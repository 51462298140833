import { Intl } from "@i18n/Intl";
import React from "react";
import styled from "styled-components";
import { Color } from "@theme/Theme";
import { Button } from "@components/Button";
import { Flex } from "@components/Flex";
import { ProgressBar } from "@components/ProgressBar";
import SvgIcnCloud from "@components/svg/IcnCloud";
import SvgicnClose from "@components/svg/IcnClose";

export const Notification = (props: { progressPercent: number }) => {
    return (
        <StyledNotification>
            <span aria-hidden="true">
                <SvgIcnCloud />
            </span>
            <StyledNotificationText>{props.progressPercent}%</StyledNotificationText>
            <Flex.Item $shrink={80}>
                <ProgressBar progressPercent={props.progressPercent} />
            </Flex.Item>
        </StyledNotification>
    );
};

export const NotificationDone = (props: { isError?: boolean }) => {
    return (
        <StyledNotificationDone>
            <span aria-hidden="true">
                <SvgIcnCloud />
            </span>
            <StyledNotificationText>{Intl.formatMessage({ id: "common.done" })}</StyledNotificationText>
            {props.isError ? (
                <StyledNotificationErrorUploaded>
                    {Intl.formatMessage({ id: "components.uploadModal.error" })}
                </StyledNotificationErrorUploaded>
            ) : (
                <StyledNotificationDoneUploaded>
                    {Intl.formatMessage({ id: "components.uploadModal.uploaded" })}
                </StyledNotificationDoneUploaded>
            )}
            <Button.Action btnLabel="Close notification" icon={<SvgicnClose />} />
        </StyledNotificationDone>
    );
};

const StyledNotification = styled(Flex.Container).attrs(() => ({
    $alignItems: "center",
}))`
    margin-left: 30px;
    padding: 22px 18px;
    background-color: ${props => props.theme.notification.backgroundColor};
    border-radius: 3px;
    box-shadow: ${props => props.theme.notification.boxShadow};
    /* AriaModal has zIndex: 1050; We will probably never need more than 1050. */
    z-index: 1050;

    svg {
        color: ${props => props.theme.notification.iconColor};
    }
`;

export const StyledNotificationWrapper = styled.div`
    position: fixed;
    display: flex;
    left: 40px;
    bottom: 40px;
`;

const StyledNotificationText = styled.span`
    margin: 0 10px;
    font-size: 12px;
    text-transform: uppercase;
`;

const StyledNotificationDone = styled(StyledNotification)`
    margin-left: 30px;
    padding: 22px 8px 22px 18px;

    ${StyledNotificationText} {
        position: relative;
        margin-right: 20px;

        &::after {
            content: "";
            position: absolute;
            top: 50%;
            right: -10px;
            transform: translateY(-50%);
            width: 1px;
            height: 44px;
            background-color: ${Color.grayD};
        }
    }

    ${Button.Action} {
        margin-left: 10px;
        color: ${props => props.theme.notification.buttonColor};
    }
`;

const StyledNotificationDoneUploaded = styled.span`
    color: ${props => props.theme.notification.color};
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
`;

const StyledNotificationErrorUploaded = styled(StyledNotificationDoneUploaded)`
    color: ${props => props.theme.notification.errorColor};
`;
