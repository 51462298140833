export class Paths {
    public static readonly Home = "/";
    public static readonly Directory = (id: string): string => {
        return `/directory/${id}`;
    };
    public static readonly LoginAuthToken: string = "/loginAuthToken";
    public static readonly Login = "/login";
    public static readonly Sandbox = "/sandbox";
    public static readonly EmbeddedStartPage = "/embedded";
}

export class AppPath {
    public static readonly Home: string = Paths.Home;
    public static readonly Directory: string = Paths.Directory(":id");
    public static readonly LoginAuthToken: string = Paths.LoginAuthToken;
    public static readonly Login: string = Paths.Login;
    public static readonly Sandbox: string = Paths.Sandbox;
    public static readonly EmbeddedStartPage: string = Paths.EmbeddedStartPage;
}
