import { Cookie } from "@utils/Cookie";

export enum AuthActionType {
    logout = "@auth/logout",
    updateAuthToken = "@auth/updateAuthToken",
}

interface LogoutAction {
    type: AuthActionType.logout;
}

interface UpdateAuthToken {
    type: AuthActionType.updateAuthToken;
    authToken: string;
}

export class AuthActions {
    public static logout(): LogoutAction {
        Cookie.clearAuthToken();
        return { type: AuthActionType.logout };
    }
    public static updateAuthToken(authToken: string): UpdateAuthToken {
        Cookie.setAuthTokken(authToken);
        return { type: AuthActionType.updateAuthToken, authToken };
    }
}

export type AuthAction = UpdateAuthToken | LogoutAction;
