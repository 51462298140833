import React from "react";
import { Asset } from "@api/graphql/types";
import { Intl } from "@i18n/Intl";
import { Color } from "@theme/Theme";
import styled from "styled-components";
import { getImageUrl } from "@utils/Helpers";
import Modal, { ModalHeader, ModalInputWrapper, StyledScrollableContainer } from "@components/Modals/Modal";
import { H2 } from "@theme/GlobalStyle";

type Props = {
    mounted: boolean;
    onModalClose: () => void;
    asset: Asset;
};

class AssetShowModal extends React.PureComponent<Props> {
    public render(): React.ReactNode {
        const { mounted, onModalClose, asset } = this.props;
        return (
            <StyledModal
                mounted={mounted}
                titleText={Intl.formatMessage({ id: "components.assetShowModal.title" })}
                onModalClose={onModalClose}
                underlayColor={Color.modal}
            >
                <ModalHeader onCloseClick={this.props.onModalClose}>
                    <H2>{Intl.formatMessage({ id: "components.assetShowModal.title" })}</H2>
                </ModalHeader>
                <ModalInputWrapper>
                    <ModalImage src={getImageUrl(asset.urlWithOpts, "w_800")} alt={asset.fileName} />
                </ModalInputWrapper>
            </StyledModal>
        );
    }
}

const StyledModal = styled(Modal)`
    width: ${props => props.theme.box.imageModal.width}px;

    ${StyledScrollableContainer} {
        padding-bottom: ${props => props.theme.box.imageModal.paddingBottom}px;
        padding-left: ${props => props.theme.box.imageModal.paddingLeft}px;
        padding-right: ${props => props.theme.box.imageModal.paddingRight}px;
        padding-top: ${props => props.theme.box.imageModal.paddingTop}px;
    }
`;

const ModalImage = styled.img`
    width: 100%;
`;

export { AssetShowModal };
