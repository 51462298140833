import React from "react";
import styled from "styled-components";
import { bp, breakpoints, Color } from "@theme/Theme";
import { Container, H1, H2, H3, H4, H5, H6, keyframesFadeIn } from "@theme/GlobalStyle";
import { Button } from "@components/Button";
import { Flex } from "@components/Flex";
import { Foldable, StyledFoldable } from "@components/Foldable/Foldable";
import { Link } from "@components/Link";
import ModalAbsolute, { ModalAlignment } from "@components/Modals/ModalAbsolute";
import { ColorList, StyledColorList } from "@components/Sandbox/ColorList";
import { Svg } from "@components/svg/index";
import SvgIcnArrowRight from "@components/svg/IcnArrowRight";

const createDemoFlexItems = (key: string, j: number) => {
    const items = [];

    for (let i = 0; i < j; i++) {
        items.push(
            <Flex.Item key={`${key}-${i}`} $shrink="shrink">
                <DemoItem $padding={i % 2 === 0 ? 8 : undefined}>{i + 1}</DemoItem>
            </Flex.Item>
        );
    }

    return items;
};

export class SandboxPage extends React.Component {
    render() {
        return (
            <StyledSandboxMain id="main">
                <StyledSandboxNav>
                    <ul>
                        <li>
                            <a href="#button">Button</a>
                        </li>

                        <li>
                            <a href="#breakpoints">Breakpoints</a>
                        </li>

                        <li>
                            <a href="#color-list">ColorList</a>
                        </li>

                        <li>
                            <a href="#flex">Flex</a>
                        </li>

                        <li>
                            <a href="#foldable">Foldable</a>
                        </li>

                        <li>
                            <a href="#link">Link</a>
                        </li>

                        <li>
                            <a href="#modals">Modals</a>
                        </li>

                        <li>
                            <a href="#svgcollection">SvgCollection</a>
                        </li>

                        <li>
                            <a href="#typography">Typography</a>
                        </li>
                    </ul>
                </StyledSandboxNav>

                <Container>
                    <h2>Buttons for media library</h2>

                    <div>
                        <h3>Primary buttons</h3>
                        <Button.Primary btnLabel="Primary button" />
                        <Button.Primary btnLabel="Icon only button" icon={<SvgIcnArrowRight />} />
                        <Button.Primary btnLabel="Button icon left" icon={<SvgIcnArrowRight />} iconPosition="left" />
                        <Button.Primary btnLabel="Button icon right" icon={<SvgIcnArrowRight />} iconPosition="right" />
                    </div>

                    <div>
                        <h3>Secondary buttons</h3>
                        <Button.Secondary btnLabel="Secondary button" />
                        <Button.Secondary btnLabel="Icon only button" icon={<SvgIcnArrowRight />} />
                        <Button.Secondary btnLabel="Button icon left" icon={<SvgIcnArrowRight />} iconPosition="left" />
                        <Button.Secondary
                            btnLabel="Button icon right"
                            icon={<SvgIcnArrowRight />}
                            iconPosition="right"
                        />
                    </div>

                    <div>
                        <h3>Action buttons</h3>
                        <Button.Action btnLabel="Action button" />
                        <Button.Action btnLabel="Icon only button" icon={<SvgIcnArrowRight />} />
                        <Button.Action btnLabel="Button icon left" icon={<SvgIcnArrowRight />} iconPosition="left" />
                        <Button.Action btnLabel="Button icon right" icon={<SvgIcnArrowRight />} iconPosition="right" />
                    </div>
                </Container>

                <Container>
                    <div id="button" className="sandbox-section">
                        <h2 className="sticky-header">Button</h2>

                        <div>
                            <Button.Primary btnLabel="Button" />

                            <Button.Primary btnLabel="Icon only button" icon={<SvgIcnArrowRight />} />

                            <Button.Primary
                                btnLabel="Button icon left"
                                icon={<SvgIcnArrowRight />}
                                iconPosition="left"
                            />

                            <Button.Primary
                                btnLabel="Button icon right"
                                icon={<SvgIcnArrowRight />}
                                iconPosition="right"
                            />
                        </div>

                        <div>
                            <Button.Primary btnLabel="Button link" to="/sandbox" />

                            <Button.Primary
                                btnLabel="Icon only button link"
                                to="/sandbox"
                                icon={<SvgIcnArrowRight />}
                            />

                            <Button.Primary
                                btnLabel="Button link icon left"
                                to="/sandbox"
                                icon={<SvgIcnArrowRight />}
                                iconPosition="left"
                            />

                            <Button.Primary
                                btnLabel="Button link icon right"
                                to="/sandbox"
                                icon={<SvgIcnArrowRight />}
                                iconPosition="right"
                            />

                            <Button.Primary btnLabel="Button link expanded" to="/sandbox" $expanded={true} />
                        </div>

                        <Button.Primary btnLabel="Button expanded" $expanded={true} />

                        <Button.Primary
                            btnLabel="Icon only button expanded"
                            icon={<SvgIcnArrowRight />}
                            $expanded={true}
                        />

                        <Button.Primary
                            btnLabel="Button icon left expanded"
                            icon={<SvgIcnArrowRight />}
                            iconPosition="left"
                            $expanded={true}
                        />

                        <Button.Primary
                            btnLabel="Button icon right expanded"
                            icon={<SvgIcnArrowRight />}
                            iconPosition="right"
                            $expanded={true}
                        />
                    </div>

                    <div id="breakpoints" className="sandbox-section">
                        <h2 className="sticky-header">Breakpoints</h2>

                        <StyledBreakpointList>
                            {Object.entries(breakpoints).map(([key, breakpoint]) => {
                                return (
                                    <li key={key} className={`breakpoint-list__item-${key}`}>
                                        <div>{key}:</div>

                                        <div>
                                            {breakpoint}
                                            px
                                        </div>
                                    </li>
                                );
                            })}
                        </StyledBreakpointList>
                    </div>

                    <div id="color-list" className="sandbox-section">
                        <h2 className="sticky-header">ColorList</h2>

                        <ColorList />
                    </div>

                    <div id="flex" className="sandbox-section">
                        <h2 className="sticky-header">Flex</h2>

                        <FlexDemoContainer>
                            <Flex.Container>Flex.Container</Flex.Container>

                            <Flex.Container $display="inline-flex">
                                Flex.Container
                                <br />
                                $display="inline-flex"
                            </Flex.Container>

                            <h3>Default</h3>

                            <Flex.Container>{createDemoFlexItems("nowrap", 10)}</Flex.Container>

                            <h3>$flexWrap="wrap"</h3>

                            <Flex.Container $flexWrap="wrap">{createDemoFlexItems("wrap", 10)}</Flex.Container>

                            <h3>$flexWrap="wrap-reverse"</h3>

                            <Flex.Container $flexWrap="wrap-reverse">
                                {createDemoFlexItems("wrap-reverse", 10)}
                            </Flex.Container>

                            <h3>$flexDirection="row-reverse"</h3>

                            <Flex.Container $flexDirection="row-reverse">
                                {createDemoFlexItems("direction-reverse", 10)}
                            </Flex.Container>

                            <h3>$flexDirection="column"</h3>

                            <Flex.Container $flexDirection="column">
                                {createDemoFlexItems("direction-column", 10)}
                            </Flex.Container>

                            <h3>$flexDirection="column-reverse"</h3>

                            <Flex.Container $flexDirection="column-reverse">
                                {createDemoFlexItems("direction-column-reverse", 10)}
                            </Flex.Container>

                            <h3>$justifyContent="center"</h3>

                            <Flex.Container $flexWrap="wrap" $justifyContent="center">
                                {createDemoFlexItems("justify-center", 10)}
                            </Flex.Container>

                            <h3>$justifyContent="flex-end"</h3>

                            <Flex.Container $flexWrap="wrap" $justifyContent="flex-end">
                                {createDemoFlexItems("justify-end", 10)}
                            </Flex.Container>

                            <h3>$justifyContent="space-around"</h3>

                            <Flex.Container $flexWrap="wrap" $justifyContent="space-around">
                                {createDemoFlexItems("justify-around", 10)}
                            </Flex.Container>

                            <h3>$justifyContent="space-between"</h3>

                            <Flex.Container $flexWrap="wrap" $justifyContent="space-between">
                                {createDemoFlexItems("justify-between", 10)}
                            </Flex.Container>

                            <h3>$justifyContent="space-evenly"</h3>

                            <Flex.Container $flexWrap="wrap" $justifyContent="space-evenly">
                                {createDemoFlexItems("justify-evenly", 10)}
                            </Flex.Container>

                            <h3>$alignItems="baseline"</h3>

                            <Flex.Container $alignItems="baseline" style={{ minHeight: 200 }}>
                                {createDemoFlexItems("align-baseline", 10)}
                            </Flex.Container>

                            <h3>$alignItems="center"</h3>

                            <Flex.Container $alignItems="center" style={{ minHeight: 200 }}>
                                {createDemoFlexItems("align-center", 10)}
                            </Flex.Container>

                            <h3>$alignItems="stretch"</h3>

                            <Flex.Container $alignItems="stretch" style={{ minHeight: 200 }}>
                                {createDemoFlexItems("align-stretch", 10)}
                            </Flex.Container>

                            <h3>$alignItems="flex-end"</h3>

                            <Flex.Container $alignItems="flex-end" style={{ minHeight: 200 }}>
                                {createDemoFlexItems("align-flex-end", 10)}
                            </Flex.Container>

                            <h3>$alignContent="center"</h3>

                            <Flex.Container $flexWrap="wrap" $alignContent="center" style={{ minHeight: 200 }}>
                                {createDemoFlexItems("content-center", 10)}
                            </Flex.Container>

                            <h3>$alignContent="stretch"</h3>

                            <Flex.Container $flexWrap="wrap" $alignItems="stretch" style={{ minHeight: 200 }}>
                                {createDemoFlexItems("content-stretch", 10)}
                            </Flex.Container>

                            <h3>$alignContent="flex-end"</h3>

                            <Flex.Container $flexWrap="wrap" $alignContent="flex-end" style={{ minHeight: 200 }}>
                                {createDemoFlexItems("content-flex-end", 10)}
                            </Flex.Container>

                            <h3>$alignContent="space-around"</h3>

                            <Flex.Container $flexWrap="wrap" $alignContent="space-around" style={{ minHeight: 200 }}>
                                {createDemoFlexItems("content-space-around", 10)}
                            </Flex.Container>

                            <h3>$alignContent="space-between"</h3>

                            <Flex.Container $flexWrap="wrap" $alignContent="space-between" style={{ minHeight: 200 }}>
                                {createDemoFlexItems("content-space-between", 10)}
                            </Flex.Container>

                            <h3>Items' shrink (width)</h3>

                            <Flex.Container>
                                <Flex.Item>
                                    <DemoItem>default</DemoItem>
                                </Flex.Item>
                            </Flex.Container>

                            <Flex.Container>
                                <Flex.Item $shrink="shrink">
                                    <DemoItem>shrink</DemoItem>
                                </Flex.Item>

                                <Flex.Item $shrink="auto">
                                    <DemoItem>auto</DemoItem>
                                </Flex.Item>

                                <Flex.Item $shrink={100}>
                                    <DemoItem>100px</DemoItem>
                                </Flex.Item>

                                <Flex.Item $shrink={1 / 4}>
                                    <DemoItem>1/4</DemoItem>
                                </Flex.Item>

                                <Flex.Item $shrink={1 / 3}>
                                    <DemoItem>1/3</DemoItem>
                                </Flex.Item>
                            </Flex.Container>

                            <h3>$gutterMargin</h3>

                            <Flex.Container $gutterMargin={16}>
                                <Flex.Item $shrink="shrink">
                                    <DemoItem>shrink</DemoItem>
                                </Flex.Item>

                                <Flex.Item $shrink="auto" $gutterMargin={32}>
                                    <DemoItem>auto</DemoItem>
                                </Flex.Item>

                                <Flex.Item $shrink={100} $gutterMargin={32}>
                                    <DemoItem>100px</DemoItem>
                                </Flex.Item>

                                <Flex.Item $shrink={1 / 4}>
                                    <DemoItem>1/4</DemoItem>
                                </Flex.Item>

                                <Flex.Item $shrink={1 / 3}>
                                    <DemoItem>1/3</DemoItem>
                                </Flex.Item>
                            </Flex.Container>

                            <Flex.Container $gutterMargin={16}>
                                <Flex.Item $shrink="shrink" $gutterMargin={16}>
                                    <DemoItem>shrink</DemoItem>
                                </Flex.Item>

                                <Flex.Item $shrink="auto" $gutterMargin={16}>
                                    <DemoItem>auto</DemoItem>
                                </Flex.Item>

                                <Flex.Item $shrink={1 / 4} $gutterMargin={16}>
                                    <DemoItem>1/4</DemoItem>
                                </Flex.Item>

                                <Flex.Item $shrink={1 / 3} $gutterMargin={16}>
                                    <DemoItem>1/3</DemoItem>
                                </Flex.Item>
                            </Flex.Container>

                            <h3>$bp</h3>

                            <Flex.Container
                                $flexWrap="wrap"
                                $gutterMargin={2}
                                $bpMedium={{ $gutterMargin: 4 }}
                                $bpLarge={{ $gutterMargin: 6 }}
                                $bpXLarge={{ $gutterMargin: 8 }}
                                $bpXXLarge={{ $gutterMargin: 10 }}
                            >
                                <Flex.Item
                                    $shrink="shrink"
                                    $gutterMargin={2}
                                    $bpMedium={{ $shrink: "auto", $gutterMargin: 4 }}
                                    $bpLarge={{ $shrink: 3 / 4, $gutterMargin: 6 }}
                                    $bpXLarge={{ $shrink: 3 / 5, $gutterMargin: 8 }}
                                    $bpXXLarge={{ $shrink: 2 / 5, $gutterMargin: 10 }}
                                >
                                    <DemoItem>
                                        <span className="em--small">shrink,</span>
                                        <br />
                                        <span className="em--medium">
                                            $bpMedium=&#123;&#123;$shrink: "auto"&#125;&#125;
                                        </span>
                                        ,
                                        <br />
                                        <span className="em--large">
                                            $bpLarge=&#123;&#123;$shrink: 3 / 4&#125;&#125;
                                        </span>
                                        ,
                                        <br />
                                        <span className="em--xlarge">
                                            $bpXLarge=&#123;&#123;$shrink: 3 / 5&#125;&#125;
                                        </span>
                                        ,
                                        <br />
                                        <span className="em--xxlarge">
                                            $bpXXLarge=&#123;&#123;$shrink: 2 / 5&#125;&#125;
                                        </span>
                                    </DemoItem>
                                </Flex.Item>

                                <Flex.Item
                                    $shrink="auto"
                                    $gutterMargin={2}
                                    $bpMedium={{ $shrink: "shrink", $gutterMargin: 4 }}
                                    $bpLarge={{ $shrink: 1 / 4, $gutterMargin: 6 }}
                                    $bpXLarge={{ $shrink: 2 / 5, $gutterMargin: 8 }}
                                    $bpXXLarge={{ $shrink: 3 / 5, $gutterMargin: 10 }}
                                >
                                    <DemoItem>
                                        <span className="em--small">auto</span>,
                                        <br />
                                        <span className="em--medium">
                                            $bpMedium=&#123;&#123;$shrink: "shrink"&#125;&#125;
                                        </span>
                                        ,
                                        <br />
                                        <span className="em--large">
                                            $bpLarge=&#123;&#123;$shrink: 1 / 4&#125;&#125;
                                        </span>
                                        ,
                                        <br />
                                        <span className="em--xlarge">
                                            $bpXLarge=&#123;&#123;$shrink: 2 / 5&#125;&#125;
                                        </span>
                                        <br />
                                        <span className="em--xxlarge">
                                            $bpXXLarge=&#123;&#123;$shrink: 3 / 5&#125;&#125;
                                        </span>
                                    </DemoItem>
                                </Flex.Item>
                            </Flex.Container>
                        </FlexDemoContainer>
                    </div>

                    <div id="foldable" className="sandbox-section">
                        <h2 className="sticky-header">Foldable</h2>

                        <Foldable id="foldable-1" toggleElement={<>Toggle Foldable</>}>
                            <div style={{ backgroundColor: "pink" }}>Yes, this is Foldable </div>
                        </Foldable>

                        <Foldable
                            id="foldable-2"
                            toggleElement={<>Toggle Foldable with icon</>}
                            toggleIcon={{
                                element: (
                                    <ToggleIconDemoWrapper>
                                        <SvgIcnArrowRight />
                                    </ToggleIconDemoWrapper>
                                ),
                                $style: {
                                    backgroundColor: "pink",
                                },
                                $activeStyle: { transform: "rotate(90deg)" },
                            }}
                        >
                            <div style={{ backgroundColor: "pink" }}>Yes, this is Foldable </div>
                        </Foldable>

                        <Foldable
                            id="foldable-3"
                            toggleElement={<>Toggle Foldable with icon left</>}
                            toggleIcon={{
                                element: (
                                    <ToggleIconDemoWrapper>
                                        <SvgIcnArrowRight />
                                    </ToggleIconDemoWrapper>
                                ),
                                elementPositionH: "left",
                                $style: {
                                    backgroundColor: "pink",
                                },
                                $activeStyle: { transform: "rotate(90deg)" },
                            }}
                        >
                            <div style={{ backgroundColor: "pink" }}>Yes, this is Foldable </div>
                        </Foldable>

                        <Foldable
                            id="foldable-4"
                            toggleElement={<>Toggle Foldable with icon right</>}
                            toggleIcon={{
                                element: (
                                    <ToggleIconDemoWrapper>
                                        <SvgIcnArrowRight />
                                    </ToggleIconDemoWrapper>
                                ),
                                elementPositionH: "right",
                                $style: {
                                    backgroundColor: "pink",
                                },
                                $activeStyle: { transform: "rotate(90deg)" },
                            }}
                        >
                            <div style={{ backgroundColor: "pink" }}>Yes, this is Foldable </div>
                        </Foldable>

                        <Foldable id="foldable-10" toggleElement={<>Toggle Foldable</>} $shrinkToggleElement={true}>
                            <div style={{ backgroundColor: "pink" }}>Yes, this is Foldable </div>
                        </Foldable>

                        <Foldable
                            id="foldable-11"
                            toggleElement={<>Toggle Foldable with icon</>}
                            $shrinkToggleElement={true}
                            toggleIcon={{
                                element: (
                                    <ToggleIconDemoWrapper>
                                        <SvgIcnArrowRight />
                                    </ToggleIconDemoWrapper>
                                ),
                                $style: {
                                    backgroundColor: "pink",
                                },
                                $activeStyle: { transform: "rotate(90deg)" },
                            }}
                        >
                            <div style={{ backgroundColor: "pink" }}>Yes, this is Foldable </div>
                        </Foldable>

                        <Foldable
                            id="foldable-12"
                            toggleElement={<>Toggle Foldable with icon left</>}
                            $shrinkToggleElement={true}
                            toggleIcon={{
                                element: (
                                    <ToggleIconDemoWrapper>
                                        <SvgIcnArrowRight />
                                    </ToggleIconDemoWrapper>
                                ),
                                elementPositionH: "left",
                                $style: {
                                    backgroundColor: "pink",
                                },
                                $activeStyle: { transform: "rotate(90deg)" },
                            }}
                        >
                            <div style={{ backgroundColor: "pink" }}>Yes, this is Foldable </div>
                        </Foldable>

                        <Foldable
                            id="foldable-13"
                            toggleElement={<>Toggle Foldable with icon right</>}
                            $shrinkToggleElement={true}
                            toggleIcon={{
                                element: (
                                    <ToggleIconDemoWrapper>
                                        <SvgIcnArrowRight />
                                    </ToggleIconDemoWrapper>
                                ),
                                elementPositionH: "right",
                                $style: {
                                    backgroundColor: "pink",
                                },
                                $activeStyle: { transform: "rotate(90deg)" },
                            }}
                        >
                            <div style={{ backgroundColor: "pink" }}>Yes, this is Foldable </div>
                        </Foldable>
                    </div>

                    <div id="link" className="sandbox-section">
                        <h2 className="sticky-header">Link</h2>

                        <Flex.Container $flexWrap="wrap">
                            <Flex.Item $bpMedium={{ $shrink: 1 / 4 }}>
                                <Link to={"/"}>Home</Link>
                            </Flex.Item>

                            <Flex.Item $bpMedium={{ $shrink: 1 / 4 }}>
                                <Link to={"https://www.onceapps.com/"}>External Link</Link>
                            </Flex.Item>

                            <Flex.Item $bpMedium={{ $shrink: 1 / 4 }}>
                                <Link to={"/"} linkIcon={<SvgIcnArrowRight />} linkIconGutter={5}>
                                    Link, icon left
                                </Link>
                            </Flex.Item>

                            <Flex.Item $bpMedium={{ $shrink: 1 / 4 }}>
                                <Link
                                    to={"/"}
                                    linkIcon={<SvgIcnArrowRight />}
                                    linkIconPosition="right"
                                    linkIconGutter={10}
                                >
                                    Link, icon right
                                </Link>
                            </Flex.Item>

                            {/* <Flex.Item $bpMedium={{ $shrink: 1 / 4 }}>
                                <button className="button--a" type="button">
                                    Button link
                                </button>
                            </Flex.Item> */}
                        </Flex.Container>
                    </div>

                    <div id="modals" className="sandbox-section">
                        <h2 className="sticky-header">Modals</h2>
                        <h3>ModalAbsolute</h3>
                        <ModalAbsolute
                            titleText="ModalAbsolute"
                            modalAlignment={ModalAlignment.TopLeft}
                            activateElement={<Button.Primary btnLabel="ModalAbsolute TOP/LEFT (Default)" />}
                        >
                            <div style={{ backgroundColor: Color.red, color: Color.white }}>ModalAbsolute</div>
                        </ModalAbsolute>{" "}
                        <ModalAbsolute
                            titleText="ModalAbsolute"
                            modalAlignment={ModalAlignment.TopCenter}
                            activateElement={<Button.Primary btnLabel="ModalAbsolute TOP/CENTER" />}
                        >
                            <div style={{ backgroundColor: Color.red, color: Color.white }}>ModalAbsolute</div>
                        </ModalAbsolute>{" "}
                        <ModalAbsolute
                            titleText="ModalAbsolute"
                            modalAlignment={ModalAlignment.TopRight}
                            activateElement={<Button.Primary btnLabel="ModalAbsolute TOP/RIGHT" />}
                        >
                            <div style={{ backgroundColor: Color.red, color: Color.white }}>ModalAbsolute</div>
                        </ModalAbsolute>{" "}
                        <ModalAbsolute
                            titleText="ModalAbsolute"
                            modalAlignment={ModalAlignment.BottomLeft}
                            activateElement={<Button.Primary btnLabel="ModalAbsolute BOTTOM/LEFT" />}
                        >
                            <div style={{ backgroundColor: Color.red, color: Color.white }}>ModalAbsolute</div>
                        </ModalAbsolute>{" "}
                        <ModalAbsolute
                            titleText="ModalAbsolute"
                            modalAlignment={ModalAlignment.BottomCenter}
                            activateElement={<Button.Primary btnLabel="ModalAbsolute BOTTOM/CENTER" />}
                        >
                            <div style={{ backgroundColor: Color.red, color: Color.white }}>ModalAbsolute</div>
                        </ModalAbsolute>{" "}
                        <ModalAbsolute
                            titleText="ModalAbsolute"
                            modalAlignment={ModalAlignment.BottomRight}
                            activateElement={<Button.Primary btnLabel="ModalAbsolute BOTTOM/Right" />}
                        >
                            <div style={{ backgroundColor: Color.red, color: Color.white }}>ModalAbsolute</div>
                        </ModalAbsolute>
                    </div>

                    <div id="svgcollection" className="sandbox-section">
                        <h2 className="sticky-header">SVGCollection</h2>

                        <StyledSVGList>
                            {Object.keys(Svg).map((key: string) => {
                                const SVG = Svg[key];

                                return (
                                    <li key={key}>
                                        <div>
                                            <SVG />
                                        </div>

                                        <div>Svg{key}</div>
                                    </li>
                                );
                            })}
                        </StyledSVGList>

                        <h3>Resize icon</h3>

                        <SvgIcnArrowRight
                            width={10 * 10}
                            height={22 * 10}
                            viewBox="0 0 10 22"
                            color={Color.red}
                            // Note: or with CSS
                            // style={{ color: Color.green }}
                        />
                    </div>

                    <div id="typography" className="sandbox-section">
                        <h2 className="sticky-header">Typography</h2>

                        <article>
                            <H1>Maecenas vitae consectetur felis, sed aliquet sapien</H1>

                            <H2>Lorem ipsum dolor sit amet</H2>

                            <H3>
                                Nullam vitae orci at ante laoreet rutrum sit amet nec lacus sed porta eget sem
                                ullamcorper finibus
                            </H3>

                            <H4>
                                Donec bibendum est quis enim luctus laoreet. Nunc nec sapien ac dolor facilisis mattis
                                non ac ante. Donec dapibus dolor sem, sed lacinia eros sodales sed.
                            </H4>

                            <H5>
                                Mauris accumsan mi et ipsum malesuada molestie. Fusce rhoncus magna sapien, sit amet
                                euismod risus sodales et. Aliquam elit massa.
                            </H5>

                            <H6>
                                Donec bibendum est quis enim luctus laoreet. Nunc nec sapien ac dolor facilisis mattis
                                non ac ante. Donec dapibus dolor sem, sed lacinia eros sodales sed.
                            </H6>

                            <p>
                                Proin luctus efficitur gravida. Nam at dolor a neque dictum venenatis a non mi. Interdum
                                et malesuada fames ac ante ipsum primis in faucibus.
                            </p>
                        </article>
                    </div>
                </Container>
            </StyledSandboxMain>
        );
    }
}

const StyledSandboxMain = styled.main`
    animation: ${keyframesFadeIn} 300ms 1;
    padding-bottom: 80px;

    .sandbox-section {
        > .sticky-header {
            align-items: center;
            background-color: ${props => props.theme.box.body.backgroundColor};
            display: flex;
            margin: 0;
            padding: 1em 0;
            position: sticky;
            top: 0;
            z-index: 2;
        }
    }

    ${StyledFoldable.ToggleButton} {
        &:hover {
            background-color: pink;
        }
    }
`;

const StyledSandboxNav = styled.nav`
    ${bp.medium} {
        background-color: ${props => props.theme.box.body.backgroundColor};
        bottom: 0;
        box-shadow: inset -1px 0 0 0 ${Color.gray};
        left: 0;
        opacity: 0;
        overflow: auto;
        position: fixed;
        top: 0;
        transform: translateX(-90%);
        transition: opacity 500ms, transform 500ms;
        width: 20%;
        z-index: 1051;

        &:hover {
            opacity: 1;
            transform: translateX(0);
        }
    }
`;

const StyledBreakpointList = styled(StyledColorList)`
    li {
        text-shadow: none;
        word-break: break-all;

        &.breakpoint-list__item-small {
            background-color: ${Color.black};
            color: ${Color.white};
        }

        &.breakpoint-list__item-medium {
            ${bp.medium} {
                background-color: ${Color.black};
                color: ${Color.white};
            }
        }

        &.breakpoint-list__item-large {
            ${bp.large} {
                background-color: ${Color.black};
                color: ${Color.white};
            }
        }

        &.breakpoint-list__item-xlarge {
            ${bp.xlarge} {
                background-color: ${Color.black};
                color: ${Color.white};
            }
        }

        &.breakpoint-list__item-xxlarge {
            ${bp.xxlarge} {
                background-color: ${Color.black};
                color: ${Color.white};
            }
        }

        ${bp.medium} {
            width: ${100 / Object.keys(breakpoints).length}%;
        }
    }
`;

const StyledSVGList = styled(StyledColorList)`
    li {
        text-align: center;
        word-break: break-all;

        &:active,
        &:hover {
            background-color: ${Color.black};
            color: ${Color.white};

            svg {
                color: ${Color.red};
            }
        }
    }

    svg {
        margin-left: auto;
        margin-right: auto;
    }
`;

const FlexDemoContainer = styled.div`
    font-family: monospace, "Courier New";
    overflow: hidden;
    padding: 0 1px;

    ${Flex.Item} {
        background-color: ${Color.grayL};
        outline: 1px solid ${Color.gray};
    }

    ${Flex.Container} {
        background-color: ${Color.grayL};

        & + ${Flex.Container} {
            margin-top: 8px;
        }
    }
`;

const DemoItem = styled.div<{ $padding?: number }>`
    background-color: ${Color.grayD};
    min-width: 100px;
    outline: 1px solid ${Color.grayD};
    padding: ${props => props.$padding || 4}px;
    text-align: center;

    ${bp.custom(`max-width: ${breakpoints.medium - 1}px`)} {
        .em--small {
            color: ${Color.black};
        }
    }

    ${`@media (max-width: ${breakpoints.large - 1}px) and (min-width: ${breakpoints.medium}px)`} {
        .em--medium {
            color: ${Color.black};
        }
    }

    ${`@media (max-width: ${breakpoints.xlarge - 1}px) and (min-width: ${breakpoints.large}px)`} {
        .em--large {
            color: ${Color.black};
        }
    }

    ${`@media (max-width: ${breakpoints.xxlarge - 1}px) and (min-width: ${breakpoints.xlarge}px)`} {
        .em--xlarge {
            color: ${Color.black};
        }
    }

    ${bp.xxlarge} {
        .em--xxlarge {
            color: ${Color.black};
        }
    }
`;

const ToggleIconDemoWrapper = styled.span`
    align-items: center;
    display: flex;
    height: 20px;
    justify-content: center;
    width: 20px;
`;
