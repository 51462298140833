import { AppPath, Paths } from "@pages/paths";
import { ApplicationState } from "@redux/reducers";
import React from "react";
import { MapStateToProps, connect, DispatchProp } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import LoadingOverlay from "@components/LoadingOverlay";
import { AuthActions } from "@redux/actions/AuthActions";
import { IncomingEvent, OutgoingEvent, sendPMEvent } from "@utils/EmbeddedUtils";
import { AppStateActions } from "@redux/actions/AppStateActions";
import { AppConfigurationType } from "@redux/reducers/AppStateReducer";
import { batchActions } from "redux-batched-actions";
import { Api } from "@api/Api";
import { Asset } from "@api/graphql/types";
import { Log } from "@utils/Log";

enum PossibleInitError {
    NoTokenProvided,
}

type StateType = {
    errorType: PossibleInitError | null;
};

type ReduxProps = {};
type Props = DispatchProp & RouteComponentProps;

class EmbeddedStartPageComponent extends React.Component<Props, StateType> {
    state: StateType = {
        errorType: null,
    };

    componentDidMount() {
        window.addEventListener("message", this.embeddedMessageListener);
        sendPMEvent(OutgoingEvent.LoadComplete);
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.embeddedMessageListener);
    }

    private readonly fetchSelectedAsset = async (fileName: string): Promise<Asset | null> => {
        try {
            return await Api.getAssetByFileName(fileName);
        } catch (error) {
            Log.error(error);
            return null;
        }
    };

    private embeddedMessageListener = (messageEvent: MessageEvent) => {
        const receivedMessage = messageEvent.data || "{}";
        if (process.env.NODE_ENV === "development") {
            console.dir(messageEvent);
            console.log(receivedMessage);
        }
        try {
            const { type, event, ...otherParams } = JSON.parse(receivedMessage);
            if (type !== "bfmlmsg") {
                return;
            }
            switch (event as IncomingEvent) {
                case IncomingEvent.InitOptions: {
                    this.setupInitialOptions(otherParams.opts);
                    break;
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    private setupInitialOptions = async (opts: Partial<AppConfigurationType>) => {
        // Do some validation
        if (!opts.embedAuthToken) {
            this.setState({ errorType: PossibleInitError.NoTokenProvided });
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const actions: any[] = [
            AppStateActions.setAppConfiguration(opts, true),
            AuthActions.updateAuthToken(opts.embedAuthToken),
        ];
        let asset = null;
        if (opts.selectedFile) {
            asset = await this.fetchSelectedAsset(opts.selectedFile);
            if (asset) {
                actions.push(AppStateActions.setCurrentDirectory(asset.directory?.id || null));
            }
        }
        this.props.dispatch(batchActions(actions));
        this.props.history.replace(asset && asset.directory ? Paths.Directory(asset.directory.id) : AppPath.Home);
    };

    public render(): JSX.Element {
        switch (this.state.errorType) {
            default: {
                return <LoadingOverlay />;
            }
        }
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, {}, ApplicationState> = (): ReduxProps => {
    return {};
};

const EmbeddedStartPage = connect(mapStateToProps)(EmbeddedStartPageComponent);

export { EmbeddedStartPage };
