import { SearchListSortField, SortOrder, SearchListOptionsInput } from "@api/graphql/types";
import { Button } from "@components/Button";
import { Flex } from "@components/Flex";
import ModalAbsolute, { ModalAlignment, Menu } from "@components/Modals/ModalAbsolute";
import SvgIcnArrowDown from "@components/svg/IcnArrowDown";
import SvgIcnFolder from "@components/svg/IcnFolder";
import SvgIcnReload from "@components/svg/IcnReload";
import SvgIcnView from "@components/svg/IcnView";
import { Intl } from "@i18n/Intl";
import { AppStateActions } from "@redux/actions/AppStateActions";
import { ApplicationState } from "@redux/reducers";
import React from "react";
import { DispatchProp, MapStateToProps, connect } from "react-redux";
import styled from "styled-components";

type ReduxProps = {
    assetListDisplay: boolean;
    sidebarOpened: boolean;
    assetListOptions: SearchListOptionsInput;
};

type Props = ReduxProps & DispatchProp;

class OptionsBarComponent extends React.Component<Props> {
    private readonly onViewToggle = (): void => {
        this.props.dispatch(AppStateActions.toggleAssetListDisplay(!this.props.assetListDisplay));
    };

    private readonly toggleSidebar = (): void => {
        this.props.dispatch(AppStateActions.toggleSidebar(!this.props.sidebarOpened));
    };

    private readonly onSortChange = (field: SearchListSortField, direction: SortOrder): void => {
        this.props.dispatch(AppStateActions.setAssetSort(field, direction));
    };

    public render(): React.ReactElement {
        return (
            <StyledOptionsBar>
                <Flex.Item $shrink="auto">
                    {!this.props.sidebarOpened && (
                        <Button.Action
                            btnLabel="Folders"
                            icon={<SvgIcnFolder />}
                            onClick={() => this.toggleSidebar()}
                        />
                    )}

                    <Button.Action
                        btnLabel="Refresh"
                        icon={<SvgIcnReload />}
                        onClick={() => {
                            if (this.props.assetListOptions.control?.offset !== 0) {
                                this.props.dispatch(AppStateActions.clearAssetOptions());
                            } else {
                                window.dispatchEvent(new CustomEvent("refreshAssets"));
                            }
                            window.dispatchEvent(new CustomEvent("refreshDirectories"));
                        }}
                    />
                </Flex.Item>

                <Flex.Item $shrink="shrink">
                    <ModalAbsolute
                        titleText="TODO"
                        modalAlignment={ModalAlignment.BottomRight}
                        activateElement={
                            <SortingOptions as="button" type="button">
                                <span>
                                    {Intl.formatMessage({
                                        id: `components.optionsBar.options.${this.props.assetListOptions.sortField}${this.props.assetListOptions.control?.sortOrder}`,
                                    })}
                                </span>
                                <SvgIcnArrowDown />
                            </SortingOptions>
                        }
                        containerPositionFixed={true}
                    >
                        <Menu.Options>
                            <Menu.Option
                                btnLabel={Intl.formatMessage({ id: "components.optionsBar.options.titleASC" })}
                                icon={<SvgIcnView />}
                                iconPosition="left"
                                onClick={() => this.onSortChange(SearchListSortField.title, SortOrder.ASC)}
                            />
                            <Menu.Option
                                btnLabel={Intl.formatMessage({ id: "components.optionsBar.options.titleDESC" })}
                                icon={<SvgIcnView />}
                                iconPosition="left"
                                onClick={() => this.onSortChange(SearchListSortField.title, SortOrder.DESC)}
                            />
                            <Menu.Option
                                btnLabel={Intl.formatMessage({ id: "components.optionsBar.options.fileSizeASC" })}
                                icon={<SvgIcnView />}
                                iconPosition="left"
                                onClick={() => this.onSortChange(SearchListSortField.fileSize, SortOrder.ASC)}
                            />
                            <Menu.Option
                                btnLabel={Intl.formatMessage({ id: "components.optionsBar.options.fileSizeDESC" })}
                                icon={<SvgIcnView />}
                                iconPosition="left"
                                onClick={() => this.onSortChange(SearchListSortField.fileSize, SortOrder.DESC)}
                            />
                            <Menu.Option
                                btnLabel={Intl.formatMessage({ id: "components.optionsBar.options.createdAtASC" })}
                                icon={<SvgIcnView />}
                                iconPosition="left"
                                onClick={() => this.onSortChange(SearchListSortField.createdAt, SortOrder.ASC)}
                            />
                            <Menu.Option
                                btnLabel={Intl.formatMessage({ id: "components.optionsBar.options.createdAtDESC" })}
                                icon={<SvgIcnView />}
                                iconPosition="left"
                                onClick={() => this.onSortChange(SearchListSortField.createdAt, SortOrder.DESC)}
                            />
                            <Menu.Option
                                btnLabel={Intl.formatMessage({ id: "components.optionsBar.options.updatedAtASC" })}
                                icon={<SvgIcnView />}
                                iconPosition="left"
                                onClick={() => this.onSortChange(SearchListSortField.updatedAt, SortOrder.ASC)}
                            />
                            <Menu.Option
                                btnLabel={Intl.formatMessage({ id: "components.optionsBar.options.updatedAtDESC" })}
                                icon={<SvgIcnView />}
                                iconPosition="left"
                                onClick={() => this.onSortChange(SearchListSortField.updatedAt, SortOrder.DESC)}
                            />
                        </Menu.Options>
                    </ModalAbsolute>
                </Flex.Item>

                <Flex.Item $shrink="shrink">
                    <Button.Action btnLabel="Switch view" icon={<SvgIcnView />} onClick={() => this.onViewToggle()} />
                </Flex.Item>
            </StyledOptionsBar>
        );
    }
}

const StyledOptionsBar = styled(Flex.Container).attrs(() => ({
    $alignItems: "center",
}))`
    width: 100%;
    min-width: max-content;
    height: 40px;
    padding: 0 20px 0 10px;
    border-bottom: 1px solid ${props => props.theme.optionsBar.borderColor};
`;

const SortingOptions = styled(Flex.Container).attrs(() => ({
    $alignItems: "center",
}))`
    margin: 0 20px 0 auto;
    font-size: 12px;

    svg {
        margin-left: 10px;
    }
`;

const mapStateToProps: MapStateToProps<ReduxProps, {}, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return {
        assetListDisplay: state.appState.assetListDisplay,
        sidebarOpened: state.appState.sidebarOpened,
        assetListOptions: state.appState.assetOptions,
    };
};

const OptionsBar = connect(mapStateToProps)(OptionsBarComponent);

export { OptionsBar };
