import gql from "graphql-tag";
import { DocumentNode } from "graphql";

import { Fragments } from "./Fragments";

class Mutations {
    public static readonly uploadAsset: DocumentNode = gql`
        mutation uploadAsset($title: String!, $file: Upload!, $directoryId: ID) {
            uploadAsset(title: $title, file: $file, directoryId: $directoryId) {
                ...Asset
            }
        }
        ${Fragments.asset}
    `;

    public static readonly updateAsset: DocumentNode = gql`
        mutation updateAsset($id: ID!, $title: String!) {
            updateAsset(id: $id, title: $title) {
                ...Asset
            }
        }
        ${Fragments.asset}
    `;

    public static readonly deleteAsset: DocumentNode = gql`
        mutation deleteAsset($id: ID!) {
            deleteAsset(id: $id)
        }
    `;

    public static readonly deleteAssets: DocumentNode = gql`
        mutation deleteAssets($ids: [ID!]!) {
            deleteAssets(ids: $ids)
        }
    `;

    public static readonly createAssetDirectory: DocumentNode = gql`
        mutation createAssetDirectory($input: CreateAssetDirectoryInput!) {
            createAssetDirectory(input: $input) {
                ...AssetDirectory
            }
        }
        ${Fragments.assetDirectory}
    `;

    public static readonly updateAssetDirectory: DocumentNode = gql`
        mutation updateAssetDirectory($id: ID, $input: UpdateAssetDirectoryInput!) {
            updateAssetDirectory(id: $id, input: $input) {
                ...AssetDirectory
            }
        }
        ${Fragments.assetDirectory}
    `;

    public static readonly deleteAssetDirectory: DocumentNode = gql`
        mutation deleteAssetDirectory($id: ID!) {
            deleteAssetDirectory(id: $id)
        }
    `;

    public static readonly moveAssetsToAssetDirectory: DocumentNode = gql`
        mutation moveAssetsToAssetDirectory($assetIds: [ID!]!, $directoryId: ID) {
            moveAssetsToAssetDirectory(assetIds: $assetIds, directoryId: $directoryId)
        }
    `;
}

export { Mutations };
