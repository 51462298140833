import * as React from "react";

function SvgIcnArrowDown(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={10} height={7} fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} {...props}>
            <path d="M1 1l4 4 4-4" stroke="currentColor" strokeWidth={1.5} />
        </svg>
    );
}

export default SvgIcnArrowDown;
