import styled from "styled-components";
import { Menu } from "./ModalAbsolute";

export const StyledMenuOptions = styled(Menu.Options)`
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);

    ${Menu.Option} {
        margin: 0;
        padding: 5px;
    }
`;
