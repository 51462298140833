import { Api } from "@api/Api";
import { Alert } from "@components/Alert/Alert";
import Modal, { ModalHeader, ModalInputWrapper, ButtonWrapper } from "@components/Modals/Modal";
import { Intl } from "@i18n/Intl";
import { Color } from "@theme/Theme";
import React from "react";
import { Asset } from "@api/graphql/types";
import { H2 } from "@theme/GlobalStyle";
import { Button } from "@components/Button";
import { connect, DispatchProp } from "react-redux";
import { AppStateActions } from "@redux/actions/AppStateActions";

type Props = {
    mounted: boolean;
    onModalClose: () => void;
    refreshAssets: () => void;
    asset: Asset | Asset[];
} & DispatchProp;

interface State {
    isLoading: boolean;
}

class AssetDeleteModalComponent extends React.Component<Props, State> {
    public state: State = {
        isLoading: false,
    };

    private readonly onDelete = async (): Promise<void> => {
        this.setState({ isLoading: true }, async () => {
            try {
                if (Array.isArray(this.props.asset)) {
                    await Api.deleteAssets(this.props.asset.map(asset => asset.id));
                } else {
                    await Api.deleteAsset(this.props.asset.id);
                }
                Alert.success({ title: Intl.formatMessage({ id: "components.assetDeleteModal.deleteSuccess" }) });
                this.props.dispatch(AppStateActions.clearSelectedAssets());
                this.props.refreshAssets();
                this.props.onModalClose();
            } catch (error) {
                Alert.error({ title: Intl.getMessageFromError(error) });
                this.setState({ isLoading: false });
            }
        });
    };

    public render(): React.ReactElement {
        const { asset } = this.props;
        return (
            <Modal
                mounted={this.props.mounted}
                titleText={Intl.formatMessage({ id: "components.assetDeleteModal.title" })}
                onModalClose={this.props.onModalClose}
                underlayColor={Color.modal}
            >
                <ModalHeader onCloseClick={this.props.onModalClose}>
                    <H2>{Intl.formatMessage({ id: "components.assetDeleteModal.title" })}</H2>
                </ModalHeader>
                <ModalInputWrapper>
                    {Array.isArray(asset) ? (
                        <>
                            <p>{Intl.formatMessage({ id: "components.assetDeleteModal.descriptionMultiple" })}</p>
                            <ul>
                                {asset.map(
                                    (item: Asset, key: number): React.ReactElement => {
                                        return <li key={key}>{item.title}</li>;
                                    }
                                )}
                            </ul>
                        </>
                    ) : (
                        <p>{Intl.formatMessage({ id: "components.assetDeleteModal.description" })}</p>
                    )}
                </ModalInputWrapper>
                <ButtonWrapper>
                    <div className="grid-x">
                        <div className="cell shrink">
                            <Button.Primary
                                btnLabel={Intl.formatMessage({ id: "common.cancel" })}
                                onClick={() => this.props.onModalClose()}
                            />
                        </div>
                        <div className="cell shrink">
                            <Button.Secondary
                                btnLabel={Intl.formatMessage({ id: "common.delete" })}
                                onClick={() => this.onDelete()}
                                disabled={this.state.isLoading}
                            />
                        </div>
                    </div>
                </ButtonWrapper>
            </Modal>
        );
    }
}

const AssetDeleteModal = connect()(AssetDeleteModalComponent);

export { AssetDeleteModal };
