import React from "react";
import styled from "styled-components";
import { Color, hexToRGBA } from "@theme/Theme";

export const ProgressBar: React.FC<{ progressPercent: number }> = props => (
    <StyledProgressBar role="progressbar" aria-valuemax={100} aria-valuemin={0} aria-valuenow={props.progressPercent}>
        <span className="show-for-sr">{props.progressPercent} %</span>
        <Progress style={{ width: `${props.progressPercent}%` }} aria-hidden="true" />
    </StyledProgressBar>
);

const StyledProgressBar = styled.div`
    position: relative;
    background-color: ${hexToRGBA(Color.secondary, 0.2)};
    border-radius: 4px;
    height: 8px;
    pointer-events: none;
    z-index: 1;
`;

const Progress = styled.div`
    background-color: ${Color.primaryD};
    border-radius: 4px;
    bottom: 0;
    content: "";
    left: 0;
    min-width: 8px;
    position: absolute;
    top: 0;
    z-index: 1;
`;
