import { ApiError } from "@api/ApiError";
import { addLocaleData, IntlProvider } from "react-intl";
import hu from "react-intl/locale-data/hu";
import { localeHu } from "./locales/hu";
import { localeEn } from "./locales/en";
import { ValidatorMessage } from "@utils/Validator";
import React from "react";

export enum Language {
    hu = "hu",
    en = "en",
}

interface Translation {
    [key: string]: string | Translation;
}

interface IntlMessageObject {
    [key: string]: string;
}

addLocaleData([...hu]);

export class Intl {
    private static readonly messages: { [key in Language]: IntlMessageObject } = {
        [Language.hu]: Intl.flattenMessages(localeHu),
        [Language.en]: Intl.flattenMessages(localeEn),
    };

    static setLanguageCode(languageCode: Language): void {
        Intl.intlProvider = new IntlProvider({
            locale: languageCode,
            messages: Intl.messages[languageCode],
        }).getChildContext();
        Intl.providedIntl = Intl.intlProvider.intl;
        Intl.formatMessage = Intl.providedIntl.formatMessage;
        Intl.formatHTMLMessage = (
            messageDescriptor: ReactIntl.FormattedMessage.MessageDescriptor,
            values?:
                | {
                      [key: string]: ReactIntl.MessageValue;
                  }
                | undefined
        ) => (
            <span
                dangerouslySetInnerHTML={{ __html: Intl.providedIntl.formatHTMLMessage(messageDescriptor, values) }}
            />
        );
    }

    private static intlProvider: { intl: ReactIntl.InjectedIntl } = new IntlProvider({
        locale: Language.hu,
        messages: Intl.messages[Language.hu],
    }).getChildContext();
    private static providedIntl: ReactIntl.InjectedIntl = Intl.intlProvider.intl;

    private static flattenMessages(nestedMessages: Translation, prefix = ""): IntlMessageObject {
        return Object.keys(nestedMessages).reduce((messages: IntlMessageObject, key: string) => {
            const value: Translation | string = nestedMessages[key];
            const prefixedKey: string = prefix ? `${prefix}.${key}` : key;

            if (typeof value === "string") {
                messages[prefixedKey] = value;
            } else {
                Object.assign(messages, Intl.flattenMessages(value, prefixedKey));
            }

            return messages;
        }, {});
    }

    static formatMessage = Intl.providedIntl.formatMessage;
    static formatHTMLMessage = (
        messageDescriptor: ReactIntl.FormattedMessage.MessageDescriptor,
        values?:
            | {
                  [key: string]: ReactIntl.MessageValue;
              }
            | undefined
    ) => <span dangerouslySetInnerHTML={{ __html: Intl.providedIntl.formatHTMLMessage(messageDescriptor, values) }} />;

    static formatValidationError(error: ValidatorMessage | string | null): string | undefined {
        if (error) {
            return Intl.formatMessage({ id: `errors.validation.${error}` });
        }
        return undefined;
    }

    /**
     * Returns an Intl formatted message
     * Error: error.message
     * ApiError: error.intlKey
     * @param error Error | ApiError
     */
    public static getMessageFromError(error: ApiError | Error): string {
        if (error instanceof ApiError) {
            return Intl.formatMessage({ id: error.intlKey });
        }
        return Intl.formatMessage({ id: error.message });
    }
}
