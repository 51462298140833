import * as React from "react";

function SvgIcnLink(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={14} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} {...props}>
            <path
                d="M5.833 3.5l2.333-2.333s2.334-2.334 4.667 0c2.333 2.333 0 4.666 0 4.666L9.722 8.944s-2.333 2.334-4.667 0c-.437-.437-.708-1.018-.708-1.018L5.65 6.624s-.053.53.57 1.154c0 0 1.168 1.166 2.334 0l3.111-3.111s1.167-1.167 0-2.334c-1.166-1.166-2.333 0-2.333 0l-1.45 1.45s-.721-.591-2.196-.137l.146-.146z"
                fill="#fff"
            />
            <path
                d="M8.106 10.5l-2.333 2.333s-2.333 2.334-4.667 0c-2.333-2.333 0-4.666 0-4.666l3.111-3.111s2.334-2.334 4.667 0c.438.437.709 1.018.709 1.018L8.289 7.377s.052-.531-.572-1.155c0 0-1.166-1.166-2.333 0L2.273 9.333s-1.167 1.167 0 2.334c1.167 1.166 2.333 0 2.333 0l1.45-1.45s.722.59 2.196.137l-.146.146z"
                fill="#fff"
            />
        </svg>
    );
}

export default SvgIcnLink;
