import React from "react";
import Modal, { ModalInputWrapper, ModalHeader } from "@components/Modals/Modal";
import { Intl } from "@i18n/Intl";
import { AppPath } from "@pages/paths";
import { AuthActions } from "@redux/actions/AuthActions";
import { ApplicationState } from "@redux/reducers";
import { AuthSelectors } from "@redux/selectors/AuthSelectors";
import { H2 } from "@theme/GlobalStyle";
import { Color } from "@theme/Theme";
import { MapStateToProps, connect, DispatchProp } from "react-redux";
import { RouteComponentProps, Redirect } from "react-router-dom";

interface RouteProps {
    authToken?: string;
}

interface ReduxProps {
    isLoggedIn: boolean;
}

type Props = ReduxProps & DispatchProp & RouteComponentProps<RouteProps>;

class LoginAuthTokenPageComponent extends React.Component<Props> {
    private authToken: string | null = new URLSearchParams(this.props.location.search).get("authToken");

    public componentDidMount(): void {
        if (this.authToken) {
            this.props.dispatch(AuthActions.updateAuthToken(this.authToken));
        }
    }

    public render(): React.ReactElement {
        if (this.props.isLoggedIn) {
            return <Redirect to={AppPath.Home} />;
        }

        if (this.authToken === null) {
            return (
                <Modal
                    titleText={Intl.formatMessage({ id: "error.auth.title" })}
                    mounted={!this.props.isLoggedIn}
                    underlayColor={Color.modal}
                >
                    <ModalHeader>
                        <H2>{Intl.formatMessage({ id: "error.auth.title" })}</H2>
                    </ModalHeader>
                    <ModalInputWrapper>
                        <p>{Intl.formatMessage({ id: "error.auth.description" })}</p>
                    </ModalInputWrapper>
                </Modal>
            );
        }
        return <>helo</>;
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, {}, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return {
        isLoggedIn: AuthSelectors.isLoggedIn(state),
    };
};

const LoginAuthTokenPage = connect(mapStateToProps)(LoginAuthTokenPageComponent);

export { LoginAuthTokenPage };
