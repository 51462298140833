import { Api } from "@api/Api";
import { Alert } from "@components/Alert/Alert";
import { Input } from "@components/Inputs/Input";
import { InputWrapper } from "@components/Inputs/InputWrapper";
import { Intl } from "@i18n/Intl";
import { Color } from "@theme/Theme";
import React from "react";
import { Asset } from "@api/graphql/types";
import Modal, { ModalHeader, ModalInputWrapper, ButtonWrapper } from "@components/Modals/Modal";
import { Button } from "@components/Button";
import { H2 } from "@theme/GlobalStyle";

type Props = {
    mounted: boolean;
    onModalClose: () => void;
    refreshAssets: () => void;
    asset: Asset;
};

interface State {
    title: string;
    isLoading: boolean;
}

class AssetRenameModal extends React.Component<Props, State> {
    public state: State = {
        title: this.props.asset.title,
        isLoading: false,
    };

    private readonly onTitleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            title: e.currentTarget.value,
        });
    };

    private readonly onRename = async (): Promise<void> => {
        this.setState(
            {
                isLoading: true,
            },
            async () => {
                try {
                    await Api.updateAsset(this.props.asset.id, this.state.title);
                    Alert.success({ title: Intl.formatMessage({ id: "components.assetRenameModal.updateSuccess" }) });
                    this.props.refreshAssets();
                    this.props.onModalClose();
                } catch (error) {
                    Alert.error({ title: Intl.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            }
        );
    };

    public render(): React.ReactElement {
        const { title } = this.state;

        return (
            <Modal
                mounted={this.props.mounted}
                titleText={Intl.formatMessage({ id: "components.assetRenameModal.title" })}
                onModalClose={this.props.onModalClose}
                underlayColor={Color.modal}
            >
                <ModalHeader onCloseClick={this.props.onModalClose}>
                    <H2>{Intl.formatMessage({ id: "components.assetRenameModal.title" })}</H2>
                </ModalHeader>
                <ModalInputWrapper>
                    <InputWrapper fakeLabel={Intl.formatMessage({ id: "components.assetRenameModal.form.title" })}>
                        <Input
                            aria-label={Intl.formatMessage({ id: "components.assetRenameModal.form.title" })}
                            value={title}
                            onChange={this.onTitleChange}
                        />
                    </InputWrapper>
                </ModalInputWrapper>
                <ButtonWrapper>
                    <Button.Secondary
                        disabled={!title || this.state.isLoading}
                        btnLabel={Intl.formatMessage({ id: "common.save" })}
                        onClick={() => this.onRename()}
                    />
                </ButtonWrapper>
            </Modal>
        );
    }
}

export { AssetRenameModal };
