import * as React from "react";

function SvgIcnReload(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={14} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} {...props}>
            <path
                d="M7 4.09v2.455l3.25-3.272L7 0v2.455C3.409 2.455.5 5.384.5 9c0 1.284.374 2.475 1.008 3.486l1.186-1.195A4.878 4.878 0 012.125 9c0-2.712 2.182-4.91 4.875-4.91zm5.492 1.425l-1.186 1.194c.362.683.569 1.46.569 2.291 0 2.712-2.182 4.91-4.875 4.91v-2.456l-3.25 3.273L7 18v-2.454c3.591 0 6.5-2.93 6.5-6.546a6.532 6.532 0 00-1.008-3.485z"
                fill="#445591"
            />
        </svg>
    );
}

export default SvgIcnReload;
