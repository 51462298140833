import gql from "graphql-tag";
import { DocumentNode } from "graphql";

class Fragments {
    public static readonly asset: DocumentNode = gql`
        fragment Asset on Asset {
            id
            title
            fileName
            fileSize
            url
            urlWithOpts
            info {
                originalFileName
                mimeType
                encoding
                etag
                width
                height
            }
            directory {
                id
            }
            createdAt
            updatedAt
        }
    `;

    public static readonly assetDirectoryItem: DocumentNode = gql`
        fragment AssetDirectoryItem on AssetDirectory {
            id
            name
            deletable
            createdAt
            updatedAt
            children {
                id
                name
                deletable
                createdAt
                updatedAt
            }
        }
    `;

    public static readonly assetDirectory: DocumentNode = gql`
        fragment AssetDirectory on AssetDirectory {
            id
            name
            parent {
                ...AssetDirectoryItem
            }
            children {
                ...AssetDirectoryItem
            }
            deletable
            createdAt
            updatedAt
        }
        ${Fragments.assetDirectoryItem}
    `;
}

export { Fragments };
