import { AnyAssetDirectory } from "@api/ApiTypes";
import React from "react";
import { Api } from "@api/Api";
import { Alert } from "@components/Alert/Alert";
import { Input } from "@components/Inputs/Input";
import { InputWrapper } from "@components/Inputs/InputWrapper";
import { CreateAssetDirectoryInput, UpdateAssetDirectoryInput } from "@api/graphql/types";
import Modal, { ModalHeader, ModalInputWrapper, ButtonWrapper } from "@components/Modals/Modal";
import { Intl } from "@i18n/Intl";
import { Color } from "@theme/Theme";
import { H2 } from "@theme/GlobalStyle";
import { Button } from "@components/Button";
import { SimplifiedDirectoryType } from "@components/BreadcrumbBar";

type Props = {
    mounted: boolean;
    currentDirectory?: string;
    onModalClose: () => void;
    refreshDirectories: (directory?: AnyAssetDirectory) => void;
    directory?: AnyAssetDirectory | SimplifiedDirectoryType;
};

interface State {
    input: CreateAssetDirectoryInput | UpdateAssetDirectoryInput;
    isLoading: boolean;
}

class DirectoryModal extends React.Component<Props, State> {
    public state: State = {
        input: {
            name: this.props.directory?.name || "",
            parentId: this.props.currentDirectory,
        },
        isLoading: false,
    };

    private readonly onSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault();
        try {
            if (this.props.directory) {
                const response = await Api.updateAssetDirectory({
                    id: this.props.directory.id,
                    input: this.state.input as UpdateAssetDirectoryInput,
                });
                if (response !== null) {
                    Alert.success({
                        title: Intl.formatMessage({ id: "components.directoryModal.messages.updateSuccess" }),
                    });
                    this.props.onModalClose();
                    this.props.refreshDirectories(response);
                    return;
                }
            } else {
                const response = await Api.createAssetDirectory({
                    input: this.state.input as CreateAssetDirectoryInput,
                });
                if (response !== null) {
                    Alert.success({
                        title: Intl.formatMessage({ id: "components.directoryModal.messages.createSuccess" }),
                    });
                    this.props.onModalClose();
                }
            }
            this.props.refreshDirectories();
        } catch (error) {
            Alert.error({ title: Intl.getMessageFromError(error) });
        }
    };

    private readonly onNameChange = (e: React.FormEvent<HTMLInputElement>): void => {
        this.setState({
            input: {
                ...this.state.input,
                name: e.currentTarget.value,
            },
        });
    };

    public render(): React.ReactElement {
        return (
            <Modal
                titleText={Intl.formatMessage({
                    id: `components.directoryModal.${this.props.directory !== undefined ? "update" : "create"}Title`,
                })}
                mounted={this.props.mounted}
                underlayColor={Color.modal}
                onModalClose={this.props.onModalClose}
            >
                <ModalHeader onCloseClick={this.props.onModalClose}>
                    <H2>
                        {Intl.formatMessage({
                            id: `components.directoryModal.${
                                this.props.directory !== undefined ? "update" : "create"
                            }Title`,
                        })}
                    </H2>
                </ModalHeader>
                <form onSubmit={this.onSubmit}>
                    <ModalInputWrapper>
                        <InputWrapper fakeLabel={Intl.formatMessage({ id: "components.directoryModal.form.name" })}>
                            <Input
                                aria-label={Intl.formatMessage({ id: "components.directoryModal.form.name" })}
                                type="text"
                                value={this.state.input.name || ""}
                                onChange={this.onNameChange}
                            />
                        </InputWrapper>
                    </ModalInputWrapper>
                    <ButtonWrapper>
                        <Button.Secondary
                            disabled={this.state.isLoading}
                            type={"submit"}
                            btnLabel={Intl.formatMessage({ id: "common.save" })}
                        />
                    </ButtonWrapper>
                </form>
            </Modal>
        );
    }
}

export { DirectoryModal };
