import * as React from "react";

function SvgIcnHome(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} {...props}>
            <path
                d="M8.677.265a.997.997 0 00-1.354 0L.333 6.7c-.683.628-.245 1.78.677 1.78h.943v6.499c0 .564.451 1.022 1.008 1.022h2.5a1 1 0 001-1v-4.112h3.024V15a1 1 0 001 1h2.554c.557 0 1.008-.458 1.008-1.022v-6.5h.943c.922 0 1.36-1.151.677-1.78L8.677.266z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgIcnHome;
