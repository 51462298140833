import { Language } from "@i18n/Intl";
import { AppConfigurationType } from "@redux/reducers/AppStateReducer";
import { Asset, SearchListSortField, SortOrder } from "@api/graphql/types";

export enum AppStateActionType {
    updateLanguage = "@appState/updateLanguage",
    toggleSidebar = "@appState/toggleSidebar",
    toggleAssetListDisplay = "@appState/toggleAssetListDisplay",
    setAppConfiguration = "@appState/setAppConfiguration",
    setCurrentDirectory = "@appState/setCurrentDirectory",
    toggleSelectedAsset = "@appState/toggleSelectedAsset",
    setSelectedAssets = "@appState/setSelectedAssets",
    clearSelectedAssets = "@appState/clearSelectedAssets",
    setAssetSort = "@appState/setAssetSort",
    setAssetSearch = "@appState/setAssetSearch",
    setAssetOffset = "@appState/setAssetOffset",
    clearAssetOptions = "@appState/clearAssetOptions",
}

interface UpdateLanguage {
    type: AppStateActionType.updateLanguage;
    language: Language;
}
interface ToggleSidebar {
    type: AppStateActionType.toggleSidebar;
    sidebarOpened: boolean;
}

interface ToggleAssetListDisplay {
    type: AppStateActionType.toggleAssetListDisplay;
    assetListDisplay: boolean;
}

interface SetAppConfiguration {
    type: AppStateActionType.setAppConfiguration;
    options: AppConfigurationType;
    isUpdate: boolean;
}

interface SetCurrentDirectory {
    type: AppStateActionType.setCurrentDirectory;
    directory: string | null;
}

interface ToggleSelectedAsset {
    type: AppStateActionType.toggleSelectedAsset;
    asset: Asset;
}

interface SetSelectedAssets {
    type: AppStateActionType.setSelectedAssets;
    assets: Asset[];
}

interface ClearSelectedAssets {
    type: AppStateActionType.clearSelectedAssets;
}

interface SetAssetSort {
    type: AppStateActionType.setAssetSort;
    field: SearchListSortField;
    direction: SortOrder;
}

interface SetAssetSort {
    type: AppStateActionType.setAssetSort;
    field: SearchListSortField;
    direction: SortOrder;
}

interface SetAssetSearch {
    type: AppStateActionType.setAssetSearch;
    search: string;
}

interface SetAssetOffset {
    type: AppStateActionType.setAssetOffset;
    offset: number;
}

interface ClearAssetOptions {
    type: AppStateActionType.clearAssetOptions;
}

export class AppStateActions {
    public static updateLanguage(language: Language): UpdateLanguage {
        return { type: AppStateActionType.updateLanguage, language };
    }

    public static toggleSidebar(sidebarOpened: boolean): ToggleSidebar {
        return { type: AppStateActionType.toggleSidebar, sidebarOpened };
    }

    public static toggleAssetListDisplay(assetListDisplay: boolean): ToggleAssetListDisplay {
        return { type: AppStateActionType.toggleAssetListDisplay, assetListDisplay };
    }

    public static setAppConfiguration(options: AppConfigurationType, isUpdate: boolean): SetAppConfiguration {
        return { type: AppStateActionType.setAppConfiguration, options, isUpdate };
    }

    public static setCurrentDirectory(directory: string | null): SetCurrentDirectory {
        return { type: AppStateActionType.setCurrentDirectory, directory };
    }

    public static toggleSelectedAsset(asset: Asset): ToggleSelectedAsset {
        return { type: AppStateActionType.toggleSelectedAsset, asset };
    }

    public static setSelectedAssets(assets: Asset[]): SetSelectedAssets {
        return { type: AppStateActionType.setSelectedAssets, assets };
    }

    public static clearSelectedAssets(): ClearSelectedAssets {
        return { type: AppStateActionType.clearSelectedAssets };
    }

    public static setAssetSort(field: SearchListSortField, direction: SortOrder): SetAssetSort {
        return { type: AppStateActionType.setAssetSort, field, direction };
    }

    public static setAssetSearch(search: string): SetAssetSearch {
        return { type: AppStateActionType.setAssetSearch, search };
    }

    public static setAssetOffset(offset: number): SetAssetOffset {
        return { type: AppStateActionType.setAssetOffset, offset };
    }

    public static clearAssetOptions(): ClearAssetOptions {
        return { type: AppStateActionType.clearAssetOptions };
    }
}

export type AppStateAction =
    | UpdateLanguage
    | ToggleSidebar
    | ToggleAssetListDisplay
    | SetAppConfiguration
    | SetCurrentDirectory
    | ToggleSelectedAsset
    | SetSelectedAssets
    | ClearSelectedAssets
    | SetAssetSort
    | SetAssetSearch
    | SetAssetOffset
    | ClearAssetOptions;
