import React from "react";
import { AnyAssetDirectory } from "@api/ApiTypes";
import Modal, { ButtonWrapper, ModalHeader } from "@components/Modals/Modal";
import { Color } from "@theme/Theme";
import { Intl } from "@i18n/Intl";
import { Asset } from "@api/graphql/types";
import styled from "styled-components";
import SvgIcnArrowRight from "@components/svg/IcnArrowRight";
import { SSkeletonLine } from "@components/Skeleton";
import { Api } from "@api/Api";
import { Alert } from "@components/Alert/Alert";
import SvgIcnArrowLeft from "@components/svg/IcnArrowLeft";
import { Button } from "@components/Button";

type Props = {
    mounted: boolean;
    onModalClose: () => void;
    selectedAssets: Asset[];
};

interface State {
    isLoading: boolean;
    directories: AnyAssetDirectory[];
    selectedDirectory: AnyAssetDirectory | null;
    currentDirectory: AnyAssetDirectory | null;
    parentDirectory: AnyAssetDirectory | null;
}

class AssetMoveModal extends React.Component<Props, State> {
    public state: State = {
        isLoading: true,
        directories: [],
        selectedDirectory: null,
        currentDirectory: null,
        parentDirectory: null,
    };

    componentDidMount() {
        this.fetchDirectories();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        if ((!prevProps.mounted && this.props.mounted) || prevState.currentDirectory !== this.state.currentDirectory) {
            this.fetchDirectories();
            this.setState({
                parentDirectory: prevState.currentDirectory,
            });
        }
    }

    private readonly fetchDirectories = (): void => {
        this.setState(
            {
                isLoading: true,
            },
            async () => {
                try {
                    const response = await Api.getAssetDirectoriesByParentId(
                        this.state.currentDirectory ? this.state.currentDirectory.id : null
                    );
                    this.setState({
                        isLoading: false,
                        directories: response,
                    });
                } catch (error) {
                    Alert.error({ title: Intl.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            }
        );
    };

    private readonly onNavigate = (directory: AnyAssetDirectory | null): void => {
        this.setState({
            currentDirectory: directory,
            selectedDirectory: null,
        });
    };

    private readonly onMove = (): void => {
        const selectedDirectory = this.state.selectedDirectory;
        if (!selectedDirectory) {
            return;
        }
        this.setState(
            {
                isLoading: true,
            },
            async () => {
                try {
                    await Api.moveAssetsToAssetDirectory(
                        this.props.selectedAssets.map(asset => asset.id),
                        selectedDirectory.id
                    );
                    Alert.success({ title: Intl.formatMessage({ id: "components.assetMoveModal.moveSuccess" }) });
                    window.dispatchEvent(new CustomEvent("refreshAssets"));
                    this.props.onModalClose();
                } catch (error) {
                    Alert.error({ title: Intl.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            }
        );
    };

    private readonly toggleSelectedDirectory = (directory: AnyAssetDirectory): void => {
        const { selectedDirectory } = this.state;
        if (selectedDirectory?.id === directory.id) {
            this.setState({
                selectedDirectory: null,
            });
        }
        this.setState({
            selectedDirectory: directory,
        });
    };

    private readonly dataLoadingView = (columnCount = 2, rowCount = 4) => {
        let i = 0;
        return (
            <>
                {new Array(rowCount).fill("1").map(() => {
                    return (
                        <tr key={`skeleton-${++i}`}>
                            {new Array(columnCount).fill("1").map(() => {
                                return (
                                    <td key={`skeleton-${++i}`}>
                                        <SSkeletonLine width={"100%"} />
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </>
        );
    };

    private readonly renderDirectories = (directories: AnyAssetDirectory[]): React.ReactElement[] => {
        return directories.map(
            (directory: AnyAssetDirectory, key: number): React.ReactElement => {
                return (
                    <tr
                        key={key}
                        className={`clickable ${directory.id === this.state.selectedDirectory?.id ? "selected" : ""}`}
                        onClick={() => this.toggleSelectedDirectory(directory)}
                    >
                        <td>{directory.name}</td>
                        <td width={"60px"}>
                            {directory.children?.length && (
                                <StyledTableButton onClick={() => this.onNavigate(directory)}>
                                    <SvgIcnArrowRight />
                                </StyledTableButton>
                            )}
                        </td>
                    </tr>
                );
            }
        );
    };

    public render(): React.ReactElement {
        return (
            <Modal
                mounted={this.props.mounted}
                titleText={Intl.formatMessage({ id: "components.assetMoveModal.title" })}
                onModalClose={this.props.onModalClose}
                underlayColor={Color.modal}
            >
                <ModalHeader>
                    <h1>{Intl.formatMessage({ id: "components.assetMoveModal.title" })}</h1>
                </ModalHeader>
                <StyledTableWrapper>
                    <ScrollableWrapper>
                        <StyledTable>
                            <tbody>
                                {!!this.state.currentDirectory && (
                                    <tr
                                        className="clickable"
                                        onClick={() => this.onNavigate(this.state.parentDirectory)}
                                    >
                                        <td width={20}>
                                            <SvgIcnArrowLeft />
                                        </td>
                                        <td>{Intl.formatMessage({ id: "components.assetMoveModal.back" })}</td>
                                    </tr>
                                )}
                                {this.state.isLoading
                                    ? this.dataLoadingView()
                                    : this.renderDirectories(this.state.directories)}
                            </tbody>
                        </StyledTable>
                    </ScrollableWrapper>
                </StyledTableWrapper>
                <ButtonWrapper>
                    <Button.Primary
                        btnLabel={Intl.formatMessage({ id: "common.cancel" })}
                        onClick={() => this.props.onModalClose()}
                    />
                    <Button.Secondary
                        btnLabel={Intl.formatMessage({ id: "common.move" })}
                        onClick={() => this.onMove()}
                        disabled={this.state.isLoading || !this.state.selectedDirectory}
                    />
                </ButtonWrapper>
            </Modal>
        );
    }
}

const StyledTableButton = styled.button`
    width: 40px;
    height: 40px;
    transition: all 300ms ease-in-out;
    z-index: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    float: right;

    &:hover {
        background: ${Color.white};
        box-shadow: 0px 6px 12px rgba(217, 225, 255, 0.5);
    }
`;

const StyledTable = styled.table`
    border-collapse: collapse;
    position: relative;
    width: 100%;

    tbody {
        tr {
            min-height: 59px;
            border-top: 1px solid ${Color.grayD};
            border-bottom: 1px solid ${Color.grayD};

            &.clickable:hover {
                cursor: pointer;
                background-color: ${Color.grayL};
            }
            &.selected {
                background-color: ${Color.grayD};
                &:hover {
                    background-color: ${Color.grayD} !important;
                    box-shadow: 0px 0.5px 0px 2px ${Color.secondary} inset;
                    border-radius: 5px;
                }
            }

            &.disabled {
                background-color: ${Color.grayD};
                opacity: 0.3;
                &:hover {
                    background-color: ${Color.grayD} !important;
                    cursor: not-allowed;
                }
            }

            td {
                padding: 12px;
            }
        }
    }
`;

export const StyledTableWrapper = styled.div`
    overflow: hidden;
`;

export const ScrollableWrapper = styled.div`
    max-height: 400px;
    overflow: scroll;
`;

export { AssetMoveModal };
