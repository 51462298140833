import * as React from "react";

function SvgIcnWarning(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={18} height={17} fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} {...props}>
            <path
                d="M.738 13.27c-.772 1.334.19 3.003 1.73 3.003h13.063c1.54 0 2.503-1.669 1.731-3.002L10.731 1.99C9.96.66 8.039.66 7.269 1.99L.738 13.27zm9.171-.634a.91.91 0 11-1.818 0 .91.91 0 011.818 0zM9.91 9a.91.91 0 11-1.818 0V7.182a.91.91 0 111.818 0V9z"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#FF6542"
            />
        </svg>
    );
}

export default SvgIcnWarning;
