import * as React from "react";

function SvgIcnArrowLeft(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={7} height={10} fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} {...props}>
            <path d="M5.5 1l-4 4 4 4" stroke="currentColor" strokeWidth={1.5} />
        </svg>
    );
}

export default SvgIcnArrowLeft;
