import * as React from "react";

function SvgIcnMoveToFolder(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={12} height={10} fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} {...props}>
            <rect y={1} width={12} height={9} rx={1} fill="#fff" />
            <path
                d="M0 1a1 1 0 011-1h3.268a1 1 0 01.909.583l3.172 6.91a1 1 0 01-.908 1.418H1a1 1 0 01-1-1V1z"
                fill="#fff"
            />
        </svg>
    );
}

export default SvgIcnMoveToFolder;
