export const localeEn = {
    common: {
        loading: "Loading...",
        save: "Save",
        close: "Close",
        upload: "Upload",
        done: "Done",
    },
    components: {
        uploadModal: {
            title: "File upload",
            error: "Error",
            uploaded: "Uploaded",
        },
        dropzone: {
            placeholder: "Drag 'n' drop some files here, or click to select files",
        },
        directoryModal: {
            createTitle: "Create directory",
            updateTitle: "Modify directory",
            form: {
                name: "Directory name",
            },
            messages: {
                createSuccess: "The directory successfully created!",
                updateSuccess: "The directory successfully modified!",
            },
        },
    },
    error: {
        auth: {
            title: "You didn't provide any authentication info",
            description: "The media library will be unusable until you don't provide an authentication key",
        },
        api: {
            INVALID_EMBED_CONFIG: "The given embed config is invalid!",
            UNKNOWN: "Unknown error.",
            INTERNAL_SERVER_ERROR: "Internal server error.",
            UNAUTHENTICATED: "Unauthenticated.",
        },
    },
};
