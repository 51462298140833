import React, { HTMLAttributes } from "react";
import styled from "styled-components";

type FoldingdivState = {
    height: number;
};

type FoldingdivProps = {
    isOpened: boolean;
} & HTMLAttributes<unknown>;

export class FoldingView extends React.Component<FoldingdivProps> {
    // TODO: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    foldableRef = React.createRef<any>();

    state: FoldingdivState = {
        height: 0,
    };

    // TODO: window resize
    // private setHeight = () => {
    //     const height = (this.foldableRef.current && this.foldableRef.current.scrollHeight) || 0;

    //     if (this.state.height !== height) {
    //         this.setState({ height });
    //     }
    // };

    // componentDidMount() {
    //     this.setHeight();
    // }

    render() {
        return (
            // TODO: temporarily simplified
            // <FoldableElement style={{ height: this.props.isOpened ? this.state.height : 0 }}>
            //     <div ref={this.foldableRef}>{this.props.children}</div>
            // </FoldableElement>

            <FoldableElement style={{ display: this.props.isOpened ? "block" : "none" }}>
                <div>{this.props.children}</div>
            </FoldableElement>
        );
    }
}

const FoldableElement = styled.div`
    overflow: hidden;
    transition: height 250ms ease;
    will-change: height;

    > div {
        overflow: hidden;
    }
`;
