import React from "react";
import styled from "styled-components";
import SvgIcnSearch from "./svg/IcnSearch";
import { showForSr } from "../theme/GlobalStyle";

type SearchProps = {
    label?: string;
    placeholder?: string;
    inputName?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value?: string | null;
};

type SearchState = {
    inputFocus: boolean;
};

export class Search extends React.Component<SearchProps, SearchState> {
    readonly state = { inputFocus: false };

    setFocus = () => this.setState({ inputFocus: true });
    removeFocus = () => this.setState({ inputFocus: false });

    private readonly onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
    };

    render() {
        const { label, placeholder, inputName } = this.props;
        const { inputFocus } = this.state;

        return (
            <SearchForm $isActive={inputFocus} onSubmit={this.onSubmit}>
                <SearchLabel>{label}</SearchLabel>
                <SearchInput
                    name={inputName}
                    placeholder={placeholder}
                    onFocus={this.setFocus}
                    onBlur={this.removeFocus}
                    value={this.props.value || ""}
                    onChange={this.props.onChange}
                />
                <button type="submit" aria-label="Press to search">
                    <SvgIcnSearch />
                </button>
                <p>{this.state.inputFocus}</p>
            </SearchForm>
        );
    }
}

const SearchForm = styled.form<{ $isActive: boolean }>`
    position: relative;
    display: flex;
    width: 340px;
    min-width: 170px;
    height: 24px;
    margin: 0 20px 0 auto;
    border: 1px solid ${props => props.theme.appBar.headerSection.search.backgroundColor};
    border-radius: 25px;
    overflow: hidden;

    ${props => (props.$isActive ? "box-shadow: 0 0 1px gray;" : "")}

    button {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        color: ${props => props.theme.appBar.headerSection.search.color};
        background-color: ${props => props.theme.appBar.headerSection.search.backgroundColor};
        width: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const SearchInput = styled.input.attrs(() => ({
    id: "search",
    type: "text",
    inputMode: "search",
}))`
    width: 100%;
    padding: 0 38px 0 10px;
    background-color: transparent;
    border: 0;
    font-size: 12px;

    &::placeholder {
        color: ${props => props.theme.appBar.headerSection.search.backgroundColor};
    }
`;

const SearchLabel = styled.label.attrs(() => ({
    htmlFor: "search",
}))`
    ${showForSr}
`;
