import gql from "graphql-tag";
import { DocumentNode } from "graphql";
import { Fragments } from "@api/graphql/queries/Fragments";

class Queries {
    public static readonly getAssetDirectoriesByParentId: DocumentNode = gql`
        query getAssetDirectoriesByParentId($id: ID) {
            getAssetDirectoriesByParentId(id: $id) {
                ...AssetDirectory
            }
        }
        ${Fragments.assetDirectory}
    `;

    public static readonly getAssetDirectoryById: DocumentNode = gql`
        query getAssetDirectoryById($id: ID!) {
            getAssetDirectoryById(id: $id) {
                ...AssetDirectory
            }
        }
        ${Fragments.assetDirectory}
    `;

    public static readonly search: DocumentNode = gql`
        query search($options: SearchInput) {
            search(options: $options) {
                count
                result {
                    ...Asset
                }
            }
        }
        ${Fragments.asset}
    `;

    public static readonly getAssetByFileName: DocumentNode = gql`
        query getAssetByFileName($fileName: String!) {
            getAssetByFileName(fileName: $fileName) {
                ...Asset
            }
        }
        ${Fragments.asset}
    `;
}

export { Queries };
