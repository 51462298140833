import * as sentry from "@sentry/browser";
import { Severity } from "@sentry/browser";
import { LogLevel } from "@sentry/types";

const aggregateParams = (...params: unknown[]): string => {
    let args = "";
    for (const arg of params) {
        if (!arg || typeof arg === "string" || typeof arg === "number") {
            args += arg;
        } else {
            args += JSON.stringify(arg);
        }
    }
    return args;
};

/* tslint:disable:no-console */
export class Log {
    public static init(): void {
        if (process.env.REACT_APP_SENTRY_ENABLED === "true") {
            console.log("Initializing sentry");
            sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN, logLevel: LogLevel.Error });
        } else {
            console.info();
        }
    }

    public static debug(...params: unknown[]): void {
        this.log(Severity.Debug, ...params);
    }

    public static info(...params: unknown[]): void {
        this.log(Severity.Info, ...params);
    }

    public static warning(...params: unknown[]): void {
        this.log(Severity.Warning, ...params);
    }

    public static error(error: Error): void {
        if (process.env.REACT_APP_SENTRY_ENABLED === "true") {
            sentry.captureException(error);
        } else {
            this.log(Severity.Error, error);
        }
    }

    private static log(level: Severity, ...params: unknown[]): void {
        if (process.env.REACT_APP_SENTRY_ENABLED === "true") {
            // Sentry LogLevel not working, so manually select logs
            switch (level) {
                case Severity.Fatal:
                case Severity.Error:
                case Severity.Warning:
                case Severity.Critical:
                    sentry.captureMessage(aggregateParams(...params), level);
                    return;
                default:
                    return;
            }
        } else {
            switch (level) {
                case Severity.Error:
                case Severity.Fatal:
                    console.error(level, ...params);
                    return;
                case Severity.Warning:
                    console.warn(level, ...params);
                    return;
                case Severity.Info:
                    console.info(level, ...params);
                    return;
                case Severity.Debug:
                default:
                    console.log(level, ...params);
                    return;
            }
        }
    }
}
