import React from "react";
import { Asset } from "@api/graphql/types";
import { SSkeletonLine } from "@components/Skeleton";
import { Intl } from "@i18n/Intl";
import { FileSizeUtils, FileSize } from "@utils/FileSizeUtils";
import { MimeType } from "@utils/MimeType";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Checkbox, CheckboxLabel, Checkmark } from "@components/Checkbox";
import { Flex } from "@components/Flex";
import ModalAbsolute, { ModalAlignment, Menu } from "@components/Modals/ModalAbsolute";
import { StyledMenuOptions } from "@components/Modals/StyledMenu";
import SvgIcnCopy from "@components/svg/IcnCopy";
import SvgIcnDelete from "@components/svg/IcnDelete";
import SvgIcnDots from "@components/svg/IcnDots";
import SvgIcnDownload from "@components/svg/IcnDownload";
import SvgIcnRename from "@components/svg/IcnRename";
import SvgIcnThumbnail from "@components/svg/IcnThumbnail";
import { random } from "@utils/Helpers";

type Props = {
    asset: Asset;
    selected: boolean;
    onSelect: (asset: Asset) => void;
    onUrlCopy: (url: string) => void;
    onRename: (asset: Asset) => void;
    onDelete: (asset: Asset) => void;
    onShow: (asset: Asset) => void;
};

export class Image extends React.Component<Props> {
    private imageRef: React.RefObject<HTMLImageElement> = React.createRef<HTMLImageElement>();

    public componentDidMount() {
        if (this.imageRef.current) {
            this.imageRef.current.ondblclick = this.onDoubleClick;
            this.imageRef.current.onclick = this.onSingleClick;
        }
    }

    private readonly onSingleClick = (): void => {
        this.props.onSelect(this.props.asset);
    };

    private readonly onDoubleClick = (): void => {
        this.props.onShow(this.props.asset);
    };

    render() {
        const { asset, selected } = this.props;
        const url = asset.url.replace(asset.fileName, `w_250/${asset.fileName}`);
        return (
            <ImageWrapper $isSelected={selected}>
                <ImageOptions>
                    <Checkbox>
                        <CheckboxLabel>Select image</CheckboxLabel>
                        <input type="checkbox" onChange={() => this.props.onSelect(asset)} checked={selected} />
                        <Checkmark />
                    </Checkbox>
                    <ModalAbsolute
                        titleText="Options"
                        modalAlignment={ModalAlignment.BottomRight}
                        activateElement={
                            <button type="button" aria-label="Options">
                                <SvgIcnDots />
                            </button>
                        }
                        containerPositionFixed={true}
                        preventDefaultClick={true}
                        useOffsetParentForCalculation={true}
                    >
                        <StyledMenuOptions>
                            <Menu.Option
                                btnLabel={Intl.formatMessage({ id: "components.assets.options.copyUrl" })}
                                icon={<SvgIcnCopy />}
                                iconPosition="left"
                                onClick={() => this.props.onUrlCopy(asset.url)}
                            />
                            <StyledLink to={asset.url} target="_blank" download>
                                <Menu.Option
                                    btnLabel={Intl.formatMessage({ id: "components.assets.options.download" })}
                                    icon={<SvgIcnDownload />}
                                    iconPosition="left"
                                />
                            </StyledLink>
                            <Menu.Option
                                btnLabel={Intl.formatMessage({ id: "components.assets.options.rename" })}
                                icon={<SvgIcnRename />}
                                iconPosition="left"
                                onClick={() => this.props.onRename(asset)}
                            />
                            <Menu.Option
                                btnLabel={Intl.formatMessage({ id: "components.assets.options.delete" })}
                                icon={<SvgIcnDelete color={"black"} />}
                                iconPosition="left"
                                onClick={() => this.props.onDelete(asset)}
                            />
                        </StyledMenuOptions>
                    </ModalAbsolute>
                </ImageOptions>
                <ImageContent>
                    <ImageTitle>{asset.title}</ImageTitle>
                    <ImageDetails>
                        <ImageFormat>
                            <SvgIcnThumbnail />
                            {MimeType.extension(asset.info.mimeType) || Intl.formatMessage({ id: "common.unknown" })}
                        </ImageFormat>
                        <span className="size">{FileSizeUtils.formatSize(asset.fileSize, FileSize.KB)} KB</span>
                        <span className="dimension">{`${asset.info.width}x${asset.info.height}`}</span>
                    </ImageDetails>
                </ImageContent>
                <StyledImage ref={this.imageRef} src={url.toString()} alt={asset.fileName} />
            </ImageWrapper>
        );
    }
}

export const LoadingImage = (): React.ReactElement => {
    return (
        <ImageWrapper $isSelected={false}>
            <ImageContent>
                <ImageTitle>
                    <SSkeletonLine width={`${random(50, 120)}px`} height={"12px"} />
                </ImageTitle>
                <ImageDetails>
                    <ImageFormat>
                        <SSkeletonLine />
                    </ImageFormat>
                    <span className="size">
                        <SSkeletonLine width={`${random(30, 50)}px`} height={"11px"} />
                    </span>
                    <span className="dimension">
                        <SSkeletonLine width={"60px"} height={"11px"} />
                    </span>
                </ImageDetails>
            </ImageContent>
            <SSkeletonLine
                height={"100%"}
                width={"100%"}
                style={{ position: "absolute", top: "0px", left: "0px", objectFit: "cover", zIndex: -1 }}
            />
        </ImageWrapper>
    );
};

const StyledImage = styled.img`
    &:hover {
        cursor: pointer;
    }
`;

const StyledLink = styled(Link)`
    text-decoration: none;
`;

const ImageOptions = styled(Flex.Container).attrs(() => ({
    $alignItems: "center",
}))`
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 12px;
    background: ${props => props.theme.image.overlay};

    ${Checkbox},
    button {
        width: 20px;
        height: 20px;
    }

    ${Checkbox} {
        margin-right: 10px;

        ${Checkmark} {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    button {
        display: flex;
        color: ${props => props.theme.image.backgroundColor};

        &:last-of-type {
            margin-left: auto;
        }

        svg {
            margin: auto;
        }
    }

    input {
        margin-right: 10px;
        cursor: pointer;
    }
`;

const ImageTitle = styled.div`
    padding: 6px 10px;
    border-top: 1px solid ${props => props.theme.image.borderColor};
    border-bottom: 1px solid ${props => props.theme.image.borderColor};
    line-height: 16px;
    font-weight: 700;
    word-break: break-all;
`;

const ImageContent = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: ${props => props.theme.image.backgroundColor};
    font-size: 12px;
`;

const ImageDetails = styled(Flex.Container).attrs(() => ({
    $alignItems: "center",
}))`
    padding: 0 5px;
    font-size: 10px;

    > span {
        padding: 0 5px;

        + span {
            position: relative;
            padding-left: 5px;
            // border-left: 1px solid ${props => props.theme.image.borderColor};

            &::before {
                content: "";
                position: relative;
                width: 0;
                height: 15px;
                margin-right: 10px;
                border-left: 1px solid ${props => props.theme.image.borderColor};
            }
        }
    }
`;

const ImageFormat = styled(Flex.Container).attrs(() => ({
    as: "span",
    $display: "inline-flex",
    $alignItems: "center",
}))`
    text-transform: uppercase;

    svg {
        margin-right: 5px;
    }
`;

const ImageWrapper = styled.div<{ $isSelected: boolean }>`
    position: relative;
    width: 220px;
    height: 220px;
    margin-top: 20px;
    margin-right: 20px;
    border: 1px solid ${props => props.theme.image.borderColor};

    @supports (display: grid) {
        width: 100%;
        height: 0;
        padding-top: 100%;
        margin: 0;
    }

    &::after {
        content: "";
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: inset 0 0 0 3px ${props => props.theme.image.selectedColor};
        pointer-events: none;
    }

    ${props =>
        props.$isSelected
            ? `

            ${ImageOptions} {
                display: flex;
                background-color: transparent;

                button {
                    visibility: hidden;
                }
            }

            &:hover {
                ${ImageOptions} {
                    button {
                        visibility: visible;
                    }
                }
            }
            
            &::after {
        display: block;
    }`
            : ""}

    &:hover {
        filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.2));

        ${ImageOptions} {
            display: flex;
        }
    }

    img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        z-index: -1;
        background: repeating-conic-gradient(#8080804d 0% 25%, transparent 0% 50%) 50% / 20px 20px;
    }
`;
