import React from "react";
import { Asset } from "@api/graphql/types";
import { Checkbox, CheckboxLabel, Checkmark } from "@components/Checkbox";
import { Flex } from "@components/Flex";
import ModalAbsolute, { ModalAlignment, Menu } from "@components/Modals/ModalAbsolute";
import { StyledMenuOptions } from "@components/Modals/StyledMenu";
import { SSkeletonLine } from "@components/Skeleton";
import SvgIcnCopy from "@components/svg/IcnCopy";
import SvgIcnDelete from "@components/svg/IcnDelete";
import SvgIcnDots from "@components/svg/IcnDots";
import SvgIcnDownload from "@components/svg/IcnDownload";
import SvgIcnLink from "@components/svg/IcnLink";
import SvgIcnRename from "@components/svg/IcnRename";
import SvgIcnThumbnail from "@components/svg/IcnThumbnail";
import { Intl } from "@i18n/Intl";
import { FileSizeUtils, FileSize } from "@utils/FileSizeUtils";
import { MimeType } from "@utils/MimeType";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { random } from "@utils/Helpers";

type Props = {
    asset: Asset;
    selected: boolean;
    onSelect: (asset: Asset) => void;
    onUrlCopy: (url: string) => void;
    onRename: (asset: Asset) => void;
    onDelete: (asset: Asset) => void;
};

export class ImageRow extends React.Component<Props> {
    render() {
        const { asset, selected } = this.props;
        const url = asset.url.replace(asset.fileName, `w_20/${asset.fileName}`);
        return (
            <StyledImageRow $isSelected={selected}>
                <StyledImageRowCell>
                    <StyledImageRowButtons>
                        <Checkbox>
                            <CheckboxLabel>Select image</CheckboxLabel>
                            <input type="checkbox" onChange={() => this.props.onSelect(asset)} checked={selected} />
                            <Checkmark />
                        </Checkbox>
                        <button type="button">
                            <SvgIcnLink />
                        </button>
                        <ModalAbsolute
                            titleText="Options"
                            modalAlignment={ModalAlignment.BottomRight}
                            activateElement={
                                <button type="button" aria-label="Options">
                                    <SvgIcnDots />
                                </button>
                            }
                            containerPositionFixed={true}
                            preventDefaultClick={true}
                        >
                            <StyledMenuOptions>
                                <Menu.Option
                                    btnLabel={Intl.formatMessage({ id: "components.assets.options.copyUrl" })}
                                    icon={<SvgIcnCopy />}
                                    iconPosition="left"
                                    onClick={() => this.props.onUrlCopy(asset.url)}
                                />
                                <Link to={asset.url} target="_blank" download>
                                    <Menu.Option
                                        btnLabel={Intl.formatMessage({ id: "components.assets.options.download" })}
                                        icon={<SvgIcnDownload />}
                                        iconPosition="left"
                                    />
                                </Link>
                                <Menu.Option
                                    btnLabel={Intl.formatMessage({ id: "components.assets.options.rename" })}
                                    icon={<SvgIcnRename />}
                                    iconPosition="left"
                                    onClick={() => this.props.onRename(asset)}
                                />
                                <Menu.Option
                                    btnLabel={Intl.formatMessage({ id: "components.assets.options.delete" })}
                                    icon={<SvgIcnDelete />}
                                    iconPosition="left"
                                    onClick={() => this.props.onDelete(asset)}
                                />
                            </StyledMenuOptions>
                        </ModalAbsolute>
                    </StyledImageRowButtons>
                </StyledImageRowCell>
                <StyledImageRowImage>
                    <img src={url.toString()} alt={asset.fileName} />
                </StyledImageRowImage>
                <StyledImageRowTitle>
                    <div>{asset.fileName}</div>
                </StyledImageRowTitle>
                <StyledImageRowFormat>
                    <Flex.Container $alignItems="center">
                        <SvgIcnThumbnail />
                        {MimeType.extension(asset.info.mimeType) || Intl.formatMessage({ id: "common.unknown" })}
                    </Flex.Container>
                </StyledImageRowFormat>
                <StyledImageRowCell>{FileSizeUtils.formatSize(asset.fileSize, FileSize.KB)} KB</StyledImageRowCell>
                <StyledImageRowCell>
                    <span className="dimension">{`${asset.info.width}x${asset.info.height}`}</span>
                </StyledImageRowCell>
            </StyledImageRow>
        );
    }
}

export const LoadingImageRow = (): React.ReactElement => {
    return (
        <StyledImageRow $isSelected={false}>
            <StyledImageRowCell>
                <StyledImageRowButtons>
                    <SSkeletonLine width={"50px"} />
                </StyledImageRowButtons>
            </StyledImageRowCell>
            <StyledImageRowImage>
                <SSkeletonLine height={"30px"} width={"30px"} />
            </StyledImageRowImage>
            <StyledImageRowTitle>
                <div>
                    <SSkeletonLine width={`${random(60, 180)}px`} />
                </div>
            </StyledImageRowTitle>
            <StyledImageRowFormat>
                <Flex.Container $alignItems="center">
                    <SSkeletonLine width={`${random(60, 120)}px`} />
                </Flex.Container>
            </StyledImageRowFormat>
            <StyledImageRowCell>
                <SSkeletonLine width={"50px"} />
            </StyledImageRowCell>
            <StyledImageRowCell>
                <span className="dimension">
                    <SSkeletonLine width={"50px"} />
                </span>
            </StyledImageRowCell>
        </StyledImageRow>
    );
};

const StyledImageRowImage = styled.td`
    width: 40px;

    img {
        width: 20px;
        height: 20px;
        vertical-align: middle;
        object-fit: cover;
    }
`;

const StyledImageRowButtons = styled(Flex.Container).attrs(() => ({
    $alignItems: "center",
}))`
    ${Checkbox},
    button {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        background-color: transparent;
    }

    ${Checkbox} {
        input,
        ${Checkmark} {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    button {
        svg {
            margin: auto;
        }
    }
`;

const StyledImageRowTitle = styled.td`
    width: 100%;

    > div {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

const StyledImageRowCell = styled.td`
    width: 120px;
`;

const StyledImageRowFormat = styled(StyledImageRowCell)`
    text-transform: uppercase;

    svg {
        margin-right: 10px;
    }
`;

const StyledImageRow = styled.tr<{ $isSelected?: boolean }>`
    border-bottom: 1px solid #d9e1ff;

    td {
        padding: 10px 0 10px 20px;
        white-space: nowrap;
    }

    &:hover {
        background-color: white;
        border-color: #cecece;
    }

    ${props =>
        props.$isSelected
            ? `
      background-color: white;
      border-color: #cecece;
    `
            : ""}
`;
