import React from "react";
import { Api } from "@api/Api";
import { Alert } from "@components/Alert/Alert";
import { Intl } from "@i18n/Intl";
import Modal, { ModalHeader, ModalInputWrapper } from "@components/Modals/Modal";
import { Color, hexToRGBA } from "@theme/Theme";
import Dropzone from "react-dropzone";
import styled from "styled-components";
import { Notification, NotificationDone, StyledNotificationWrapper } from "@components/Notification";
import { connect } from "react-redux";
import { ApplicationState } from "@redux/reducers";

type ReduxProps = {
    currentDirectory: string | null;
};

type Props = {
    mounted: boolean;
    onToggleModal: () => void;
} & ReduxProps;

type UploadStatus = {
    key: number;
    progress: number;
    isUploaded: boolean;
    error?: boolean;
};

interface State {
    uploads: UploadStatus[];
}

class UploadModalComponent extends React.Component<Props, State> {
    public state: State = {
        uploads: [],
    };

    public componentDidUpdate() {
        if (this.state.uploads.length > 0 && this.state.uploads.filter(upload => !upload.isUploaded).length === 0) {
            this.setState({ uploads: [] }, () => {
                window.dispatchEvent(new CustomEvent("refreshAssets"));
            });
        }
    }

    private readonly onFileSelected = async (files: File[]) => {
        if (files.length < 1) {
            return;
        }

        this.setState(
            {
                uploads: files.map((file, key) => {
                    return {
                        key: key,
                        progress: 0,
                        isUploaded: false,
                    };
                }),
            },
            () => {
                files.forEach((file, key) => this.onFileUpload(file, key));
            }
        );

        this.props.onToggleModal();
    };

    private readonly onProgressChange = (progress: number, key: number): void => {
        const currentUploads = this.state.uploads;
        currentUploads[key].progress = progress;
        this.setState({ uploads: currentUploads });
    };

    private readonly onFileUpload = async (file: File, key: number) => {
        try {
            await Api.uploadAsset(file.name, file, this.props.currentDirectory || null, (progress: number) =>
                this.onProgressChange(progress, key)
            );
            this.onUploadReady(key);
        } catch (error) {
            this.onUploadReady(key, true);
            Alert.error({ title: Intl.getMessageFromError(error) });
        }
    };

    private readonly onUploadReady = (key: number, error?: boolean) => {
        const currentUploads = this.state.uploads;
        currentUploads[currentUploads.findIndex(upload => upload.key === key)].isUploaded = true;
        currentUploads[currentUploads.findIndex(upload => upload.key === key)].error = !!error;
        this.setState({ uploads: currentUploads }, () => {
            setTimeout(() => {
                this.setState({
                    uploads: this.state.uploads.filter(upload => upload.key !== key),
                });
            }, 5000);
        });
    };

    private readonly renderNotifications = (uploads: UploadStatus[]): React.ReactElement[] => {
        return uploads.map(
            (upload: UploadStatus, key: number): React.ReactElement => {
                if (upload.isUploaded) {
                    return <NotificationDone key={key} isError={upload.error} />;
                }
                return <Notification key={key} progressPercent={upload.progress} />;
            }
        );
    };

    public render(): React.ReactElement {
        return (
            <>
                <Modal
                    titleText={Intl.formatMessage({ id: "components.uploadModal.title" })}
                    mounted={this.props.mounted}
                    underlayColor={Color.modal}
                    onModalClose={this.props.onToggleModal}
                >
                    <ModalHeader onCloseClick={this.props.onToggleModal}>
                        <h2>{Intl.formatMessage({ id: "components.uploadModal.title" })}</h2>
                    </ModalHeader>
                    <ModalInputWrapper>
                        <Dropzone onDrop={this.onFileSelected}>
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <DropzoneContainer {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <p>{Intl.formatMessage({ id: "components.dropzone.placeholder" })}</p>
                                    </DropzoneContainer>
                                </section>
                            )}
                        </Dropzone>
                    </ModalInputWrapper>
                </Modal>

                <StyledNotificationWrapper>{this.renderNotifications(this.state.uploads)}</StyledNotificationWrapper>
            </>
        );
    }
}

const DropzoneContainer = styled.div`
    width: 100%;
    height: 100px;
    border-radius: 3px;
    border: 1px solid ${Color.grayD};
    display: flex;
    justify-content: center;
    align-items: center;
    transition: ease-in-out all 300ms;

    &:hover {
        background-color: ${hexToRGBA(Color.grayD, 0.7)};
        cursor: pointer;
    }
`;

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return {
        currentDirectory: state.appState.currentDirectory,
    };
};

const UploadModal = connect(mapStateToProps)(UploadModalComponent);

export { UploadModal };
