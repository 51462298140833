export class DirectoryUtils {
    public static readonly getCurrentDirectory = (): string | null => {
        const currentDir = window.location.pathname;
        if (currentDir === "/" || !currentDir.startsWith("/directory")) {
            return null;
        }

        return currentDir.replace("/directory/", "");
    };
}
