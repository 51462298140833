import { applyMiddleware, createStore, Middleware, Store, compose } from "redux";
import { createLogger } from "redux-logger";
import { ApplicationState, rootReducer } from "@redux/reducers";
import { enableBatching, batchDispatchMiddleware } from "redux-batched-actions";

const middlewares: Middleware[] = [batchDispatchMiddleware];

if (process.env.NODE_ENV === "development") {
    const logger: Middleware = createLogger();
    middlewares.push(logger);
}

const composeEnhancers: (<R>(a: R) => R) | undefined = process.env.REACT_APP_DEBUG_MODE
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;
const enhancer = composeEnhancers ? composeEnhancers(applyMiddleware(...middlewares)) : undefined;

const store: Store<ApplicationState> = createStore(enableBatching(rootReducer), enhancer);

export { store };
