import * as React from "react";

function SvgIcnDots(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={3}
            height={15}
            viewBox="0 0 2 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            focusable={false}
            {...props}
        >
            <circle cx={1} cy={1} r={1} fill="currentColor" />
            <circle cx={1} cy={5} r={1} fill="currentColor" />
            <circle cx={1} cy={9} r={1} fill="currentColor" />
        </svg>
    );
}

export default SvgIcnDots;
