import * as React from "react";

function SvgIcnSearch(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={14} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} {...props}>
            <circle cx={6} cy={6} r={5} stroke="currentColor" strokeWidth={1.5} />
            <path d="M10 10l3 3" stroke="currentColor" strokeWidth={1.5} />
        </svg>
    );
}

export default SvgIcnSearch;
