import * as React from "react";

function SvgIcnRename(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={11} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} {...props}>
            <path
                d="M10.93.836l-.587-.587a.227.227 0 00-.329 0l-.47.47.917.915.47-.47c.093-.07.093-.234 0-.328zM4.192 6.095l-.235 1.15 1.15-.235 5.001-5-.915-.916-5.001 5z"
                fill="#445591"
            />
            <path
                d="M8.652 9.828c0 .14-.094.235-.234.235H1.374c-.14 0-.235-.094-.235-.235V2.784c0-.14.094-.235.235-.235h5.212l.94-.939H1.373C.717 1.61.2 2.127.2 2.784v7.044c0 .657.517 1.174 1.174 1.174h7.044c.657 0 1.174-.517 1.174-1.174V3.676l-.94.94v5.212z"
                fill="#445591"
            />
        </svg>
    );
}

export default SvgIcnRename;
