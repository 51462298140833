import React from "react";
import { Link as DomLink } from "react-router-dom";
import styled from "styled-components";

import { Flex } from "./Flex";

export type LinkProps = {
    ariaCurrent?: "page" | "step"; // https://www.w3.org/TR/wai-aria-1.1/#aria-current
    ariaLabel?: string;
    className?: string;
    to?: string | {};
    forceSameWindow?: boolean;
    linkIcon?: JSX.Element;
    linkIconGutter?: number;
    linkIconPosition?: "left" | "right";
    onClick?: (event: React.MouseEvent<unknown>) => void;
    "data-test-id"?: string;
};

export class Link extends React.Component<LinkProps> {
    static defaultProps = {
        linkIconPosition: "left",
    };

    getLinkClassName = () => {
        return `${this.props.linkIcon ? "link--inline-block" : ""}${
            this.props.className ? ` ${this.props.className}` : ""
        }`;
    };

    renderContent = () => {
        if (this.props.linkIcon) {
            return (
                <Flex.Container as="span" $alignItems="center" $gutterMargin={this.props.linkIconGutter}>
                    {this.props.linkIconPosition === "left" ? (
                        <>
                            <LinkIconCell
                                as="span"
                                $shrink="shrink"
                                $gutterMargin={this.props.linkIconGutter}
                                aria-hidden="true"
                            >
                                {this.props.linkIcon || null}
                            </LinkIconCell>

                            <Flex.Item as="span" $shrink="shrink" $gutterMargin={this.props.linkIconGutter}>
                                {this.props.children}
                            </Flex.Item>
                        </>
                    ) : (
                        <>
                            <Flex.Item as="span" $shrink="shrink" $gutterMargin={this.props.linkIconGutter}>
                                {this.props.children}
                            </Flex.Item>

                            <LinkIconCell
                                as="span"
                                $shrink="shrink"
                                $gutterMargin={this.props.linkIconGutter}
                                aria-hidden="true"
                            >
                                {this.props.linkIcon || null}
                            </LinkIconCell>
                        </>
                    )}
                </Flex.Container>
            );
        }

        return this.props.children;
    };

    renderInternal = () => {
        return (
            <DomLink
                to={this.props.to as string}
                onClick={this.props.onClick}
                className={this.getLinkClassName().trim()}
                aria-current={this.props.ariaCurrent}
                aria-label={this.props.ariaLabel}
                data-test-id={this.props["data-test-id"]}
            >
                {this.renderContent()}
            </DomLink>
        );
    };

    renderExternal = (openInSameWindow = false) => {
        return (
            <a
                className={this.getLinkClassName().trim()}
                onClick={this.props.onClick}
                href={this.props.to as string}
                target={openInSameWindow || this.props.forceSameWindow === true ? undefined : "_blank"}
                rel="noopener noreferrer"
                aria-label={this.props.ariaLabel}
                data-test-id={this.props["data-test-id"]}
            >
                {this.renderContent()}
            </a>
        );
    };

    renderFunctionLink = () => {
        return (
            <a
                href="#"
                className={this.getLinkClassName().trim()}
                aria-label={this.props.ariaLabel}
                // TODO: "Expected an assignment or function call and instead saw an expression  @typescript-eslint/no-unused-expressions"
                // onClick={e => {
                //     e.preventDefault();
                //     this.props.onClick?.(e);
                // }}
                data-test-id={this.props["data-test-id"]}
            >
                {this.renderContent()}
            </a>
        );
    };

    render() {
        if (typeof this.props.to === "string") {
            const isExternal = this.props.to.indexOf("http") === 0;
            const isEmail = this.props.to.indexOf("mailto") === 0;
            const isPhone = this.props.to.indexOf("tel") === 0;
            return isExternal || isEmail || isPhone ? this.renderExternal() : this.renderInternal();
        } else {
            return this.renderFunctionLink();
        }
    }
}

export const LinkIconCell = styled(Flex.Item)`
    width: 32px;

    svg {
        margin-left: auto;
        margin-right: auto;
    }
`;
