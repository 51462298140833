import SvgIcnSettings from "@components/svg/IcnSettings";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Color, hexToRGBA } from "@theme/Theme";
import { keyframesRotate360 } from "@theme/GlobalStyle";

const DELAY = 250;
const ANIM_TIME = 500;

interface Props {
    overrideDelay?: number;
}

const LoadingOverlay: React.FC<Props> = ({ overrideDelay }) => {
    const [mounted, setMounted] = useState(false);
    useEffect(() => {
        if (!mounted) {
            const timeout = setTimeout(() => setMounted(true), overrideDelay ?? DELAY);
            return () => {
                clearTimeout(timeout);
            };
        }
        return;
    }, [mounted, overrideDelay]);

    return (
        <Overlay className={mounted ? "mounted" : ""}>
            <div>
                <SvgIcnSettings viewBox="0 0 19 20" />
            </div>
        </Overlay>
    );
};

const Overlay = styled.div`
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(${hexToRGBA(Color.secondary)}, 0);
    transition: background-color ease-in-out ${ANIM_TIME}ms;

    > div {
        position: absolute;
        justify-content: center;
        align-items: center;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: auto;

        > svg {
            position: relative;
            top: calc(50% - 50px);
            left: calc(50% - 50px);
            width: 100px;
            height: 100px;
            margin: 0;
            transform-origin: 51.8% 50.8%;
            animation: ${keyframesRotate360} 1.2s linear infinite;
        }
        opacity: 0;
        transition: opacity ease-in-out ${ANIM_TIME}ms;
    }

    &.mounted {
        background-color: rgba(${hexToRGBA(Color.secondary)}, 0.4);
        > div {
            opacity: 1;
        }
    }

    z-index: 1100;
`;

export default LoadingOverlay;
