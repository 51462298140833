import * as React from "react";

function SvgIcnCopy(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={11} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} {...props}>
            <path
                d="M9.886 12.013H3.76V4.215h6.127v7.798zm0-8.912H3.76c-.613 0-1.114.501-1.114 1.114v7.798c0 .613.501 1.114 1.114 1.114h6.127c.613 0 1.114-.501 1.114-1.114V4.215C11 3.602 10.5 3.1 9.886 3.1zM8.216.873H1.53C.918.873.417 1.374.417 1.987v7.798h1.114V1.987h6.684V.873z"
                fill="#445591"
            />
        </svg>
    );
}

export default SvgIcnCopy;
