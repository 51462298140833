import { LoginPage } from "@pages/login/LoginPage";
import React from "react";
import { DirectoryPage } from "@pages/directory/DirectoryPage";
import { EmbeddedStartPage } from "@pages/EmbeddedStartPage";
import { LoginAuthTokenPage } from "@pages/login/LoginAuthTokenPage";
import { AlertContainer } from "@components/Alert/AlertContainer";
import { PrivateRoute } from "@components/Routes/PrivateRoute";
import { AppPath } from "@pages/paths";
import { SandboxPage } from "@pages/sandbox/SandboxPage";
import { Switch, Route } from "react-router-dom";

class App extends React.Component {
    public render(): React.ReactElement {
        return (
            <>
                <Switch>
                    <PrivateRoute exact={true} path={AppPath.Home} component={DirectoryPage} />
                    <Route path={AppPath.LoginAuthToken} component={LoginAuthTokenPage} />
                    <Route path={AppPath.EmbeddedStartPage} component={EmbeddedStartPage} />
                    <Route path={AppPath.Login} component={LoginPage} />
                    <PrivateRoute exact={true} path={AppPath.Directory} component={DirectoryPage} />

                    {process.env.NODE_ENV === "development" && (
                        <PrivateRoute exact path={AppPath.Sandbox} component={SandboxPage} />
                    )}
                </Switch>

                <AlertContainer />
            </>
        );
    }
}

export { App };
