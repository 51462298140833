import IcnArrowDown from "./IcnArrowDown";
import IcnArrowLeft from "./IcnArrowLeft";
import IcnArrowRight from "./IcnArrowRight";
import IcnCheckMark from "./IcnCheckMark";
import IcnClearAll from "./IcnClearAll";
import IcnClear from "./IcnClear";
import IcnClose from "./IcnClose";
import IcnCloud from "./IcnCloud";
import IcnCopy from "./IcnCopy";
import IcnDelete from "./IcnDelete";
import IcnDots from "./IcnDots";
import IcnDownload from "./IcnDownload";
import IcnFolderAdd from "./IcnFolderAdd";
import IcnFolder from "./IcnFolder";
import IcnHome from "./IcnHome";
import IcnLink from "./IcnLink";
import IcnMoveToFolder from "./IcnMoveToFolder";
import IcnReload from "./IcnReload";
import IcnRename from "./IcnRename";
import IcnSearch from "./IcnSearch";
import IcnSelectAll from "./IcnSelectAll";
import IcnSettings from "./IcnSettings";
import IcnTick from "./IcnTick";
import IcnView from "./IcnView";
import IcnWarning from "./IcnWarning";
import Logo from "./Logo";

export const Svg: { [key: string]: (props: React.SVGProps<SVGSVGElement>) => JSX.Element } = {
    IcnArrowDown,
    IcnArrowLeft,
    IcnArrowRight,
    IcnCheckMark,
    IcnClearAll,
    IcnClear,
    IcnClose,
    IcnCloud,
    IcnCopy,
    IcnDelete,
    IcnDots,
    IcnDownload,
    IcnFolderAdd,
    IcnFolder,
    IcnHome,
    IcnLink,
    IcnMoveToFolder,
    IcnReload,
    IcnRename,
    IcnSearch,
    IcnSelectAll,
    IcnSettings,
    IcnTick,
    IcnView,
    IcnWarning,
    Logo,
};
