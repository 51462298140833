import { Api } from "@api/Api";
import { Alert } from "@components/Alert/Alert";
import { Paths } from "@pages/paths";
import React from "react";
import { AssetDirectory, AssetDirectory_children } from "@api/graphql/types";
import { Intl } from "@i18n/Intl";
import { AppStateActions } from "@redux/actions/AppStateActions";
import { ApplicationState } from "@redux/reducers";
import { connect, DispatchProp, MapStateToProps } from "react-redux";
import styled from "styled-components";
import { Flex } from "@components/Flex";
import SvgIconClose from "@components/svg/IcnClose";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { DirectoryTree, DirectoryTreeItem } from "@components/DirectoryTree/DirectoryTree";

type ReduxProps = {
    sidebarOpened: boolean;
    currentDirectory: string | null;
};

type Props = ReduxProps & DispatchProp & RouteComponentProps;

interface State {
    isOpen: boolean;
    directories: DirectoryTreeItem[];
    isLoading: boolean;
}

class SidebarComponent extends React.Component<Props, State> {
    public state: State = {
        isOpen: this.props.sidebarOpened,
        directories: [],
        isLoading: true,
    };

    public componentDidMount(): void {
        this.fetchSidebarDirectories();
        window.addEventListener("refreshSidebarDirectories", () =>
            this.fetchSidebarDirectories(!!this.props.currentDirectory)
        );
    }

    public componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.sidebarOpened !== this.props.sidebarOpened) {
            this.setState({
                isOpen: this.props.sidebarOpened,
            });
        }
    }

    public componentWillUnmount(): void {
        window.removeEventListener("refreshSidebarDirectories", () =>
            this.fetchSidebarDirectories(!!this.props.currentDirectory)
        );
    }

    private readonly fetchSidebarDirectories = (refresh: boolean = false): void => {
        this.setState({ isLoading: true }, async () => {
            try {
                if (refresh) {
                    const response = await Api.getAssetDirectoryById(this.props.currentDirectory!);
                    const currentItems = this.state.directories;
                    const item: DirectoryTreeItem = {
                        id: response.id,
                        label: response.name,
                        children: [],
                    };
                    this.findAndSetDirectory(currentItems, item, this.directoryToDirectoryTreeItem(response.children));
                    this.setState({
                        directories: currentItems,
                        isLoading: false,
                    });
                    return;
                }
                const response = await Api.getAssetDirectoriesByParentId();
                this.setState({
                    isLoading: false,
                    directories: this.directoriesToDirectoryTreeItems(response),
                });
            } catch (error) {
                Alert.error({ title: Intl.getMessageFromError(error) });
                this.setState({ isLoading: false });
            }
        });
    };

    private readonly directoriesToDirectoryTreeItems = (directories: AssetDirectory[]): DirectoryTreeItem[] => {
        return directories.map(
            (directory: AssetDirectory): DirectoryTreeItem => {
                return {
                    id: directory.id,
                    label: directory.name,
                    children: directory.children
                        ? directory.children.map(
                              (child): DirectoryTreeItem => {
                                  return {
                                      id: child.id,
                                      label: child.name,
                                      children: [
                                          {
                                              id: "",
                                              label: Intl.formatMessage({ id: "common.loading" }),
                                              children: [],
                                          },
                                      ],
                                  };
                              }
                          )
                        : [],
                };
            }
        );
    };

    private readonly directoryToDirectoryTreeItem = (
        directories: AssetDirectory_children[] | null
    ): DirectoryTreeItem[] => {
        if (directories === null) {
            return [];
        }
        return directories.map(
            (directory: AssetDirectory_children): DirectoryTreeItem => {
                return {
                    id: directory.id,
                    label: directory.name,
                    children: [
                        {
                            id: "",
                            label: Intl.formatMessage({ id: "common.loading" }),
                            children: [],
                        },
                    ],
                };
            }
        );
    };

    private readonly toggleSidebar = (): void => {
        this.props.dispatch(AppStateActions.toggleSidebar(!this.state.isOpen));
    };

    private readonly findAndSetDirectory = (
        items: DirectoryTreeItem[],
        findItem: DirectoryTreeItem,
        children: DirectoryTreeItem[]
    ): DirectoryTreeItem[] => {
        items.forEach((item: DirectoryTreeItem, key: number) => {
            if (item.id !== findItem.id) {
                items[key].children = this.findAndSetDirectory(item.children, findItem, children);
                return;
            }
            items[key].children = children;
        });
        return items;
    };

    private readonly onOpenMenu = (item: DirectoryTreeItem): void => {
        if (item.children.length === 0) {
            return;
        }
        if (item.children[0].id !== "") {
            return;
        }
        this.setState({ isLoading: true }, async () => {
            try {
                const response = await Api.getAssetDirectoryById(item.id);
                const currentItems = this.state.directories;
                this.findAndSetDirectory(currentItems, item, this.directoryToDirectoryTreeItem(response.children));
                this.setState({
                    directories: currentItems,
                    isLoading: false,
                });
            } catch (error) {
                Alert.error({ title: Intl.getMessageFromError(error) });
                this.setState({ isLoading: false });
            }
        });
    };

    render() {
        const { isOpen } = this.state;

        return (
            <SidebarWrapper $isOpen={isOpen}>
                <SidebarHeader>
                    <h2>{Intl.formatMessage({ id: "components.folders.title" })}</h2>
                    <button type="button" aria-label="Close" onClick={this.toggleSidebar}>
                        <SvgIconClose />
                    </button>
                </SidebarHeader>
                <SidebarFolders>
                    <DirectoryTree
                        items={this.state.directories}
                        onOpenMenu={this.onOpenMenu}
                        onClick={(item: DirectoryTreeItem) => {
                            this.props.history.push(Paths.Directory(item.id));
                        }}
                        activeOnInit={this.props.currentDirectory || ""}
                        isLoading={this.state.isLoading}
                    />
                </SidebarFolders>
            </SidebarWrapper>
        );
    }
}

const SidebarWrapper = styled.nav<{ $isOpen: boolean }>`
    box-sizing: border-box;
    position: fixed;
    top: 90px;
    left: 0;
    width: inherit;
    height: calc(100% - 90px);
    transform: translate3d(0, 0, 0);
    background-color: ${props => props.theme.sidebar.backgroundColor};
    box-shadow: ${props => props.theme.sidebar.boxShadow};
    // transition: transform 300ms ease-in;
    overflow-x: hidden;
    z-index: 100;

    /*
    ${props =>
        props.$isOpen
            ? `
            transform: none;
            `
            : `
            transform: translateX(-103%);
    `}
    */
`;

const SidebarHeader = styled(Flex.Container).attrs(() => ({
    $alignItems: "center",
}))`
    padding: 0 20px;
    height: 40px;
    border-bottom: 1px solid ${props => props.theme.sidebar.borderColor};

    h2 {
        margin: 0 20px 0 0;
        font-size: 12px;
    }

    button {
        position: relative;
        margin-left: auto;

        /* Note: to increase clickable area from 10*10px (size of close svg) to 40*40px. */
        &::after {
            bottom: ${(40 - 10) / -2}px;
            content: "";
            left: ${(40 - 10) / -2}px;
            position: absolute;
            right: ${(40 - 10) / -2}px;
            top: ${(40 - 10) / -2}px;
        }
    }
`;

const SidebarFolders = styled.div`
    height: calc(100% - 40px);
    overflow-y: auto;
    list-style-type: none;
    font-size: 12px;

    button {
        width: 100%;
        padding-top: 10px;
        padding-right: 20px;
        padding-bottom: 10px;
        padding-left: 20px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;

        &:hover {
            background-color: ${props => props.theme.sidebar.folder.hover.backgroundColor};
        }
    }
`;

const mapStateToProps: MapStateToProps<ReduxProps, {}, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return {
        sidebarOpened: state.appState.sidebarOpened,
        currentDirectory: state.appState.currentDirectory,
    };
};

const Sidebar = withRouter(connect(mapStateToProps)(SidebarComponent));

export { Sidebar };
