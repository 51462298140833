// https://css-tricks.com/snippets/css/a-guide-to-flexbox/
import styled from "styled-components";
import { bp } from "@theme/Theme";

// Item
type ItemShrinkType = "auto" | "shrink" | number;

type ItemProps = {
    $alignSelf?: "auto" | "flex-start" | "flex-end" | "center" | "baseline" | "stretch";
    $order?: number;
    // Note: $shrink defines Item's width. Number: 0-1 is interpreted in %s. If $shrink > 1, it is interpreted in pixels.
    $shrink?: ItemShrinkType;
    $gutterMargin?: number;
    $mb?: number;
    $mt?: number;
    $minWidth?: number;
    $display?: string;
} & { $bpMedium?: ItemProps; $bpLarge?: ItemProps; $bpXLarge?: ItemProps; $bpXXLarge?: ItemProps };

const itemWidth = (shrink: ItemShrinkType, $gutterMargin: number) => {
    if (typeof shrink === "number" && shrink > 1) {
        return `width: ${shrink}px;`;
    }
    return `width: ${
        shrink === "shrink"
            ? "auto"
            : shrink !== "auto" && shrink >= 0 && shrink <= 1
            ? $gutterMargin
                ? `calc(${parseFloat((shrink * 100).toFixed(4))}% - ${$gutterMargin}px)`
                : `${parseFloat((shrink * 100).toFixed(4))}%`
            : $gutterMargin
            ? `calc(100% - ${$gutterMargin}px)`
            : "100%"
    };`;
};

const itemBpStyles = (props: ItemProps) => {
    return `
    align-self: ${props.$alignSelf || ""};
    flex: ${props.$shrink === "auto" ? "1 1 0px" : ""};
    flex: ${props.$shrink === "shrink" ? "0 0 auto" : ""};
    ${
        props.$gutterMargin
            ? `
    margin-left: ${props.$gutterMargin / 2}px;
    margin-right: ${props.$gutterMargin / 2}px;
    `
            : ""
    };
    margin-bottom: ${props.$mb ? `${props.$mb}px` : ""};
    order: ${props.$order ?? ""};
    ${itemWidth(props.$shrink ?? 0, props.$gutterMargin ?? 0)}

    ${
        props.$display
            ? `
            display: ${props.$display};
            `
            : ""
    };
  `;
};

const StyledItem = styled.div<ItemProps>`
    align-self: ${props => props.$alignSelf || ""};
    flex: ${props => (props.$shrink === "auto" ? "1 1 0px" : "0 0 auto")};
    ${props =>
        props.$gutterMargin
            ? `
    margin-left: ${props.$gutterMargin / 2}px;
    margin-right: ${props.$gutterMargin / 2}px;
    `
            : ""};
    margin-bottom: ${props => (props.$mb ? `${props.$mb}px` : "")};
    margin-top: ${props => (props.$mt ? `${props.$mt}px` : "")};
    min-height: 0;
    min-width: ${props => props.$minWidth ?? 0}px;
    order: ${props => props.$order || ""};
    ${props => itemWidth(props.$shrink ?? 0, props.$gutterMargin ?? 0)}

    ${props =>
        props.$display
            ? `
    display: ${props.$display};
    `
            : ""};

    /* NTH: refactor breakpoint logic */
    ${props =>
        props.$bpMedium
            ? `
    ${bp.medium} {
      ${itemBpStyles(props.$bpMedium)}
    }
    `
            : ""}

    ${props =>
        props.$bpLarge
            ? `
    ${bp.large} {
      ${itemBpStyles(props.$bpLarge)}
    }
    `
            : ""}

    ${props =>
        props.$bpXLarge
            ? `
    ${bp.xlarge} {
      ${itemBpStyles(props.$bpXLarge)}
    }
    `
            : ""}

    ${props =>
        props.$bpXXLarge
            ? `
    ${bp.xxlarge} {
      ${itemBpStyles(props.$bpXXLarge)}
    }
    `
            : ""}
`;

// Container
type ContainerProps = {
    $alignContent?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly";
    $alignItems?: "stretch" | "flex-start" | "flex-end" | "center" | "baseline";
    $display?: "flex" | "inline-flex";
    $flexDirection?: "row" | "row-reverse" | "column" | "column-reverse";
    $flexWrap?: "nowrap" | "wrap" | "wrap-reverse";
    $justifyContent?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly";
    $gutterMargin?: number;
    $mt?: number;
    $mb?: number;
} & { $bpMedium?: ContainerProps; $bpLarge?: ContainerProps; $bpXLarge?: ContainerProps; $bpXXLarge?: ContainerProps };

const containerBpStyles = (props: ContainerProps) => {
    return `
    align-content: ${props.$alignContent || ""};
    align-items: ${props.$alignItems || ""};
    display: ${props.$display || ""};
    flex-direction: ${props.$flexDirection || ""};
    flex-wrap: ${props.$flexWrap || ""};
    justify-content: ${props.$justifyContent || ""};
    ${
        props.$gutterMargin
            ? `
    margin-left: ${(props.$gutterMargin / 2) * -1}px;
    margin-right: ${(props.$gutterMargin / 2) * -1}px;
    `
            : ""
    };
    margin-bottom: ${props.$mb ? `${props.$mb}px` : ""};
    margin-top: ${props.$mt ? `${props.$mt}px` : ""};
  `;
};

const StyledContainer = styled.div<ContainerProps>`
    align-content: ${props => props.$alignContent || ""};
    align-items: ${props => props.$alignItems || ""};
    display: ${props => props.$display || "flex"};
    flex-direction: ${props => props.$flexDirection || ""};
    flex-wrap: ${props => props.$flexWrap || "nowrap"};
    justify-content: ${props => props.$justifyContent || ""};
    ${props =>
        props.$gutterMargin
            ? `
    margin-left: ${(props.$gutterMargin / 2) * -1}px;
    margin-right: ${(props.$gutterMargin / 2) * -1}px;
    `
            : ""};
    margin-bottom: ${props => (props.$mb ? `${props.$mb}px` : "")};
    margin-top: ${props => (props.$mt ? `${props.$mt}px` : "")};

    /* NTH: refactor breakpoint logic */
    ${props =>
        props.$bpMedium
            ? `
    ${bp.medium} {
      ${containerBpStyles(props.$bpMedium)}
    }
    `
            : ""}
    ${props =>
        props.$bpLarge
            ? `
    ${bp.large} {
      ${containerBpStyles(props.$bpLarge)}
    }
    `
            : ""}
    ${props =>
        props.$bpXLarge
            ? `
    ${bp.xlarge} {
      ${containerBpStyles(props.$bpXLarge)}
    }
    `
            : ""}
    ${props =>
        props.$bpXXLarge
            ? `
    ${bp.xxlarge} {
      ${containerBpStyles(props.$bpXXLarge)}
    }
    `
            : ""}
`;

export const Flex = {
    Container: StyledContainer,
    Item: StyledItem,
};
