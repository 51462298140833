import * as React from "react";

function SvgIcnDelete(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={14} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} {...props}>
            <path
                d="M3.5 11.083c0 .645.522 1.167 1.167 1.167h4.667c.644 0 1.166-.522 1.166-1.167v-7h-7v7zm7.584-8.75H9.042l-.583-.583H5.542l-.583.583H2.917V3.5h8.167V2.333z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgIcnDelete;
