import React from "react";
import { AppStateActions } from "@redux/actions/AppStateActions";
import { SearchListOptionsInput, AssetDirectory } from "@api/graphql/types";
import { AppBar } from "@components/AppBar";
import { Assets } from "@components/Assets/Assets";
import { Folders } from "@components/Folders";
import { Main } from "@components/Main";
import { OptionsBar } from "@components/OptionsBar";
import { Sidebar } from "@components/Sidebar";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ApplicationState } from "@redux/reducers";
import { connect, DispatchProp } from "react-redux";
import { AppConfigurationType } from "@redux/reducers/AppStateReducer";
import { StyledSplitPane } from "@components/SplitPane";
import { Constants } from "@utils/Constants";
import { batchActions } from "redux-batched-actions";

interface RouteProps {
    id?: string;
}

type ReduxProps = {
    config: AppConfigurationType;
    sidebarOpened: boolean;
    assetOptions: SearchListOptionsInput;
};

type Props = {} & ReduxProps & DispatchProp & RouteComponentProps<RouteProps>;

interface State {
    isSidebarLoading: boolean;
    sidebarDirectories: AssetDirectory[];
    sidebarWidth: number;
}

class DirectoryPageComponent extends React.Component<Props, State> {
    initialPath: string | null = null;

    public state: State = {
        isSidebarLoading: true,
        sidebarDirectories: [],
        sidebarWidth: 180,
    };

    constructor(props: Props) {
        super(props);
    }

    componentDidMount() {
        console.log({ path: window.location.pathname });
        window.addEventListener("scroll", this.onScroll);
    }

    public componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.props.dispatch(
                batchActions([
                    AppStateActions.clearSelectedAssets(),
                    AppStateActions.setCurrentDirectory(this.props.match.params.id || null),
                    AppStateActions.setAssetOffset(0),
                    AppStateActions.clearAssetOptions(),
                ])
            );
        }
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.onScroll);
    }

    private readonly onScroll = () => {
        const bottom =
            document.documentElement.scrollHeight - document.documentElement.scrollTop ===
            document.documentElement.clientHeight;
        if (bottom) {
            this.props.dispatch(
                AppStateActions.setAssetOffset(
                    this.props.assetOptions.control?.offset
                        ? this.props.assetOptions.control.offset + Constants.assetPageSize
                        : Constants.assetPageSize
                )
            );
        }
    };

    render() {
        return (
            <React.Fragment key="root">
                <AppBar key="appBar" initialPath={this.initialPath || ""} />

                <StyledSplitPane
                    split="vertical"
                    minSize={180}
                    maxSize={360}
                    primary="first"
                    onDragFinished={size => {
                        this.setState({
                            sidebarWidth: size,
                        });
                    }}
                    size={this.props.sidebarOpened ? this.state.sidebarWidth : 0}
                >
                    <Sidebar />

                    <Main>
                        <OptionsBar />

                        <Folders />

                        <Assets />
                    </Main>
                </StyledSplitPane>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return {
        sidebarOpened: state.appState.sidebarOpened,
        config: state.appState.appConfiguration,
        assetOptions: state.appState.assetOptions,
    };
};

export const DirectoryPage = withRouter(connect(mapStateToProps)(DirectoryPageComponent));
