class ApiError extends Error {
    public readonly intlKey: string;
    public readonly code: ApiErrorCode;

    constructor(message: ApiErrorCode) {
        super(message);
        this.intlKey = `error.api.${message}`;
        this.code = message;
    }
}

/**
 * Error messages from Api excluded 'E_' started codes
 */
enum ApiErrorCode {
    UNKNOWN = "UNKNOWN",
    UNAUTHENTICATED = "UNAUTHENTICATED",
    NETWORK_ERROR = "NETWORK_ERROR",
    INVALID_RESPONSE = "INVALID_RESPONSE",
    REQUEST_TIMEOUT = "REQUEST_TIMEOUT",

    DIRECTORY_NOT_FOUND = "DIRECTORY_NOT_FOUND",

    INVALID_EMBED_CONFIG = "INVALID_EMBED_CONFIG",
}

export { ApiError, ApiErrorCode };
