import * as React from "react";

function SvgIcnClose(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={10} height={10} fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} {...props}>
            <path d="M1 1l8 8M9 1L1 9" stroke="currentColor" strokeWidth={1.5} />
        </svg>
    );
}

export default SvgIcnClose;
