import * as React from "react";

function SvgIcnView(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={17} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 1.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0zm14 0A1.5 1.5 0 0015.5 0h-9a1.5 1.5 0 100 3h9A1.5 1.5 0 0017 1.5zM0 6a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm17 0a1.5 1.5 0 00-1.5-1.5h-9a1.5 1.5 0 100 3h9A1.5 1.5 0 0017 6zM0 10.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm17 0A1.5 1.5 0 0015.5 9h-9a1.5 1.5 0 100 3h9a1.5 1.5 0 001.5-1.5z"
                fill="#445591"
            />
        </svg>
    );
}

export default SvgIcnView;
