import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "@redux/store";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "@theme/GlobalStyle";
import { theme } from "@theme/Theme";
import { App } from "./App";

class AppContainer extends React.Component {
    public render(): JSX.Element {
        return (
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <>
                        <GlobalStyle />

                        <BrowserRouter>
                            <App />
                        </BrowserRouter>
                    </>
                </ThemeProvider>
            </Provider>
        );
    }
}

export { AppContainer };
