import React from "react";
import styled, { CSSObject } from "styled-components";
import { Flex } from "@components/Flex";
import { FoldingView } from "@components/Foldable/FoldingView";

type FoldableState = {
    isOpened: boolean;
};

type FoldableProps = {
    id: string;
    $shrinkToggleElement?: boolean;
    toggleElement: JSX.Element;
    toggleIcon?: {
        element: JSX.Element;
        elementPositionH?: "left" | "right";
        $style?: CSSObject;
        $activeStyle?: CSSObject;
    };
    as?: "li";
    style?: CSSObject;
    isOpenedDefault?: boolean;
};

export class Foldable extends React.Component<FoldableProps, FoldableState> {
    state: FoldableState = {
        isOpened: this.props.isOpenedDefault || false,
    };

    private toggleIsOpened = () => {
        this.setState({
            isOpened: !this.state.isOpened,
        });
    };

    private renderIconElement = () => {
        return (
            this.props.toggleIcon && (
                <FoldableToggleIconWrapper
                    $style={this.props.toggleIcon.$style}
                    $activeStyle={this.state.isOpened ? this.props.toggleIcon.$activeStyle : {}}
                >
                    {this.props.toggleIcon.element}
                </FoldableToggleIconWrapper>
            )
        );
    };

    private renderToggleElement = () => {
        return this.props.toggleIcon ? (
            <FoldableToggleButton
                id={`btn-${this.props.id}`}
                aria-controls={`region-${this.props.id}`}
                aria-expanded={this.state.isOpened}
                $isExpanded={true}
                onClick={this.toggleIsOpened}
            >
                {this.props.toggleIcon.elementPositionH ? (
                    <Flex.Container as="span" $alignItems="center">
                        {this.props.toggleIcon.elementPositionH === "left" && this.renderIconElement()}

                        <Flex.Item as="span" $shrink="auto">
                            {this.props.toggleElement}
                        </Flex.Item>

                        {this.props.toggleIcon.elementPositionH === "right" && this.renderIconElement()}
                    </Flex.Container>
                ) : (
                    <Flex.Container as="span" $alignItems="center" $justifyContent="center">
                        <Flex.Item className="show-for-sr" as="span" $shrink="auto">
                            {this.props.toggleElement}
                        </Flex.Item>

                        {this.renderIconElement()}
                    </Flex.Container>
                )}
            </FoldableToggleButton>
        ) : (
            <FoldableToggleButton
                id={`btn-${this.props.id}`}
                aria-controls={`region-${this.props.id}`}
                aria-expanded={this.state.isOpened}
                $isExpanded={true}
                onClick={this.toggleIsOpened}
            >
                <Flex.Container as="span">{this.props.toggleElement}</Flex.Container>
            </FoldableToggleButton>
        );
    };

    private renderShrinkedToggleElement = () => {
        return this.props.toggleIcon ? (
            <FoldableToggleButton
                id={`btn-${this.props.id}`}
                aria-controls={`region-${this.props.id}`}
                aria-expanded={this.state.isOpened}
                $isExpanded={false}
                onClick={this.toggleIsOpened}
            >
                {this.props.toggleIcon.elementPositionH ? (
                    <Flex.Container as="span" $display="inline-flex" $alignItems="center">
                        {this.props.toggleIcon.elementPositionH === "left" && this.renderIconElement()}

                        <Flex.Item as="span" $shrink="auto">
                            {this.props.toggleElement}
                        </Flex.Item>

                        {this.props.toggleIcon.elementPositionH === "right" && this.renderIconElement()}
                    </Flex.Container>
                ) : (
                    <Flex.Container as="span" $display="inline-flex" $alignItems="center">
                        <Flex.Item className="show-for-sr" as="span" $shrink="auto">
                            {this.props.toggleElement}
                        </Flex.Item>

                        {this.renderIconElement()}
                    </Flex.Container>
                )}
            </FoldableToggleButton>
        ) : (
            <FoldableToggleButton
                id={`btn-${this.props.id}`}
                aria-controls={`region-${this.props.id}`}
                aria-expanded={this.state.isOpened}
                $isExpanded={false}
                onClick={this.toggleIsOpened}
            >
                <Flex.Container as="span" $display="inline-flex">
                    {this.props.toggleElement}
                </Flex.Container>
            </FoldableToggleButton>
        );
    };

    render() {
        const { children, id, toggleElement, toggleIcon, $shrinkToggleElement, ...otherProps } = this.props;
        const { isOpened } = this.state;

        return (
            <FoldableWrapper {...otherProps}>
                {this.props.$shrinkToggleElement ? this.renderShrinkedToggleElement() : this.renderToggleElement()}

                <FoldingView
                    id={`region-${id}`}
                    role="region"
                    aria-hidden={!isOpened}
                    aria-labelledby={`btn-${this.props.id}`}
                    isOpened={isOpened}
                >
                    {children}
                </FoldingView>
            </FoldableWrapper>
        );
    }
}

const FoldableToggleIconWrapper = styled(Flex.Item).attrs(() => ({ as: "span", $shrink: "shrink" }))<{
    $style?: CSSObject;
    $activeStyle?: CSSObject;
}>`
    ${props => props.$style ?? {}}
    ${props => props.$activeStyle ?? {}}
`;

const FoldableToggleButton = styled.button.attrs(() => ({ type: "button" }))<{ $isExpanded: boolean }>`
    /* Note: button's text-align by default is center. */
    text-align: left;

    ${props =>
        props.$isExpanded
            ? `
      display: block;
      width: 100%;
    `
            : "display: inline-block;"};
`;

const FoldableWrapper = styled.div``;

export const StyledFoldable = {
    IconWrapper: FoldableToggleIconWrapper,
    ToggleButton: FoldableToggleButton,
    Wrapper: FoldableWrapper,
};

export const FoldableSymbol = styled.span`
    display: flex;
    transform: rotate(0deg);
`;
