import * as React from "react";

function SvgIcnSettings(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={19} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.906 10.158c0 .309-.027.618-.067.913l1.988 1.559a.475.475 0 01.108.604l-1.881 3.252c-.121.201-.363.282-.578.201l-2.338-.94a7.208 7.208 0 01-1.585.927l-.35 2.485a.488.488 0 01-.47.403H7.971a.489.489 0 01-.47-.39l-.35-2.485a6.907 6.907 0 01-1.585-.927l-2.337.94a.475.475 0 01-.578-.2L.77 13.247a.475.475 0 01.108-.605l1.988-1.558a7.17 7.17 0 01-.067-.927 6.8 6.8 0 01.067-.914L.878 7.685a.475.475 0 01-.108-.604l1.881-3.252a.475.475 0 01.578-.201l2.337.94a7.206 7.206 0 011.586-.927l.35-2.485a.488.488 0 01.47-.403h3.761c.229 0 .43.174.47.39l.35 2.485c.578.242 1.102.55 1.585.927l2.338-.94a.476.476 0 01.578.201l1.88 3.251a.475.475 0 01-.107.605L16.84 9.231c.04.309.067.618.067.927zM9.852 7.47a2.695 2.695 0 00-2.687 2.688 2.695 2.695 0 002.687 2.687 2.695 2.695 0 002.688-2.687A2.695 2.695 0 009.851 7.47z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgIcnSettings;
