import React from "react";
import styled from "styled-components";

import { bp, Color } from "@theme/Theme";

type StateType = {
    orderByValue: boolean;
};

export class ColorList extends React.Component<{}, StateType> {
    state: StateType = {
        orderByValue: false,
    };

    hexToVBColor(rrggbb: string): number {
        const bbggrr = `${rrggbb.substr(5, 3)}${rrggbb.substr(3, 3)}${rrggbb.substr(1, 3)}`;

        return parseInt(bbggrr, 16);
    }

    renderColorListItem(item: keyof typeof Color, i: number): JSX.Element {
        return (
            <li key={`color-${i}`} style={{ backgroundColor: Color[item] }}>
                {`${item}: ${Color[item]}`}
            </li>
        );
    }

    private toggleOrderBy = (): void => {
        const { orderByValue } = this.state;

        this.setState({
            orderByValue: !orderByValue,
        });
    };

    render(): JSX.Element {
        const { ...otherProps } = this.props;
        const { orderByValue } = this.state;

        return (
            <StyledColorList onDoubleClick={this.toggleOrderBy} {...otherProps}>
                {orderByValue
                    ? Object.keys(Color)
                          .sort((c1: string, c2: string): number => {
                              const c1v = Color[c1 as keyof typeof Color];
                              const c2v = Color[c2 as keyof typeof Color];

                              return this.hexToVBColor(c2v) - this.hexToVBColor(c1v);
                          })
                          .map((item: string, i: number) => this.renderColorListItem(item as keyof typeof Color, i))
                    : (Object.keys(Color) as Array<keyof typeof Color>).map((item, i) =>
                          this.renderColorListItem(item, i)
                      )}
            </StyledColorList>
        );
    }
}

export const StyledColorList = styled.ul`
    border-left: 1px solid ${Color.black};
    border-top: 1px solid ${Color.black};
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
    padding-left: 0;

    li {
        border-bottom: 1px solid ${Color.black};
        border-right: 1px solid ${Color.black};
        font-family: monospace, "Courier New";
        padding: 1em;
        text-shadow: 1px 1px ${Color.white};
        width: 50%;

        ${bp.medium} {
            width: 25%;
        }
    }
`;
