import { Intl, Language } from "@i18n/Intl";
import { ReduxAction } from "@redux/actions";
import { AppStateActionType } from "@redux/actions/AppStateActions";
import { Asset, SearchListOptionsInput, SearchListSortField, SortOrder } from "@api/graphql/types";
import { Constants } from "@utils/Constants";
import { DirectoryUtils } from "@utils/DirectoryUtils";

export const defaultAppConfiguration: Partial<AppConfigurationType> = {
    maximumSelectableAsset: 0,
    selectButtonTitle: Intl.formatMessage({ id: "components.actionBar.insert" }),
};

export type AppConfigurationType = {
    embedAuthToken?: string;
    path?: string;
    maximumSelectableAsset?: number;
    selectButtonTitle?: string;
    navbarColor?: string;
    insertButtonEnabled?: boolean;
    selectedFile?: string;
};

export interface AppStateReducerState {
    selectedLanguage: Language;
    sidebarOpened: boolean;
    assetListDisplay: boolean;
    appConfiguration: AppConfigurationType;
    currentDirectory: string | null;
    selectedAssets: Asset[];
    assetOptions: SearchListOptionsInput;
}

export class AppStateReducer {
    private static readonly initialState: AppStateReducerState = {
        selectedLanguage: Language.en,
        sidebarOpened: true,
        assetListDisplay: false,
        appConfiguration: defaultAppConfiguration,
        currentDirectory: DirectoryUtils.getCurrentDirectory(),
        selectedAssets: [],
        assetOptions: {
            sortField: SearchListSortField.createdAt,
            control: {
                sortOrder: SortOrder.DESC,
                search: null,
                limit: Constants.assetPageSize,
                offset: 0,
            },
        },
    };

    public static instance(
        state: AppStateReducerState = AppStateReducer.initialState,
        action: ReduxAction
    ): AppStateReducerState {
        switch (action.type) {
            case AppStateActionType.updateLanguage:
                return { ...state, selectedLanguage: action.language };
            case AppStateActionType.toggleSidebar:
                return { ...state, sidebarOpened: action.sidebarOpened };
            case AppStateActionType.toggleAssetListDisplay:
                return { ...state, assetListDisplay: action.assetListDisplay };
            case AppStateActionType.setAppConfiguration:
                return {
                    ...state,
                    appConfiguration: {
                        ...(action.isUpdate ? { ...state.appConfiguration, ...action.options } : { ...action.options }),
                    },
                };
            case AppStateActionType.setCurrentDirectory:
                return {
                    ...state,
                    currentDirectory: action.directory,
                };
            case AppStateActionType.toggleSelectedAsset: {
                const { selectedAssets } = state;
                const maxSelectableAsset = state.appConfiguration.maximumSelectableAsset || 0;
                if (maxSelectableAsset > 1 && selectedAssets.length === maxSelectableAsset) {
                    return {
                        ...state,
                    };
                }
                if (selectedAssets.some((selectedAsset: Asset) => selectedAsset.id === action.asset.id)) {
                    return {
                        ...state,
                        selectedAssets: selectedAssets.filter(
                            (selectedAsset: Asset) => selectedAsset.id !== action.asset.id
                        ),
                    };
                }
                return {
                    ...state,
                    selectedAssets: maxSelectableAsset === 1 ? [action.asset] : [...selectedAssets, action.asset],
                };
            }
            case AppStateActionType.setSelectedAssets: {
                return {
                    ...state,
                    selectedAssets: action.assets,
                };
            }
            case AppStateActionType.clearSelectedAssets: {
                return {
                    ...state,
                    selectedAssets: [],
                };
            }
            case AppStateActionType.setAssetSort: {
                return {
                    ...state,
                    assetOptions: {
                        sortField: action.field,
                        control: {
                            ...state.assetOptions.control,
                            sortOrder: action.direction,
                        },
                    },
                };
            }
            case AppStateActionType.setAssetSearch: {
                return {
                    ...state,
                    assetOptions: {
                        ...state.assetOptions,
                        control: {
                            ...state.assetOptions.control,
                            search: action.search,
                        },
                    },
                };
            }
            case AppStateActionType.setAssetOffset: {
                return {
                    ...state,
                    assetOptions: {
                        ...state.assetOptions,
                        control: {
                            ...state.assetOptions.control,
                            offset: action.offset,
                        },
                    },
                };
            }
            case AppStateActionType.clearAssetOptions: {
                return {
                    ...state,
                    assetOptions: AppStateReducer.initialState.assetOptions,
                };
            }
            default:
                return state;
        }
    }
}
