import React from "react";
import styled from "styled-components";
import { Flex } from "@components/Flex";

type BreadcrumbProps = {
    ariaLabel: string;
};

export class Breadcrumb extends React.Component<BreadcrumbProps> {
    render() {
        const { ariaLabel, children } = this.props;

        return (
            <BreadCrumbNav aria-label={ariaLabel}>
                <BreadCrumbList>{children}</BreadCrumbList>
            </BreadCrumbNav>
        );
    }
}

const BreadCrumbList = styled(Flex.Container).attrs(() => ({ as: "ol" }))`
    margin: 0;
    padding: 0;
    list-style-type: none;

    &::after {
        content: "";
        width: 1px;
        height: 16px;
        margin: auto;
        background-color: currentColor;
        opacity: 0.5;
    }

    li {
        position: relative;
        display: flex;
        margin-right: 10px;

        button {
            margin-left: 10px;
        }

        + li {
            &::before {
                content: "";
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                width: 7px;
                height: 10px;
                margin-right: 10px;
                opacity: 0.5;
                background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMCIgZmlsbD0ibm9uZSIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNyAxMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KIDxwYXRoIGQ9Im0xIDkgNC00LTQtNCIgc3Ryb2tlPSIjNDQ1NTkxIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8L3N2Zz4K")
                    no-repeat;
            }
        }
    }

    a,
    span.static {
        display: inline-flex;
        color: inherit;
        font-size: 12px;
        text-decoration: none;
        white-space: nowrap;

        svg {
            margin: auto;
            color: ${props => props.theme.appBar.headerSection.breadcrumb.icon.color};
        }

        &:hover {
            cursor: pointer;
        }
    }
`;

const BreadCrumbNav = styled.nav``;
