import * as React from "react";

function SvgIcnCheckMark(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={14} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} {...props}>
            <circle cx={7} cy={7} r={6.5} fill="#fff" stroke="#fff" />
            <path
                d="M3.5 7l2.333 2.5 4.667-5"
                stroke="#657ED6"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default SvgIcnCheckMark;
