import React from "react";
import styled from "styled-components";

import { Color } from "@theme/Theme";
import { keyframesSlideUpAndBackWithOpacity } from "@theme/GlobalStyle";
import SvgIcnTick from "@components/svg/IcnTick";
import SvgIcnClear from "@components/svg/IcnClear";
import { AlertType } from "./AlertTypes";
import { store } from "@redux/store";
import { AlertActions } from "@redux/actions/AlertActions";

interface Props {
    type: AlertType;
    message: string;
    visible: boolean;
    onClose: () => void;
}

class Alert extends React.PureComponent<Props> {
    public static success(options: { title: string; timeout?: number; callback?: () => Promise<void> | void }): void {
        store.dispatch(
            AlertActions.show({
                message: options.title,
                timeout: options.timeout || undefined,
                callback: options.callback,
                type: AlertType.success,
            })
        );
    }

    public static info(options: { title: string; timeout?: number; callback?: () => Promise<void> | void }): void {
        store.dispatch(
            AlertActions.show({
                message: options.title,
                timeout: options.timeout || 5000,
                callback: options.callback,
                type: AlertType.info,
            })
        );
    }

    public static error(options: { title: string; timeout?: number; callback?: () => Promise<void> | void }): void {
        store.dispatch(
            AlertActions.show({
                message: options.title,
                timeout: options.timeout || undefined,
                callback: options.callback,
                type: AlertType.error,
            })
        );
    }

    private readonly renderIcon = (): React.ReactElement => {
        switch (this.props.type) {
            case AlertType.success:
                return <SvgIcnTick width={14} height={11} viewBox="0 0 16 13" focusable="false" />;
            case AlertType.info:
                return <SvgIcnTick width={14} height={11} viewBox="0 0 16 13" focusable="false" />;
            case AlertType.error:
            default:
                return <SvgIcnClear width={10} height={10} viewBox="0 0 14 14" focusable="false" />;
        }
    };

    public render(): React.ReactElement {
        return (
            <AlertContainer {...this.props}>
                <div className="grid-x align-middle align-center">
                    <div className="cell shrink" ari-hidden="true">
                        <IconWrapperCell>{this.renderIcon()}</IconWrapperCell>
                    </div>

                    <div className="cell shrink" role="alert">
                        {this.props.message}
                    </div>
                </div>
            </AlertContainer>
        );
    }
}

const AlertContainer = styled.div<Props>`
    animation: ${keyframesSlideUpAndBackWithOpacity} 5000ms forwards;
    background-color: ${props => (props.type === AlertType.error ? Color.red : "")};
    background-color: ${props => (props.type === AlertType.success ? Color.primary : "")};
    bottom: 0;
    color: ${Color.white};
    font-weight: 700;
    left: 0;
    padding: 16px;
    position: fixed;
    right: 0;
    z-index: 1049;
    display: ${props => (props.visible ? "block" : "none")};
`;

const IconWrapperCell = styled.div`
    align-items: center;
    border-radius: 50%;
    border: 2px solid;
    display: flex;
    height: 24px;
    justify-content: center;
    margin-right: 10px;
    width: 24px;
`;

export { Alert, AlertType };
