import { Paths } from "@pages/paths";
import React, { Component } from "react";
import { AnyAssetDirectory } from "@api/ApiTypes";
import { SSkeletonLine } from "@components/Skeleton";
import { Intl } from "@i18n/Intl";
import { FolderButton, LoadingFolderButton } from "@components/FolderButton";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Api } from "@api/Api";
import { Alert } from "@components/Alert/Alert";
import { ApplicationState } from "@redux/reducers";
import { DirectoryModal } from "@components/Directory/DirectoryModal";
import { DirectoryDeleteModal } from "@components/Directory/DirectoryDeleteModal";
import { connect } from "react-redux";
import { SimplifiedDirectoryType } from "@components/BreadcrumbBar";
import { Section } from "@components/Section";
import styled from "styled-components";

type ReduxProps = {
    currentDirectory: string | null;
};

type Props = ReduxProps & RouteComponentProps;

interface State {
    isLoading: boolean;
    directories: AnyAssetDirectory[];
    renameDirectoryModal: {
        show: boolean;
        selectedDirectory?: AnyAssetDirectory | SimplifiedDirectoryType;
    };
    deleteDirectoryModal: {
        show: boolean;
        selectedDirectory?: AnyAssetDirectory | SimplifiedDirectoryType;
    };
}

export type ToggleRenameDirectoryModalEvent = {
    directory?: AnyAssetDirectory | SimplifiedDirectoryType;
};

export type ToggleDeleteDirectoryModalEvent = {
    directory?: AnyAssetDirectory | SimplifiedDirectoryType;
};

class FoldersComponent extends Component<Props, State> {
    public state: State = {
        isLoading: true,
        directories: [],
        renameDirectoryModal: {
            show: false,
        },
        deleteDirectoryModal: {
            show: false,
        },
    };

    componentDidMount(): void {
        this.fetchDirectories();
        window.addEventListener("refreshDirectories", () => this.fetchDirectories());
        window.addEventListener(
            "toggleRenameDirectoryModal",
            (event: CustomEventInit<ToggleRenameDirectoryModalEvent>) =>
                this.toggleRenameDirectoryModal(event.detail?.directory)
        );
        window.addEventListener(
            "toggleDeleteDirectoryModal",
            (event: CustomEventInit<ToggleDeleteDirectoryModalEvent>) =>
                this.toggleDeleteDirectoryModal(event.detail?.directory)
        );
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.currentDirectory !== this.props.currentDirectory) {
            this.fetchDirectories();
        }
    }

    componentWillUnmount(): void {
        window.removeEventListener("refreshDirectories", () => this.fetchDirectories());
        window.removeEventListener(
            "toggleRenameDirectoryModal",
            (event: CustomEventInit<ToggleRenameDirectoryModalEvent>) =>
                this.toggleRenameDirectoryModal(event.detail?.directory)
        );
        window.removeEventListener(
            "toggleDeleteDirectoryModal",
            (event: CustomEventInit<ToggleDeleteDirectoryModalEvent>) =>
                this.toggleDeleteDirectoryModal(event.detail?.directory)
        );
    }

    private readonly fetchDirectories = (): void => {
        this.setState({ isLoading: true }, async () => {
            try {
                const { currentDirectory } = this.props;
                if (currentDirectory) {
                    const response = await Api.getAssetDirectoryById(currentDirectory);
                    this.setState({
                        isLoading: false,
                        directories: response.children || [],
                    });
                    return;
                }
                const response = await Api.getAssetDirectoriesByParentId();

                this.setState({
                    isLoading: false,
                    directories: response,
                });
            } catch (error) {
                Alert.error({ title: Intl.getMessageFromError(error) });
                this.setState({ isLoading: false });
            }
        });
    };

    private readonly toggleRenameDirectoryModal = (directory?: AnyAssetDirectory | SimplifiedDirectoryType): void => {
        this.setState({
            renameDirectoryModal: {
                show: !this.state.renameDirectoryModal.show,
                selectedDirectory: directory,
            },
        });
    };

    private readonly toggleDeleteDirectoryModal = (directory?: AnyAssetDirectory | SimplifiedDirectoryType): void => {
        this.setState({
            deleteDirectoryModal: {
                show: !this.state.deleteDirectoryModal.show,
                selectedDirectory: directory,
            },
        });
    };

    private readonly renderLoadingFolders = (folderCount = 2) => {
        return new Array(folderCount).fill("1").map((value, key) => {
            return (
                <LoadingFolderButton key={key} onClick={() => void 0}>
                    <SSkeletonLine height={"40px"} width={"157px"} />
                </LoadingFolderButton>
            );
        });
    };

    private readonly renderFolders = (folders: AnyAssetDirectory[]): React.ReactElement[] => {
        return folders.map(
            (folder: AnyAssetDirectory): React.ReactElement => {
                return (
                    <FolderButton
                        key={folder.id}
                        btnLabel={folder.name}
                        isDeletable={folder.deletable}
                        onClick={() => this.props.history.push(Paths.Directory(folder.id))}
                        onRenameClick={() => this.toggleRenameDirectoryModal(folder)}
                        onDeleteClick={() => this.toggleDeleteDirectoryModal(folder)}
                    />
                );
            }
        );
    };

    public render(): React.ReactElement {
        const { directories, isLoading, renameDirectoryModal, deleteDirectoryModal } = this.state;

        return (
            <>
                {!isLoading && directories.length > 0 && (
                    <Section title={Intl.formatMessage({ id: "components.folders.title" })}>
                        <StyledFolders>{this.renderFolders(directories)}</StyledFolders>
                    </Section>
                )}
                {renameDirectoryModal.show && renameDirectoryModal.selectedDirectory && (
                    <DirectoryModal
                        mounted={renameDirectoryModal.show}
                        onModalClose={this.toggleRenameDirectoryModal}
                        refreshDirectories={() => {
                            this.fetchDirectories();
                            window.dispatchEvent(new CustomEvent("refreshSidebarDirectories"));
                        }}
                        directory={renameDirectoryModal.selectedDirectory}
                    />
                )}
                {deleteDirectoryModal.show && deleteDirectoryModal.selectedDirectory && (
                    <DirectoryDeleteModal
                        mounted={deleteDirectoryModal.show}
                        onModalClose={this.toggleDeleteDirectoryModal}
                        onDeleted={(directoryId: string) => {
                            this.fetchDirectories();
                            window.dispatchEvent(new CustomEvent("refreshSidebarDirectories"));
                            if (directoryId === this.props.currentDirectory) {
                                this.props.history.push(Paths.Home);
                            }
                        }}
                        directory={deleteDirectoryModal.selectedDirectory}
                    />
                )}
            </>
        );
    }
}

const StyledFolders = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(157px, 1fr));
    grid-gap: 20px;
`;

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return {
        currentDirectory: state.appState.currentDirectory,
    };
};

const Folders = withRouter(connect(mapStateToProps)(FoldersComponent));

export { Folders };
