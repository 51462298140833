import * as React from "react";

function SvgIcnThumbnail(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={11} height={11} fill="none" focusable={false} {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 0C0.447715 0 0 0.447715 0 1V10C0 10.5523 0.447715 11 1 11H10C10.5523 11 11 10.5523 11 10V1C11 0.447715 10.5523 0 10 0H1ZM1.69231 9.30769L4.23077 4.23077L5.5 6.76923L6.76923 5.07692L9.30769 9.30769H1.69231Z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgIcnThumbnail;
