import * as React from "react";

function SvgIcnClearAll(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={14} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} {...props}>
            <path
                fill="#fff"
                d="M.5.5h1v1h-1zM2.5.5h1v1h-1zM2.5 6.5h1v1h-1zM4.5.5h1v1h-1zM4.5 6.5h1v1h-1zM6.5.5h1v1h-1zM8.5.5h1v1h-1zM8.5 6.5h1v1h-1zM10.5.5h1v1h-1zM10.5 6.5h1v1h-1zM12.5.5h1v1h-1zM12.5 2.5h1v1h-1zM6.5 2.5h1v1h-1zM12.5 4.5h1v1h-1zM6.5 4.5h1v1h-1zM12.5 6.5h1v1h-1zM6.5 6.5h1v1h-1zM12.5 8.5h1v1h-1zM6.5 8.5h1v1h-1zM12.5 10.5h1v1h-1zM6.5 10.5h1v1h-1zM12.5 12.5h1v1h-1zM.5 2.5h1v1h-1zM.5 4.5h1v1h-1zM.5 6.5h1v1h-1zM.5 8.5h1v1h-1zM.5 10.5h1v1h-1zM.5 12.5h1v1h-1zM2.5 12.5h1v1h-1zM4.5 12.5h1v1h-1zM6.5 12.5h1v1h-1zM8.5 12.5h1v1h-1zM10.5 12.5h1v1h-1z"
            />
        </svg>
    );
}

export default SvgIcnClearAll;
