import * as React from "react";

function SvgIcnTick(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={16} height={13} fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} {...props}>
            <path
                d="M1 7l4.375 5L15 1"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default SvgIcnTick;
