import SvgIcnArrowDown from "@components/svg/IcnArrowDown";
import SvgIcnArrowRight from "@components/svg/IcnArrowRight";
import SvgIcnFolder from "@components/svg/IcnFolder";
import React from "react";
import styled from "styled-components";
import { Color, hexToRGBA } from "@theme/Theme";

export type DirectoryTreeItem = {
    id: string;
    label: string;
    children: DirectoryTreeItem[];
};

type Props = {
    items: DirectoryTreeItem[];
    isLoading?: boolean;
    onOpenMenu?: (item: DirectoryTreeItem) => void;
    onClick?: (item: DirectoryTreeItem) => void;
    activeOnInit?: string;
};

type TreeItem = {
    isOpened: boolean;
    isActive: boolean;
    id: string;
    label: string;
    children: TreeItem[];
};

interface State {
    items: TreeItem[];
    isLoading: boolean;
}

class DirectoryTree extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            items: DirectoryTree.getTreeItemsFromDirectoryTreeItems(props.items, undefined),
            isLoading: false,
        };
    }

    static getDerivedStateFromProps = (props: Props, state: State) => {
        return {
            items: DirectoryTree.getTreeItemsFromDirectoryTreeItems(props.items, state?.items),
            isLoading: props.isLoading || false,
        };
    };

    private readonly toggleMenuOpen = (item: TreeItem): void => {
        item.isOpened = !item.isOpened;
        this.props.onOpenMenu && this.props.onOpenMenu(item);
        this.forceUpdate();
    };

    private static readonly getTreeItemsFromDirectoryTreeItems = (
        items: DirectoryTreeItem[],
        prevItems?: TreeItem[]
    ): TreeItem[] => {
        return items.map((item: DirectoryTreeItem, key: number) => {
            return {
                isOpened: prevItems ? (prevItems[key]?.isActive ? false : prevItems[key]?.isOpened) : false,
                isActive: false,
                id: item.id,
                label: item.label,
                children: DirectoryTree.getTreeItemsFromDirectoryTreeItems(
                    item.children,
                    prevItems ? prevItems[key]?.children : undefined
                ),
            };
        });
    };

    private readonly renderTreeItems = (items: TreeItem[], level: number = 1): React.ReactElement[] => {
        return items.map(
            (item: TreeItem): React.ReactElement => {
                return (
                    <React.Fragment key={`lv_${level}_${item.id}`}>
                        <StyledDirectoryTreeItem
                            level={level - 1}
                            className={this.props.activeOnInit === item.id ? "active" : ""}
                            onClick={() => this.props.onClick && this.props.onClick(item)}
                            onDoubleClick={() => this.toggleMenuOpen(item)}
                        >
                            <DirectoryTreeItemFoldable hasChildren={true} onClick={() => this.toggleMenuOpen(item)}>
                                {item.children.length > 0 ? (
                                    item.isOpened ? (
                                        <SvgIcnArrowDown />
                                    ) : (
                                        <SvgIcnArrowRight />
                                    )
                                ) : (
                                    " "
                                )}
                            </DirectoryTreeItemFoldable>
                            <DirectoryTreeItemLabel>
                                <SvgIcnFolder />
                                <span>{item.label}</span>
                            </DirectoryTreeItemLabel>
                        </StyledDirectoryTreeItem>
                        {item.isOpened && item.children.length > 0 && this.renderTreeItems(item.children, level + 1)}
                    </React.Fragment>
                );
            }
        );
    };

    public render(): React.ReactElement {
        return <StyledDirectoryTree>{this.renderTreeItems(this.state.items)}</StyledDirectoryTree>;
    }
}

const StyledDirectoryTree = styled.ul`
    width: 100%;
`;
const StyledDirectoryTreeItem = styled.li<{ level: number }>`
    height: 32px;
    padding: 0px 22px;
    padding-left: ${props => props.level * 21 + 8}px;
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
    align-items: center;
    display: flex;

    &.active {
        background-color: ${Color.secondaryXD};
        color: ${Color.white};
        &:hover {
            background-color: ${Color.secondaryXD};
        }
    }

    &:hover {
        background-color: ${hexToRGBA(Color.secondaryXD, 0.1)};
        cursor: pointer;
    }
`;
const DirectoryTreeItemFoldable = styled.div<{ hasChildren?: boolean }>`
    width: 20px;
    height: 12px;
    padding: 0px 8px 0 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const DirectoryTreeItemLabel = styled.div`
    display: flex;

    svg {
        margin-right: 5px;
    }
`;

export { DirectoryTree };
