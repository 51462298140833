import { Asset } from "@api/graphql/types";
import { Intl } from "@i18n/Intl";
import React from "react";
import styled from "styled-components";
import { Button } from "@components/Button";
import { Flex } from "@components/Flex";
import SvgIconDelete from "@components/svg/IcnDelete";
import SvgIcnCheckMark from "@components/svg/IcnCheckMark";
import SvgMoveToFolder from "@components/svg/IcnMoveToFolder";
import SvgCloud from "@components/svg/IcnCloud";
import SvgSelectAll from "@components/svg/IcnSelectAll";
import SvgClearAll from "@components/svg/IcnClearAll";
import { OutgoingEvent, sendPMEvent } from "@utils/EmbeddedUtils";
import { DispatchProp } from "react-redux";
import { AppStateActions } from "@redux/actions/AppStateActions";
import { AssetDeleteModal } from "@components/Assets/AssetDeleteModal";
import { AssetMoveModal } from "@components/Assets/AssetMoveModal";

type Props = {
    selectedAssets: Asset[];
    isEmbeddedView: boolean;
    maxSelectableAsset: number;
    insertButtonEnabled: boolean;
} & DispatchProp;

interface State {
    showDeleteModal: boolean;
    showMoveModal: boolean;
}

export class ActionBar extends React.Component<Props, State> {
    public state: State = {
        showDeleteModal: false,
        showMoveModal: false,
    };

    private readonly toggleDeleteModal = (): void => {
        this.setState({
            showDeleteModal: !this.state.showDeleteModal,
        });
    };

    private readonly toggleMoveModal = (): void => {
        this.setState({
            showMoveModal: !this.state.showMoveModal,
        });
    };

    public render(): React.ReactElement {
        return (
            <StyledActionBar>
                <ActionBarSelected>
                    <SvgIcnCheckMark />
                    <span>
                        {Intl.formatMessage(
                            { id: "components.actionBar.selected" },
                            { number: this.props.selectedAssets.length }
                        )}
                    </span>
                </ActionBarSelected>
                <ActionBarButtonWrapper>
                    <ActionBarButton
                        btnLabel={Intl.formatMessage({ id: "components.actionBar.delete" })}
                        icon={<SvgIconDelete />}
                        iconPosition="left"
                        onClick={() => this.toggleDeleteModal()}
                    />
                    <ActionBarButton
                        btnLabel={Intl.formatMessage({ id: "components.actionBar.move" })}
                        icon={<SvgMoveToFolder />}
                        iconPosition="left"
                        onClick={() => this.toggleMoveModal()}
                    />
                    <ActionBarButton
                        btnLabel={Intl.formatMessage({ id: "components.actionBar.download" })}
                        icon={<SvgCloud />}
                        iconPosition="left"
                    />
                </ActionBarButtonWrapper>
                {this.props.maxSelectableAsset === 0 && (
                    <ActionBarButton
                        btnLabel={Intl.formatMessage({ id: "components.actionBar.selectAll" })}
                        icon={<SvgSelectAll />}
                        iconPosition="left"
                        onClick={() => {
                            window.dispatchEvent(new CustomEvent("selectAllAssets"));
                        }}
                    />
                )}
                <ActionBarButton
                    btnLabel={Intl.formatMessage({ id: "components.actionBar.clearAll" })}
                    icon={<SvgClearAll />}
                    iconPosition="left"
                    onClick={() => this.props.dispatch(AppStateActions.clearSelectedAssets())}
                />
                {this.props.isEmbeddedView && this.props.insertButtonEnabled && (
                    <ActionBarInsert
                        onClick={() => {
                            sendPMEvent(OutgoingEvent.ItemsSelected, {
                                assets: this.props.selectedAssets.map(asset => asset.fileName),
                            });
                        }}
                        btnLabel={Intl.formatMessage({ id: "components.actionBar.insert" })}
                    />
                )}
                <AssetMoveModal
                    mounted={this.state.showMoveModal}
                    onModalClose={this.toggleMoveModal}
                    selectedAssets={this.props.selectedAssets}
                />
                <AssetDeleteModal
                    mounted={this.state.showDeleteModal}
                    onModalClose={this.toggleDeleteModal}
                    refreshAssets={() => {
                        window.dispatchEvent(new CustomEvent("refreshAssets"));
                    }}
                    asset={this.props.selectedAssets}
                />
            </StyledActionBar>
        );
    }
}
const StyledActionBar = styled(Flex.Container).attrs(() => ({
    $alignItems: "center",
}))`
    min-width: max-content;
    height: 24px;
    padding-left: 10px;
    margin-left: auto;
    background-color: ${props => props.theme.ActionBar.backgroundColor};
    color: ${props => props.theme.ActionBar.color};
    border-radius: 12px;
    font-size: 12px;
    overflow: hidden;
`;

const ActionBarButtonWrapper = styled(Flex.Container).attrs(() => ({
    $alignItems: "center",
}))`
    position: relative;
    margin-left: 10px;

    &::after,
    &::before {
        content: "";
        width: 1px;
        height: 15px;
        margin: 0 10px 0 10px;
        background-color: ${props => props.theme.ActionBar.color};
        opacity: 0.5;
    }
`;

const ActionBarButton = styled(Button.Primary)`
    padding: 0 10px 0 0;

    span {
        padding: 0;
        font-size: 12px;
        text-transform: none;
    }
`;

const ActionBarInsert = styled(Button.Primary)`
    background-color: ${props => props.theme.ActionBar.buttonColor};
    border-radius: 0;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;

    span {
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 2px;
    }
`;

const ActionBarSelected = styled(Flex.Container).attrs(() => ({
    $alignItems: "center",
}))`
    min-width: max-content;
    font-weight: 700;

    /*
    &::after {
        content: "";
        width: 1px;
        height: 15px;
        margin: 0 10px 0 15px;
        background-color: ${props => props.theme.ActionBar.color};
        opacity: 0.5;
    }
    */

    svg {
        position: relative;
        margin-right: 10px;
    }
`;
