import React from "react";
import { Api } from "@api/Api";
import { AnyAssetDirectory } from "@api/ApiTypes";
import { Alert } from "@components/Alert/Alert";
import Modal, { ModalHeader, ModalInputWrapper, ButtonWrapper } from "@components/Modals/Modal";
import { Intl } from "@i18n/Intl";
import { Color } from "@theme/Theme";
import { H2 } from "@theme/GlobalStyle";
import { Button } from "@components/Button";
import { SimplifiedDirectoryType } from "@components/BreadcrumbBar";

type Props = {
    mounted: boolean;
    onModalClose: () => void;
    onDeleted: (directoryId: string) => void;
    directory: AnyAssetDirectory | SimplifiedDirectoryType;
};

interface State {
    isLoading: boolean;
}

class DirectoryDeleteModal extends React.Component<Props, State> {
    public state: State = {
        isLoading: false,
    };

    private readonly onDelete = async (): Promise<void> => {
        this.setState({ isLoading: true }, async () => {
            try {
                await Api.deleteAssetDirectory(this.props.directory.id);
                Alert.success({ title: Intl.formatMessage({ id: "components.directoryDeleteModal.deleteSuccess" }) });
                this.props.onDeleted(this.props.directory.id);
                this.props.onModalClose();
            } catch (error) {
                Alert.error({ title: Intl.getMessageFromError(error) });
                this.setState({ isLoading: false });
            }
        });
    };

    public render(): React.ReactElement {
        return (
            <Modal
                mounted={this.props.mounted}
                titleText={Intl.formatMessage({ id: "components.directoryDeleteModal.title" })}
                onModalClose={this.props.onModalClose}
                underlayColor={Color.modal}
            >
                <ModalHeader>
                    <H2>{Intl.formatMessage({ id: "components.directoryDeleteModal.title" })}</H2>
                </ModalHeader>
                <ModalInputWrapper>
                    <p>{Intl.formatMessage({ id: "components.directoryDeleteModal.description" })}</p>
                </ModalInputWrapper>
                <ButtonWrapper>
                    <div className="grid-x">
                        <div className="cell shrink">
                            <Button.Primary
                                btnLabel={Intl.formatMessage({ id: "common.cancel" })}
                                onClick={() => this.props.onModalClose()}
                            />
                        </div>
                        <div className="cell shrink">
                            <Button.Secondary
                                btnLabel={Intl.formatMessage({ id: "common.delete" })}
                                onClick={() => this.onDelete()}
                                disabled={this.state.isLoading}
                            />
                        </div>
                    </div>
                </ButtonWrapper>
            </Modal>
        );
    }
}

export { DirectoryDeleteModal };
