export enum FileSize {
    B = 1,
    KB = 1024 * FileSize.B,
    MB = 1024 * FileSize.KB,
    GB = 1024 * FileSize.MB,
}

export class FileSizeUtils {
    public static readonly formatSize = (fileSizeInBytes: number, newSize: FileSize): number => {
        return Math.round((fileSizeInBytes / newSize) * 100) / 100;
    };
}
