import Cookies from "universal-cookie";

class Cookie {
    private static readonly keys = {
        authToken: "authToken",
    };

    private static instance: Cookies = new Cookies();

    public static getAuthToken(): string | null {
        return Cookie.instance.get(Cookie.keys.authToken) || null;
    }

    public static setAuthTokken(authToken: string | null): void {
        Cookie.instance.set(Cookie.keys.authToken, authToken, { path: "/", sameSite: "lax" });
    }

    public static clearAuthToken(): void {
        Cookie.instance.remove(Cookie.keys.authToken, { path: "/" });
    }
}

export { Cookie };
