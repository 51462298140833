import * as React from "react";

function SvgIcnCloud(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={18} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} {...props}>
            <path
                d="M14.516 4.526A5.625 5.625 0 009 0a5.63 5.63 0 00-4.987 3.026A4.502 4.502 0 000 7.5C0 9.986 2.014 12 4.5 12h9.75C16.32 12 18 10.32 18 8.25c0-1.98-1.541-3.585-3.484-3.724zM10.5 6.75v3h-3v-3H5.25L9 3l3.75 3.75H10.5z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgIcnCloud;
