import * as React from "react";

function SvgIcnFolderAdd(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={13} height={10} fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} {...props}>
            <path
                d="M1 .408a1 1 0 00-1 1v7.184c0 .554.446 1 1 1h11c.554 0 1-.446 1-1V2.408c0-.554-.446-1-1-1H5.023l-.195-.42a1.003 1.003 0 00-.908-.58H1zM5.947 3h1v2h2v1h-2v2h-1V6h-2V5h2V3z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgIcnFolderAdd;
