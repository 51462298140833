import React from "react";
import styled from "styled-components";

import { bp } from "@theme/Theme";
import { Flex } from "./Flex";
import { Link } from "./Link";

type ButtonProps = {
    btnLabel: string;
    $expanded?: boolean;
    icon?: JSX.Element;
    iconPosition?: "left" | "right";
    to?: string;
    disabled?: boolean;
    noPadding?: boolean;
    type?: "button" | "submit";
    onClick?: () => void;
};

class ButtonComponent extends React.PureComponent<ButtonProps> {
    private renderButtonInner = (): JSX.Element => {
        const { btnLabel, icon, iconPosition } = this.props;

        if (icon) {
            if (!iconPosition) {
                return (
                    <Flex.Container as="span" $alignItems="center" $justifyContent="center">
                        <span className="show-for-sr">{btnLabel}</span>

                        <ButtonIconWrapper as="span" aria-hidden={true}>
                            {icon}
                        </ButtonIconWrapper>
                    </Flex.Container>
                );
            }

            if (iconPosition === "right") {
                return (
                    <Flex.Container as="span" $alignItems="center">
                        <ButtonLabelWrapper as="span" $shrink="auto">
                            {btnLabel}
                        </ButtonLabelWrapper>

                        <ButtonIconWrapperRight as="span" $shrink="shrink" aria-hidden={true}>
                            {icon}
                        </ButtonIconWrapperRight>
                    </Flex.Container>
                );
            }

            return (
                <Flex.Container as="span" $alignItems="center">
                    <ButtonIconWrapperLeft as="span" $shrink="shrink" aria-hidden={true}>
                        {icon}
                    </ButtonIconWrapperLeft>

                    <ButtonLabelWrapper as="span" $shrink="auto">
                        {btnLabel}
                    </ButtonLabelWrapper>
                </Flex.Container>
            );
        }

        return (
            <Flex.Container as="span" $alignItems="center">
                <ButtonLabelWrapper as="span" $shrink={1}>
                    {btnLabel}
                </ButtonLabelWrapper>
            </Flex.Container>
        );
    };

    private renderButton = (): JSX.Element => {
        const { $expanded, type, ...otherProps } = this.props;

        return (
            <StyledButton as="button" type={type || "button"} $expanded={!!$expanded} {...otherProps}>
                {this.renderButtonInner()}
            </StyledButton>
        );
    };

    private renderLink = (): JSX.Element => {
        const { $expanded, to, ...otherProps } = this.props;
        return (
            <StyledButton as={Link} to={to} $expanded={!!$expanded} {...otherProps}>
                {this.renderButtonInner()}
            </StyledButton>
        );
    };

    render(): React.ReactNode {
        return this.props.to ? this.renderLink() : this.renderButton();
    }
}

const ButtonLabelWrapper = styled(Flex.Item)`
    color: ${props => props.theme.box.button.primary.color};
    display: inline-block;
    font-size: ${props => props.theme.typo.button.fontSize}px;
    font-weight: ${props => props.theme.typo.button.fontWeight};
    line-height: ${props => props.theme.box.button.lineHeight}px;
    padding: ${props => `${props.theme.box.button.paddingVertical}px ${props.theme.box.button.paddingHorizontal}px`};
    position: relative;
    text-align: center;
    text-transform: ${props => props.theme.typo.button.textTransform};
    white-space: nowrap;
    z-index: 1;
`;

const ButtonIconWrapper = styled(Flex.Item)`
    align-items: center;
    align-self: stretch;
    display: flex;
    justify-content: center;
    min-width: 30px;
    position: relative;
`;

const ButtonIconWrapperLeft = styled(ButtonIconWrapper)``;

const ButtonIconWrapperRight = styled(ButtonIconWrapper)``;

const StyledButton = styled.div<{ $expanded: boolean; noPadding?: boolean }>`
    background-color: ${props => props.theme.box.button.primary.backgroundColor};
    border-radius: ${props => (props.theme.box.button.borderRadius ? `${props.theme.box.button.borderRadius}px` : "")};
    color: ${props => props.theme.box.button.primary.color};
    display: ${props => (props.$expanded ? "block" : "inline-block")};
    position: relative;
    user-select: none;
    vertical-align: bottom;
    width: ${props => (props.$expanded ? "100%" : "")};

    ${props =>
        !props.noPadding
            ? `
    padding: 6px 24px;`
            : ""}

    a& {
        color: ${props => props.theme.box.button.primary.color};
        text-decoration: none;
    }

    ${props =>
        props.theme.box.button.primary.border
            ? `
      &::before {
        border-radius: inherit;
        border: ${props.theme.box.button.primary.border};
        bottom: 0;
        content: "";
        left: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
      }
    `
            : ""}

    > ${Flex.Container} {
        min-height: ${props => props.theme.box.button.lineHeight + props.theme.box.button.paddingVertical * 2}px;
    }

    ${ButtonIconWrapperLeft},
    ${ButtonIconWrapperRight} {
        background-color: ${props => props.theme.box.button.primary.backgroundColor};
        color: ${props => props.theme.box.button.primary.color};
    }

    ${ButtonIconWrapperLeft} {
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
    }

    ${ButtonIconWrapperRight} {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
    }

    &:hover {
        /* TODO */
        ${ButtonLabelWrapper},
        ${ButtonIconWrapper} {
            opacity: 0.5;
        }
    }

    &.disabled,
    &:disabled {
        cursor: not-allowed;

        ${ButtonLabelWrapper} {
            color: ${props => props.theme.typo.button.disabled.color};
        }
    }

    ${bp.custom("max-width: 374px")} {
        padding-left: ${props => props.theme.box.button.paddingLeft / 2}px;
        padding-right: ${props => props.theme.box.button.paddingRight / 2}px;
    }
`;

const SecondaryButton = styled(ButtonComponent)`
    color: ${props => props.theme.box.button.secondary.color};
    background-color: ${props => props.theme.box.button.secondary.backgroundColor};
    border-radius: ${props => props.theme.box.button.secondary.borderRadius}px;

    a& {
        color: ${props => props.theme.box.button.secondary.color};
    }

    ${props =>
        props.theme.box.button.primary.border
            ? `
      &::before {
        border: ${props.theme.box.button.secondary.border};
      }
    `
            : ""}

    ${ButtonLabelWrapper} {
        color: ${props => props.theme.box.button.secondary.color};
    }

    ${ButtonIconWrapperLeft},
    ${ButtonIconWrapperRight} {
        background-color: ${props => props.theme.box.button.secondary.backgroundColor};
        color: ${props => props.theme.box.button.secondary.color};
    }

    ${ButtonIconWrapperLeft} {
        padding-left: ${props => `${props.theme.box.button.paddingHorizontal}px`};
    }

    ${ButtonIconWrapperRight} {
        padding-right: ${props => `${props.theme.box.button.paddingHorizontal}px`};
    }
`;

const ActionButton = styled(ButtonComponent)`
    color: ${props => props.theme.box.button.action.color};
    background-color: ${props => props.theme.box.button.action.backgroundColor};
    padding: unset;

    a& {
        color: ${props => props.theme.box.button.secondary.color};
    }

    ${props =>
        props.theme.box.button.primary.border
            ? `
      &::before {
        border: ${props.theme.box.button.action.border};
      }
    `
            : ""}

    ${ButtonLabelWrapper} {
        color: ${props => props.theme.box.button.action.color};
    }

    ${ButtonIconWrapperLeft},
    ${ButtonIconWrapperRight} {
        background-color: ${props => props.theme.box.button.action.backgroundColor};
        color: ${props => props.theme.box.button.action.color};
    }
`;

export const Button = {
    IconWrapper: ButtonIconWrapper,
    LabelWrapper: ButtonLabelWrapper,
    Primary: ButtonComponent,
    Secondary: SecondaryButton,
    Action: ActionButton,
    StyledButton,
};
