import * as React from "react";

function SvgIcnDownload(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={14} height={10} fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} {...props}>
            <path
                d="M11.29 3.52A4.375 4.375 0 007 0a4.379 4.379 0 00-3.88 2.354 3.499 3.499 0 00.38 6.98h7.583A2.918 2.918 0 0014 6.416a2.91 2.91 0 00-2.71-2.897zM9.917 5.25L7 8.167 4.083 5.25h1.75V2.917h2.334V5.25h1.75z"
                fill="#283259"
            />
        </svg>
    );
}

export default SvgIcnDownload;
