import styled, { css, createGlobalStyle, keyframes } from "styled-components";
import { bp, breakpoints, theme } from "./Theme";

// https://accessibility.18f.gov/hidden-content/
export const showForSr = css`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

export const GlobalStyle = createGlobalStyle`
    html {
        box-sizing: border-box;
    }

    *,
    *::before,
    *::after {
        box-sizing: inherit;
    }

    /* TODO: remember to define focus styles! http://www.outlinenone.com/ */
    :focus {
        outline: 0;
        /* outline: 4px solid red; */
    }

    body {
        background-color: ${theme.box.body.backgroundColor};
        color: ${theme.typo.body.color};
        font-family: Arial, Helvetica, sans-serif;
        font-size: ${theme.typo.body.fontSize}px;
        font-weight: ${theme.typo.body.fontWeight};
        line-height: ${theme.typo.body.lineHeight}px;
        margin: 0;
        // min-height: 100vh;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
    }

    button {
        background-color: transparent;
        border: 0;
        color: inherit;
        cursor: pointer;
        display: block;
        font-family: inherit;
        font-size: 100%;
        margin: 0;
        padding: 0;
    }

    article,
    aside,
    header,
    footer,
    main,
    nav {
        display: block;
    }

    ul {
        list-style-type: none;
        margin-bottom: 0;
        margin-top: 0;
        padding-left: 0;
    }

    svg {
        display: block;
    }

    /* Utility Classes */
    .show-for-sr {
        ${showForSr}
    }


    /* Visibility Classes */
    .show-for-small-only {
        ${bp.custom(`min-width: ${breakpoints.medium}px`)} {
            display: none !important;
        }
    }

    .show-for-medium {
        ${bp.custom(`max-width: ${breakpoints.medium - 1}px`)} {
            display: none !important;
        }
    }

    .show-for-medium-only {
        ${`@media (max-width: ${breakpoints.medium - 1}px), (min-width: ${breakpoints.large}px)`} {
            display: none !important;
        }
    }

    .hide-for-medium {
        ${bp.custom(`min-width: ${breakpoints.medium}px`)} {
            display: none !important;
        }
    }

    .show-for-large {
        ${bp.custom(`max-width: ${breakpoints.large - 1}px`)} {
            display: none !important;
        }
    }

    .hide-for-large {
        ${bp.custom(`min-width: ${breakpoints.large}px`)} {
            display: none !important;
        }
    }

    .show-for-xlarge {
        ${bp.custom(`max-width: ${breakpoints.xlarge - 1}px`)} {
            display: none !important;
        }
    }

    .hide-for-xlarge {
        ${bp.custom(`min-width: ${breakpoints.xlarge}px`)} {
            display: none !important;
        }
    }

    .hide-for-large-only {
        ${`@media (min-width: ${breakpoints.large}px) and (max-width: ${breakpoints.xlarge - 1}px)`} {
            display: none !important;
        }
    }
        .grid-x {
        display: flex;
        flex-flow: row wrap;

        &.align-center {
            justify-content: center;
        }

        &.align-right {
            justify-content: flex-end;
        }

        &.align-middle {
            align-items: center;
        }

        &.align-bottom {
            align-items: flex-end;
        }

        &.grid-margin-x-10 {
            flex-flow: nowrap;
            margin-left: -10px;
            margin-right: -10px;

            > .cell {
                margin-left: 10px;
                margin-right: 10px;
            }
        }

        &.grid-margin-x-12 {
            flex-flow: nowrap;
            margin-left: -12px;
            margin-right: -12px;

            > .cell {
                margin-left: 12px;
                margin-right: 12px;
            }
        }

        &.grid-margin-x-15 {
            flex-flow: nowrap;
            margin-left: -15px;
            margin-right: -15px;

            > .cell {
                margin-left: 15px;
                margin-right: 15px;
            }
        }

        &.grid-margin-x-20 {
            flex-flow: nowrap;
            margin-left: -20px;
            margin-right: -20px;

            > .cell {
                margin-left: 20px;
                margin-right: 20px;
            }
        }
    }

    .grid-y {
        display: flex;
        flex-flow: column nowrap;
    }

    .cell {
        flex: 0 0 auto;
        min-height: 0px;
        min-width: 0px;
        width: 100%;

        &.auto {
            flex: 1 1 0px;
            width: auto;
        }

        &.shrink {
            flex: 0 0 auto;
            width: auto;
        }

        &.small-4 {
            width: ${(4 * 100) / 12}%;
        }

        &.small-6 {
            width: ${(6 * 100) / 12}%;
        }

        &.small-8 {
            width: ${(8 * 100) / 12}%;
        }
    }
`;

export const Container = styled.div`
    padding-left: 22px;
    padding-right: 22px;

    ${bp.xlarge} {
        margin: 0 auto;
        max-width: ${breakpoints.xlarge + 2 * 22}px;
    }
`;

export const H1 = styled.h1`
    font-size: ${theme.typo.h1.fontSize}px;
    font-weight: ${theme.typo.h1.fontWeight};
    line-height: ${theme.typo.h1.lineHeight}px;
    text-transform: uppercase;
`;

export const H2 = styled.h2`
    font-size: ${theme.typo.h2.fontSize}px;
    font-weight: ${theme.typo.h2.fontWeight};
    line-height: ${theme.typo.h2.lineHeight}px;
    text-transform: uppercase;
`;

export const H3 = styled.h3`
    font-size: ${theme.typo.h3.fontSize}px;
    font-weight: ${theme.typo.h3.fontWeight};
    line-height: ${theme.typo.h3.lineHeight}px;
`;

export const H4 = styled.h4`
    font-size: ${theme.typo.h4.fontSize}px;
    font-weight: ${theme.typo.h4.fontWeight};
    line-height: ${theme.typo.h4.lineHeight}px;
`;

export const H5 = styled.h5`
    font-size: ${theme.typo.h5.fontSize}px;
    font-weight: ${theme.typo.h5.fontWeight};
    line-height: ${theme.typo.h5.lineHeight}px;
`;

export const H6 = styled.h6`
    font-size: ${theme.typo.h6.fontSize}px;
    font-weight: ${theme.typo.h6.fontWeight};
    line-height: ${theme.typo.h6.lineHeight}px;
`;

export const CaptionText = styled.div<{ thStyle?: boolean }>`
    ${props =>
        props.thStyle
            ? `
        color: ${theme.typo.table.th.color};
        font-size: ${theme.typo.table.th.fontSize}px;
        // padding-bottom: ${theme.box.table.th.paddingTop}px;
        // padding-left: ${theme.box.table.td.padding}px;
        // padding-right: ${theme.box.table.td.padding}px;
        // padding-top: ${theme.box.table.th.paddingTop}px;
        h2& {
            margin-bottom: 14px;
            margin-top: 30px;
            text-transform: uppercase;
        }
    `
            : `
        font-size: ${theme.typo.CaptionText.fontSize}px;
        line-height: ${theme.typo.CaptionText.lineHeight}px;
    `}
    span.error {
        color: ${theme.typo.input.hasError.color};
    }
`;

// Keyframes
export const keyframesFadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

export const keyframesRotate360 = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const keyframesSlideUpAndBackWithOpacity = keyframes`
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    10% {
        opacity: 1;
        transform: translateX(0);
    }
    90% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateY(100%);
    }
`;

export const keyframesBlinkInputShadow = keyframes`
    0% {
        box-shadow: 0 0 ${theme.box.input.focus.outlineOffset}px ${theme.box.input.focus.outlineWidth}px ${theme.box.input.focus.outlineColor};
    }
    49% {
        box-shadow: none;
    }
    50% {
        box-shadow: none;
    }
`;
