import { Alert } from "@components/Alert/Alert";
import { Input } from "@components/Inputs/Input";
import { InputWrapper } from "@components/Inputs/InputWrapper";
import { AppPath } from "@pages/paths";
import { AuthActions } from "@redux/actions/AuthActions";
import { ApplicationState } from "@redux/reducers";
import { AuthSelectors } from "@redux/selectors/AuthSelectors";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import React from "react";
import Modal, { ModalHeader, ModalInputWrapper, ButtonWrapper } from "@components/Modals/Modal";
import { Intl } from "@i18n/Intl";
import { H2 } from "@theme/GlobalStyle";
import { Color } from "@theme/Theme";
import { MapStateToProps, connect, DispatchProp } from "react-redux";
import { Redirect } from "react-router-dom";
import { Button } from "@components/Button";

interface ReduxProps {
    isLoggedIn: boolean;
}

type Props = ReduxProps & DispatchProp;

interface State {
    loginInput: {
        email: string;
        password: string;
    };
    isLoading: boolean;
}

class LoginPageComponent extends React.Component<Props, State> {
    public state: State = {
        loginInput: {
            email: "",
            password: "",
        },
        isLoading: false,
    };

    private readonly onEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            loginInput: {
                ...this.state.loginInput,
                email: e.currentTarget.value,
            },
        });
    };

    private readonly onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            loginInput: {
                ...this.state.loginInput,
                password: e.currentTarget.value,
            },
        });
    };

    private readonly onSubmit = (): void => {
        this.setState({ isLoading: true }, async () => {
            try {
                const link = createHttpLink({ uri: process.env.REACT_APP_ADMIN_GRAPHQL_API_URL });
                const client = new ApolloClient({
                    link: link,
                    cache: new InMemoryCache({
                        resultCaching: false,
                    }),
                    defaultOptions: {
                        watchQuery: {
                            fetchPolicy: "network-only",
                            errorPolicy: "ignore",
                        },
                        query: {
                            fetchPolicy: "no-cache",
                            errorPolicy: "all",
                        },
                    },
                });

                const mutation: DocumentNode = gql`
                    mutation adminLogin($credentials: AdminLoginDataInput!) {
                        adminLogin(credentials: $credentials) {
                            token
                        }
                    }
                `;
                const response = await client.mutate({
                    mutation,
                    variables: {
                        credentials: this.state.loginInput,
                    },
                });
                if (response.data.adminLogin.token) {
                    this.props.dispatch(AuthActions.updateAuthToken(response.data.adminLogin.token));
                    return;
                } else {
                    Alert.error({ title: Intl.formatMessage({ id: "components.loginPage.error" }) });
                    this.setState({
                        isLoading: false,
                    });
                }
            } catch (error) {
                Alert.error({ title: error.message });
                this.setState({
                    isLoading: false,
                });
            }
        });
    };

    public render(): React.ReactElement {
        const { isLoading, loginInput } = this.state;

        if (this.props.isLoggedIn) {
            return <Redirect to={AppPath.Home} />;
        }

        return (
            <Modal
                titleText={Intl.formatMessage({ id: "components.loginPage.title" })}
                mounted={!this.props.isLoggedIn}
                underlayColor={Color.modal}
            >
                <ModalHeader>
                    <H2>{Intl.formatMessage({ id: "components.loginPage.title" })}</H2>
                </ModalHeader>
                <ModalInputWrapper>
                    <InputWrapper fakeLabel={Intl.formatMessage({ id: "components.loginPage.form.email" })}>
                        <Input
                            aria-label={Intl.formatMessage({ id: "components.loginPage.form.email" })}
                            type={"email"}
                            value={loginInput.email}
                            onChange={this.onEmailChange}
                        />
                    </InputWrapper>
                    <InputWrapper
                        fakeLabel={Intl.formatMessage({ id: "components.loginPage.form.password" })}
                        style={{ marginTop: "30px" }}
                    >
                        <Input
                            aria-label={Intl.formatMessage({ id: "components.loginPage.form.password" })}
                            type={"password"}
                            value={loginInput.password}
                            onChange={this.onPasswordChange}
                        />
                    </InputWrapper>
                    <ButtonWrapper>
                        <Button.Primary
                            btnLabel={Intl.formatMessage({ id: "components.loginPage.login" })}
                            onClick={() => this.onSubmit()}
                            disabled={isLoading || !loginInput.email || !loginInput.password}
                        />
                    </ButtonWrapper>
                </ModalInputWrapper>
            </Modal>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, {}, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return {
        isLoggedIn: AuthSelectors.isLoggedIn(state),
    };
};

const LoginPage = connect(mapStateToProps)(LoginPageComponent);

export { LoginPage };
