/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: uploadAsset
// ====================================================

export interface uploadAsset_uploadAsset_info {
  __typename: "AssetInfo";
  originalFileName: string;
  mimeType: string;
  encoding: string;
  etag: string;
  width: number;
  height: number;
}

export interface uploadAsset_uploadAsset_directory {
  __typename: "AssetDirectory";
  id: string;
}

export interface uploadAsset_uploadAsset {
  __typename: "Asset";
  id: string;
  title: string;
  fileName: string;
  fileSize: number;
  url: any;
  urlWithOpts: any;
  info: uploadAsset_uploadAsset_info;
  directory: uploadAsset_uploadAsset_directory | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface uploadAsset {
  uploadAsset: uploadAsset_uploadAsset | null;
}

export interface uploadAssetVariables {
  title: string;
  file: any;
  directoryId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateAsset
// ====================================================

export interface updateAsset_updateAsset_info {
  __typename: "AssetInfo";
  originalFileName: string;
  mimeType: string;
  encoding: string;
  etag: string;
  width: number;
  height: number;
}

export interface updateAsset_updateAsset_directory {
  __typename: "AssetDirectory";
  id: string;
}

export interface updateAsset_updateAsset {
  __typename: "Asset";
  id: string;
  title: string;
  fileName: string;
  fileSize: number;
  url: any;
  urlWithOpts: any;
  info: updateAsset_updateAsset_info;
  directory: updateAsset_updateAsset_directory | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface updateAsset {
  updateAsset: updateAsset_updateAsset;
}

export interface updateAssetVariables {
  id: string;
  title: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteAsset
// ====================================================

export interface deleteAsset {
  deleteAsset: any | null;
}

export interface deleteAssetVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteAssets
// ====================================================

export interface deleteAssets {
  deleteAssets: any | null;
}

export interface deleteAssetsVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createAssetDirectory
// ====================================================

export interface createAssetDirectory_createAssetDirectory_parent_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
}

export interface createAssetDirectory_createAssetDirectory_parent {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  children: createAssetDirectory_createAssetDirectory_parent_children[] | null;
}

export interface createAssetDirectory_createAssetDirectory_children_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
}

export interface createAssetDirectory_createAssetDirectory_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  children: createAssetDirectory_createAssetDirectory_children_children[] | null;
}

export interface createAssetDirectory_createAssetDirectory {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  parent: createAssetDirectory_createAssetDirectory_parent | null;
  children: createAssetDirectory_createAssetDirectory_children[] | null;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
}

export interface createAssetDirectory {
  createAssetDirectory: createAssetDirectory_createAssetDirectory | null;
}

export interface createAssetDirectoryVariables {
  input: CreateAssetDirectoryInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateAssetDirectory
// ====================================================

export interface updateAssetDirectory_updateAssetDirectory_parent_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
}

export interface updateAssetDirectory_updateAssetDirectory_parent {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  children: updateAssetDirectory_updateAssetDirectory_parent_children[] | null;
}

export interface updateAssetDirectory_updateAssetDirectory_children_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
}

export interface updateAssetDirectory_updateAssetDirectory_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  children: updateAssetDirectory_updateAssetDirectory_children_children[] | null;
}

export interface updateAssetDirectory_updateAssetDirectory {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  parent: updateAssetDirectory_updateAssetDirectory_parent | null;
  children: updateAssetDirectory_updateAssetDirectory_children[] | null;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
}

export interface updateAssetDirectory {
  updateAssetDirectory: updateAssetDirectory_updateAssetDirectory | null;
}

export interface updateAssetDirectoryVariables {
  id?: string | null;
  input: UpdateAssetDirectoryInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteAssetDirectory
// ====================================================

export interface deleteAssetDirectory {
  deleteAssetDirectory: any | null;
}

export interface deleteAssetDirectoryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: moveAssetsToAssetDirectory
// ====================================================

export interface moveAssetsToAssetDirectory {
  moveAssetsToAssetDirectory: any | null;
}

export interface moveAssetsToAssetDirectoryVariables {
  assetIds: string[];
  directoryId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAssetDirectoriesByParentId
// ====================================================

export interface getAssetDirectoriesByParentId_getAssetDirectoriesByParentId_parent_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
}

export interface getAssetDirectoriesByParentId_getAssetDirectoriesByParentId_parent {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  children: getAssetDirectoriesByParentId_getAssetDirectoriesByParentId_parent_children[] | null;
}

export interface getAssetDirectoriesByParentId_getAssetDirectoriesByParentId_children_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
}

export interface getAssetDirectoriesByParentId_getAssetDirectoriesByParentId_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  children: getAssetDirectoriesByParentId_getAssetDirectoriesByParentId_children_children[] | null;
}

export interface getAssetDirectoriesByParentId_getAssetDirectoriesByParentId {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  parent: getAssetDirectoriesByParentId_getAssetDirectoriesByParentId_parent | null;
  children: getAssetDirectoriesByParentId_getAssetDirectoriesByParentId_children[] | null;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
}

export interface getAssetDirectoriesByParentId {
  getAssetDirectoriesByParentId: getAssetDirectoriesByParentId_getAssetDirectoriesByParentId[];
}

export interface getAssetDirectoriesByParentIdVariables {
  id?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAssetDirectoryById
// ====================================================

export interface getAssetDirectoryById_getAssetDirectoryById_parent_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
}

export interface getAssetDirectoryById_getAssetDirectoryById_parent {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  children: getAssetDirectoryById_getAssetDirectoryById_parent_children[] | null;
}

export interface getAssetDirectoryById_getAssetDirectoryById_children_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
}

export interface getAssetDirectoryById_getAssetDirectoryById_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  children: getAssetDirectoryById_getAssetDirectoryById_children_children[] | null;
}

export interface getAssetDirectoryById_getAssetDirectoryById {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  parent: getAssetDirectoryById_getAssetDirectoryById_parent | null;
  children: getAssetDirectoryById_getAssetDirectoryById_children[] | null;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
}

export interface getAssetDirectoryById {
  getAssetDirectoryById: getAssetDirectoryById_getAssetDirectoryById | null;
}

export interface getAssetDirectoryByIdVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: search
// ====================================================

export interface search_search_result_info {
  __typename: "AssetInfo";
  originalFileName: string;
  mimeType: string;
  encoding: string;
  etag: string;
  width: number;
  height: number;
}

export interface search_search_result_directory {
  __typename: "AssetDirectory";
  id: string;
}

export interface search_search_result {
  __typename: "Asset";
  id: string;
  title: string;
  fileName: string;
  fileSize: number;
  url: any;
  urlWithOpts: any;
  info: search_search_result_info;
  directory: search_search_result_directory | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface search_search {
  __typename: "SearchList";
  count: number;
  result: search_search_result[];
}

export interface search {
  search: search_search;
}

export interface searchVariables {
  options?: SearchInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAssetByFileName
// ====================================================

export interface getAssetByFileName_getAssetByFileName_info {
  __typename: "AssetInfo";
  originalFileName: string;
  mimeType: string;
  encoding: string;
  etag: string;
  width: number;
  height: number;
}

export interface getAssetByFileName_getAssetByFileName_directory {
  __typename: "AssetDirectory";
  id: string;
}

export interface getAssetByFileName_getAssetByFileName {
  __typename: "Asset";
  id: string;
  title: string;
  fileName: string;
  fileSize: number;
  url: any;
  urlWithOpts: any;
  info: getAssetByFileName_getAssetByFileName_info;
  directory: getAssetByFileName_getAssetByFileName_directory | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface getAssetByFileName {
  getAssetByFileName: getAssetByFileName_getAssetByFileName | null;
}

export interface getAssetByFileNameVariables {
  fileName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Asset
// ====================================================

export interface Asset_info {
  __typename: "AssetInfo";
  originalFileName: string;
  mimeType: string;
  encoding: string;
  etag: string;
  width: number;
  height: number;
}

export interface Asset_directory {
  __typename: "AssetDirectory";
  id: string;
}

export interface Asset {
  __typename: "Asset";
  id: string;
  title: string;
  fileName: string;
  fileSize: number;
  url: any;
  urlWithOpts: any;
  info: Asset_info;
  directory: Asset_directory | null;
  createdAt: any;
  updatedAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AssetDirectoryItem
// ====================================================

export interface AssetDirectoryItem_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
}

export interface AssetDirectoryItem {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  children: AssetDirectoryItem_children[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AssetDirectory
// ====================================================

export interface AssetDirectory_parent_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
}

export interface AssetDirectory_parent {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  children: AssetDirectory_parent_children[] | null;
}

export interface AssetDirectory_children_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
}

export interface AssetDirectory_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  children: AssetDirectory_children_children[] | null;
}

export interface AssetDirectory {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  parent: AssetDirectory_parent | null;
  children: AssetDirectory_children[] | null;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum SearchListSortField {
  createdAt = "createdAt",
  fileSize = "fileSize",
  title = "title",
  updatedAt = "updatedAt",
}

export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export interface CreateAssetDirectoryInput {
  name: string;
  parentId?: string | null;
}

export interface ListControl {
  sortOrder?: SortOrder | null;
  search?: string | null;
  limit?: number | null;
  offset?: number | null;
}

export interface SearchInput {
  assetDirectory?: string | null;
  recursive?: boolean | null;
  listOptions?: SearchListOptionsInput | null;
}

export interface SearchListOptionsInput {
  sortField?: SearchListSortField | null;
  control?: ListControl | null;
}

export interface UpdateAssetDirectoryInput {
  name?: string | null;
  parentId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
