import { AppPath } from "@pages/paths";
import React from "react";
import { ApplicationState } from "@redux/reducers";
import { AuthSelectors } from "@redux/selectors/AuthSelectors";
import { DispatchProp, MapStateToProps, connect } from "react-redux";
import { RouteProps } from "react-router";
import { Route, Redirect, withRouter, RouteComponentProps } from "react-router-dom";

interface ReduxProps {
    isLoggedIn: boolean;
}

type ComponentProps = RouteProps;

type Props = ReduxProps & ComponentProps & DispatchProp & RouteComponentProps;

class PrivateRouteComponent extends React.Component<Props> {
    public componentDidUpdate(prevProps: Readonly<Props>): void {
        if (this.props.isLoggedIn !== prevProps.isLoggedIn && !this.props.isLoggedIn) {
            this.props.history.push(AppPath.Login);
        }
    }

    public render(): React.ReactElement {
        const { component: Component, ...rest } = this.props;
        if (!this.props.isLoggedIn) {
            return <Redirect to={AppPath.Login} />;
        }

        return (
            <>
                <Route
                    {...rest}
                    render={props => {
                        if (Component) {
                            return <Component {...props} />;
                        } else if (this.props.render) {
                            return this.props.render(props);
                        }
                        return null;
                    }}
                />
            </>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (
    state: ApplicationState
): ReduxProps => {
    return {
        isLoggedIn: AuthSelectors.isLoggedIn(state),
    };
};

export const PrivateRoute = withRouter(connect(mapStateToProps)(PrivateRouteComponent));
