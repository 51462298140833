import { Api } from "@api/Api";
import { Intl } from "@i18n/Intl";
import { Paths } from "@pages/paths";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Breadcrumb } from "./Breadcrumb";
import ModalAbsolute, { Menu, ModalAlignment } from "./Modals/ModalAbsolute";
import { StyledMenuOptions } from "./Modals/StyledMenu";
import SvgIcnArrowDown from "./svg/IcnArrowDown";
import SvgIcnDelete from "./svg/IcnDelete";
import SvgIcnHome from "./svg/IcnHome";
import SvgIcnRename from "./svg/IcnRename";
import { ToggleDeleteDirectoryModalEvent, ToggleRenameDirectoryModalEvent } from "@components/Folders";

type PropType = {
    initialPath: string;
    currentPath: string;
} & RouteComponentProps;

export type SimplifiedDirectoryType = {
    id: string;
    name: string;
    deletable: boolean;
};

type StateType = {
    isLoading: boolean;
    dirTree: SimplifiedDirectoryType[];
};

class BreadcrumbBar extends React.Component<PropType, StateType> {
    state: StateType = {
        isLoading: true,
        dirTree: [],
    };

    constructor(props: PropType) {
        super(props);
        this.state = {
            isLoading: this.props.initialPath !== "",
            dirTree: [],
        };
    }

    componentDidMount() {
        // We need to load the tree if the initialPath is not the root directory
        if (this.state.isLoading) {
            this.loadParentDirectory(this.props.initialPath, []).then(dirTree => {
                this.setState({
                    isLoading: false,
                    dirTree,
                });
            });
        }
    }

    componentDidUpdate(prevProps: PropType) {
        if (!this.state.isLoading && prevProps.currentPath !== this.props.currentPath) {
            if (this.props.currentPath === "") {
                this.setState({
                    dirTree: [],
                });
                return;
            }
            // Find if current path is in the dirtree
            const foundIndex = this.state.dirTree.findIndex(d => d.id === this.props.currentPath);
            if (foundIndex !== -1) {
                this.setState({
                    dirTree: this.state.dirTree.slice(0, foundIndex + 1),
                });
                return;
            }
            // Not found so reload the whole tree
            this.loadParentDirectory(this.props.currentPath, []).then(dirTree => {
                this.setState({
                    isLoading: false,
                    dirTree,
                });
            });
        }
    }

    setStateAsync = (state: Partial<StateType>) => {
        return new Promise(resolve => {
            this.setState(
                current => ({ ...current, ...state }),
                () => {
                    resolve();
                }
            );
        });
    };

    loadParentDirectory = async (directoryId: string, dirTree: SimplifiedDirectoryType[]) => {
        try {
            const response = await Api.getAssetDirectoryById(directoryId);
            if (response) {
                if (
                    this.state.dirTree.length > 0 &&
                    this.state.dirTree[this.state.dirTree.length - 1].id === response.parent?.id
                ) {
                    // This happens when we walking down the tree, dont show loading if we need to load only 1 path item
                    dirTree = [
                        ...this.state.dirTree,
                        { id: response.id, name: response.name, deletable: response.deletable },
                    ];
                    return dirTree;
                } else {
                    if (!this.state.isLoading && dirTree.length === 0 && this.props.initialPath !== directoryId) {
                        // Show loading before we continue
                        await this.setStateAsync({ isLoading: true });
                    }
                }
                dirTree.unshift({ id: response.id, name: response.name, deletable: response.deletable });
                if (response.parent) {
                    dirTree = await this.loadParentDirectory(response.parent.id, dirTree);
                }
            }
        } catch (e) {
            console.dir(e);
        }
        return dirTree;
    };

    renderItems = () => {
        return this.state.dirTree.map(
            (directory: SimplifiedDirectoryType, key): React.ReactElement => {
                return (
                    <li key={key}>
                        <a
                            onClick={() => {
                                this.props.history.push(Paths.Directory(directory.id));
                            }}
                        >
                            {directory.name}
                        </a>
                        {key + 1 === this.state.dirTree.length && (
                            <ModalAbsolute
                                titleText="Options"
                                modalAlignment={ModalAlignment.BottomRight}
                                activateElement={
                                    <button type="button" aria-label="Options">
                                        <SvgIcnArrowDown />
                                    </button>
                                }
                                containerPositionFixed={true}
                            >
                                <StyledMenuOptions>
                                    <Menu.Option
                                        btnLabel={Intl.formatMessage({ id: "components.folders.options.rename" })}
                                        icon={<SvgIcnRename />}
                                        iconPosition="left"
                                        onClick={() => {
                                            window.dispatchEvent(
                                                new CustomEvent<ToggleRenameDirectoryModalEvent>(
                                                    "toggleRenameDirectoryModal",
                                                    {
                                                        detail: {
                                                            directory,
                                                        },
                                                    }
                                                )
                                            );
                                        }}
                                    />
                                    {directory.deletable && (
                                        <Menu.Option
                                            btnLabel={Intl.formatMessage({ id: "components.folders.options.delete" })}
                                            icon={<SvgIcnDelete />}
                                            iconPosition="left"
                                            onClick={() => {
                                                window.dispatchEvent(
                                                    new CustomEvent<ToggleDeleteDirectoryModalEvent>(
                                                        "toggleDeleteDirectoryModal",
                                                        {
                                                            detail: {
                                                                directory,
                                                            },
                                                        }
                                                    )
                                                );
                                            }}
                                        />
                                    )}
                                </StyledMenuOptions>
                            </ModalAbsolute>
                        )}
                    </li>
                );
            }
        );
    };

    render() {
        return (
            <Breadcrumb ariaLabel="Breadcrumb">
                <li>
                    <a onClick={() => this.props.history.push(Paths.Home)} aria-label="Go to homepage">
                        <SvgIcnHome />
                    </a>
                </li>
                {this.state.isLoading ? (
                    <li>
                        <span className="static">Loading...</span>
                    </li>
                ) : (
                    this.renderItems()
                )}
            </Breadcrumb>
        );
    }
}

export default withRouter(BreadcrumbBar);
