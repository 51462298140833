import { Reducer, combineReducers } from "redux";

import { AuthReducerState, AuthReducer } from "./AuthReducer";
import { AlertReducer, AlertReducerState } from "./AlertReducer";
import { AppStateReducer, AppStateReducerState } from "./AppStateReducer";

export interface ApplicationState {
    appState: AppStateReducerState;
    auth: AuthReducerState;
    alerts: AlertReducerState;
}

export const rootReducer: Reducer<ApplicationState> = combineReducers<ApplicationState>({
    appState: AppStateReducer.instance,
    auth: AuthReducer.instance,
    alerts: AlertReducer.instance,
});
