export const hexToRGBA = (h: string, opacity = 1): string => {
    let r = "";
    let g = "";
    let b = "";

    if (h.length === 4) {
        r = `0x${h[1]}${h[1]}`;
        g = `0x${h[2]}${h[2]}`;
        b = `0x${h[3]}${h[3]}`;
    } else if (h.length === 7) {
        r = `0x${h[1]}${h[2]}`;
        g = `0x${h[3]}${h[4]}`;
        b = `0x${h[5]}${h[6]}`;
    }

    return `rgba(${parseInt(r, 16)}, ${parseInt(g, 16)}, ${parseInt(b, 16)}, ${opacity})`;
};

// Note: X = extra, D = dark, L = light
export enum Color {
    black = "#283259",
    gray = "#ecf0ff",
    grayD = "#d9e1ff",
    grayL = "#f8f9ff",
    primary = "#40e7d3",
    primaryD = "#00dbc1",
    primaryL = "#59ecda",
    primaryXD = "#00b8a1",
    primaryXL = "#9df5eb",
    primaryXXL = "#c7fff9",
    red = "#ff6542",
    redD = "#d45134",
    redL = "#ff7657",
    redXL = "#ffb09e",
    secondary = "#657ed6",
    secondaryD = "#5469b3",
    secondaryL = "#7d95e8",
    secondaryXD = "#445591",
    secondaryXL = "#94acff",
    secondaryXXL = "#b8c7ff",
    white = "#ffffff",
    yellow = "#fced6b",
    modal = "rgba(236, 240, 255, 0.7)",
}

export enum BreakPoint {
    small = "small",
    medium = "medium",
    large = "large",
    xlarge = "xlarge",
    xxlarge = "xxlarge",
}

export const breakpoints: { [key in BreakPoint]: number } = {
    small: 0,
    medium: 768,
    large: 1024,
    xlarge: 1280,
    xxlarge: 1920,
};

function customMediaQuery(mediaQuery: string): string {
    return `@media (${mediaQuery})`;
}

export const bp: { [key in BreakPoint]: string } & { custom: (mediaQuery: string) => string } = {
    custom: customMediaQuery,
    small: customMediaQuery(`max-width:  ${breakpoints.medium - 1}px`),
    medium: customMediaQuery(`min-width: ${breakpoints.medium}px`),
    large: customMediaQuery(`min-width: ${breakpoints.large}px`),
    xlarge: customMediaQuery(`min-width: ${breakpoints.xlarge}px`),
    xxlarge: customMediaQuery(`min-width: ${breakpoints.xxlarge}px`),
};

export const theme = {
    box: {
        body: {
            backgroundColor: Color.grayL,
        },
        button: {
            borderRadius: 18,
            lineHeight: 24,
            paddingHorizontal: 12,
            paddingVertical: 0,
            primary: {
                backgroundColor: Color.secondary,
                color: Color.white,
            },
            secondary: {
                backgroundColor: Color.primaryD,
                color: Color.white,
            },
            action: {
                backgroundColor: "transparent",
                color: Color.secondaryXD,
            },
            folder: {
                backgroundColor: Color.white,
                borderColor: Color.grayD,
                icon: {
                    fillColor: Color.secondary,
                },
                borderRadius: 5,
            },
        },
        modal: {
            backgroundColor: Color.white,
            borderRadius: 4,
            boxShadow: "0 6px 12px",
            boxShadowColor: Color.grayD,
            boxShadowOpacity: ".3",
            paddingBottom: 50,
            paddingLeft: 55,
            paddingRight: 55,
            paddingTop: 95 + 42,
            width: 663,
        },
        imageModal: {
            paddingBottom: 16,
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 16 + 95,
            width: 800,
        },
        input: {
            borderColor: Color.secondary,
            borderRadius: 25,
            borderWidth: 1,
            height: 50,
            paddingBottom: 14,
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 14,
            hasError: {
                borderColor: Color.red,
                borderWidth: 2,
            },
            focus: {
                outlineColor: Color.secondaryXXL,
                outlineOffset: 1,
                outlineWidth: 4,
            },
        },
        table: {
            th: {
                paddingTop: 6,
            },
            td: {
                borderTopColor: Color.grayD,
                borderTopWidth: 1,
                minHeight: 59,
                padding: 12,
            },
        },
    },
    typo: {
        body: {
            color: Color.secondaryXD,
            fontSize: 18,
            fontWeight: 400,
            lineHeight: 30,
        },
        button: {
            fontSize: 15,
            fontWeight: 500,
            textTransform: "uppercase",
            disabled: {
                color: Color.grayL,
            },
        },
        h1: {
            fontSize: 55,
            fontWeight: 700,
            lineHeight: 66,
        },
        h2: {
            fontSizeMobile: 20,
            fontSize: 30,
            fontWeight: 600,
            lineHeight: 36,
        },
        h3: {
            fontSize: 32,
            fontWeight: 500,
            lineHeight: 36,
        },
        h4: {
            fontSize: 25,
            fontWeight: 500,
            lineHeight: 30,
        },
        h5: {
            fontSize: 20,
            fontWeight: 500,
            lineHeight: 24,
        },
        h6: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: 16,
        },
        CaptionText: {
            fontSize: 14,
            lineHeight: 17,
        },
        input: {
            color: Color.secondaryXD,
            fontSize: 16,
            fontWeight: 400,
            hasError: {
                color: Color.red,
            },
            fakeLabel: {
                color: Color.secondaryXL,
            },
        },
        table: {
            caption: {
                color: Color.secondaryD,
            },
            th: {
                color: Color.secondaryXL,
                fontSize: 13,
                textTransform: "uppercase",
            },
            td: {
                color: Color.black,
            },
        },
    },
    appBar: {
        boxShadow: `0px 2px 6px ${hexToRGBA(Color.black, 0.1)}`,
        logoSection: {
            backgroundColor: Color.secondaryXD,
            color: Color.white,
        },
        headerSection: {
            backgroundColor: Color.white,
            breadcrumb: {
                icon: {
                    color: Color.secondary,
                },
            },
            search: {
                color: Color.white,
                backgroundColor: Color.secondaryXD,
            },
        },
    },
    sidebar: {
        width: 180,
        backgroundColor: Color.white,
        borderColor: Color.grayD,
        boxShadow: `0px 2px 6px ${hexToRGBA(Color.black, 0.1)}`,
        folder: {
            hover: {
                backgroundColor: `${hexToRGBA(Color.secondaryXD, 0.1)}`,
            },
            active: {
                color: Color.white,
                backgroundColor: Color.secondaryXD,
            },
        },
    },
    optionsBar: {
        borderColor: Color.grayD,
    },
    ActionBar: {
        color: Color.white,
        backgroundColor: Color.secondary,
        buttonColor: Color.secondaryXD,
    },
    image: {
        overlay: "linear-gradient(180deg, rgba(65, 65, 65, 0.5) 0%, rgba(65, 65, 65, 0) 100%)",
        borderColor: Color.grayD,
        backgroundColor: Color.white,
        selectedColor: Color.secondary,
    },
    notification: {
        color: Color.primaryD,
        errorColor: Color.redD,
        backgroundColor: Color.white,
        iconColor: Color.secondary,
        buttonColor: `${hexToRGBA(Color.secondaryXD, 0.5)}`,
        boxShadow: `0px 10px 20px ${hexToRGBA(Color.black, 0.05)}`,
    },
    background: {
        input: {
            backgroundColor: Color.white,
        },
        modal: {
            backgroundColor: Color.white,
        },
    },
};
