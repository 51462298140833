import React from "react";
import styled from "styled-components";
import { Flex } from "@components/Flex";

type Props = {
    title: string;
};

class Section extends React.PureComponent<React.PropsWithChildren<Props>> {
    public render(): React.ReactNode {
        const { title, children } = this.props;
        return (
            <StyledSection>
                <Flex.Container>
                    <Flex.Item>
                        <SectionHeader>{title}</SectionHeader>
                    </Flex.Item>
                </Flex.Container>
                <SectionContent>{children}</SectionContent>
            </StyledSection>
        );
    }
}

const SectionContent = styled.div``;

const SectionHeader = styled.h2`
    margin-left: 10px;
    font-size: 12px;
    text-transform: uppercase;
`;

const StyledSection = styled.div`
    padding: 20px;
`;

export { Section };
